import React, { useEffect, useState } from "react";
import { forgotPassword } from "@khitmah/shared/src/Services/User/Authentication/forgotPassword";
import schema from "@khitmah/shared/src/Schemas/schemaForgotPassword";
import { FORGOT_PASSWORD } from "@khitmah/shared/src/Constants/form";
import { Form, Formik } from "formik";
import FormInput from "../Common/FormInput/textInput";

import "./styles.scss";
import Button from "../Common/Button";
import { useTranslation } from "react-i18next";
import KWHModal from "../Common/Modal";

function ForgotPasswordForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const forgot = async (values) => {
    setIsLoading(true);
    try {
      const response = await forgotPassword(values.email);
      setIsLoading(false);
      setShowResendButton(true);
      setVisibleModal(true);
      //console.log("response", response);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const resendEmail = async (values) => {
    try {
      await forgotPassword(values.email);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    return () => {
      setShowResendButton(false);
      setIsLoading(false);
      setVisibleModal(false);
    };
  }, []);

  const closeModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <KWHModal
        visible={visibleModal}
        modalTitle={t("common.resetPassword")}
        modalBody={t("common.resetPasswordSuccessMessage")}
        // onClose={closeModal}
        onOkClick={closeModal}
        btnSaveText={t("common.ok")}
      />
      <Formik
        noValidate={true}
        validationSchema={schema}
        initialValues={{
          email: "",
        }}
        onSubmit={forgot}
        validateOnMount={true}
      >
        {(props) => {
          const { isValid, values } = props;
          return (
            <>
              <Form>
                <FormInput
                  name={FORGOT_PASSWORD.EMAIL}
                  label={t("common.email")}
                  fieldType="email"
                  {...props}
                />

                <Button
                  type="submit"
                  disabled={!isValid || isLoading || showResendButton}
                  loading={isLoading}
                  btnText={t("common.resetPassword")}
                  className="primary-btn reset-btn"
                />
              </Form>

              {showResendButton && (
                <div className="resent-email-btn">
                  <a className="btn" onClick={() => resendEmail(values)}>
                    {t("common.resentEmail")}
                  </a>
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
}
export default ForgotPasswordForm;
