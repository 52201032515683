import {Entity} from './entity';
import {documentSnapshotToKhitmah} from './khitmah';
import {documentSnapshotToUser} from './user';

export enum Status {
  CLAIMED = 'CLAIMED',
  LEFT = 'LEFT',
  REMOVED = 'REMOVED',
  COMPLETED = 'COMPLETED',
  KHITMAH_REMOVED = 'KHITMAH_REMOVED',
  KHITMAH_COMPLETED = 'KHITMAH_COMPLETED',
}

export interface KhitmahPledge extends Entity {
  userId: string;
  user: any;
  userCity: string;
  userCountry: string;
  khitmahId: string;
  khitmah: any;
  friendName: string;
  friendImageUrl: string;
  khitmahOwnerId: string;
  juzzNumber: number;
  notificationCount: number;
  lastNotificationSentAt: Date | null;
  status: Status;
  reminderCount: number;
  reminderDate: Date | null;
  bookmarkPage: number | null;
  khitmahSponsorMsg: string | null;
}

export const documentSnapshotToKhitmahPledge = (snap: any) => {
  const khitmahPledge = snap.data() as KhitmahPledge;
  if (snap.id === null) {
    throw new Error('khitmah pledge snapshot document id not found');
  }
  khitmahPledge.id = snap.id;

  return khitmahPledge;
};

export const fetchKhitmahPledgeReferences = async (
  khitmahPledge: KhitmahPledge,
) => {
  const khitmahSnap = await khitmahPledge.khitmah.get();
  khitmahPledge.khitmah = documentSnapshotToKhitmah(khitmahSnap);
  const userSnap = await khitmahPledge.user.get();
  khitmahPledge.user = documentSnapshotToUser(userSnap);

  return khitmahPledge;
};
