import React from "react";
import AssetsConstants from "../../Constants/AssetsConstants";

function PrivacyPolicy() {
  return (
    <>
      <div className="cms-khitmah-screen generic-inner-sec">
        <div className="inner-bg-box">
          <div className="head-box">
            <img src={AssetsConstants.khitmahHeading} alt="khitmah" />
          </div>
          <div className="content-box">
            <h2 className="title-main">Date of Last Revision: April 2021</h2>

            <p>
              KeyFaya Labs takes your privacy seriously and does not sell any
              data to third party data brokers. Your privacy is of utmost
              importance.
            </p>

            <p>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. We use Your Personal data to provide and improve
              the Service. By using the Service, You agree to the collection and
              use of information in accordance with this Privacy Policy.
            </p>

            <p>
              Welcome to the web sites and mobile application operated by{" "}
              <a target="_blank" href="https://www.keyfaya.org/">
                KeyFaya Labs, Inc.
              </a>{" "}
              (including its subsidiaries, affiliates, agents, and service
              providers, collectively, "KeyFaya Labs," "we," "us," or "our").
              KeyFaya Labs provides (a) a platform through its website at
              www.KeyFaya Labs.org and mobile applications and related services
              (collectively the KeyFaya Labs Platform, including any new
              features and applications. The KeyFaya Labs Platform (collectively
              “Platform”) provides information about KeyFaya Labs, and it
              explains how users can post a fundraising campaign, download a
              donation button, accept monetary donations and details our related
              services (together with the Platform, the "Services") to users and
              visitors ("you" or "your").
            </p>

            <p>
              This Privacy Policy (the "Policy") describes and governs how we
              collect, use, share and protect information collected in the
              context of our Services. Before you use or submit any information
              through or in connection with the Services, please carefully
              review this Policy. By using any part of the Services, you consent
              to the collection, use, and disclosure of your information as
              outlined in this Policy. To the extent allowed by law, the English
              version of this Policy is binding and other translations are for
              convenience only.
            </p>

            <p>
              If you are in the European Economic Area, the United Kingdom or
              Switzerland ("Europe"):
              <ul>
                <li>
                  for the purposes of the EU General Data Protection Regulation
                  2016/679 (the "GDPR"), the data controller is KeyFaya Labs,
                  Inc. and can be reached at the address set out at{" "}
                  <a href="http://www.keyfaya.org/" target="_blank">
                    "Contacting KeyFaya Labs" below;
                  </a>{" "}
                  and
                </li>
                <li>
                  you will not need to consent to this Privacy Policy, but you
                  do acknowledge you have read and understood its terms.
                </li>
              </ul>
              If you are a resident of California, the California Consumer
              Privacy Act of 2018 provides you with additional rights. Please
              see the California Consumer Privacy Act Notice for more
              information.
            </p>

            <h3 className="title-sub">Interpretation and Definitions</h3>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions.
              <br />
              The following definitions shall have the same meaning regardless
              of whether they appear in singular or in plural.
            </p>

            <h4>Definitions</h4>
            <p>For the purposes of this Privacy Policy:</p>

            <p>
              <strong>Account</strong> means a unique account created for You to
              access our Service or parts of our Service.
            </p>

            <p>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              "control" means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of
              directors or other managing authority.
            </p>

            <p>
              <strong>Application</strong> means the software program provided
              by the Company downloaded by You on any electronic device, named
              Khitmah with Friends
            </p>

            <p>
              <strong>Business,</strong> for the purpose of the CCPA (California
              Consumer Privacy Act), refers to the Company as the legal entity
              that collects Consumers' personal information and determines the
              purposes and means of the processing of Consumers' personal
              information, or on behalf of which such information is collected
              and that alone, or jointly with others, determines the purposes
              and means of the processing of consumers' personal information,
              that does business in the State of California.
            </p>

            <p>
              <strong>Company</strong> (referred to as either "the Company",
              "We", "Us" or "Our" in this Agreement) refers to KeyFaya Labs,
              6425 Worlington Road.
              <br />
              For the purpose of the GDPR, the Company is the Data Controller.
            </p>

            <p>
              <strong>Consumer,</strong> for the purpose of the CCPA (California
              Consumer Privacy Act), means a natural person who is a California
              resident. A resident, as defined in the law, includes (1) every
              individual who is in the USA for other than a temporary or
              transitory purpose, and (2) every individual who is domiciled in
              the USA who is outside the USA for a temporary or transitory
              purpose.
            </p>

            <p>
              <strong>Cookies</strong> are small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing the details of Your browsing history on that website
              among its many uses.
            </p>

            <p>
              <strong>Country</strong> refers to: Michigan, United States
            </p>

            <p>
              <strong>Data Controller,</strong> for the purposes of the GDPR
              (General Data Protection Regulation), refers to the Company as the
              legal person which alone or jointly with others determines the
              purposes and means of the processing of Personal Data.
            </p>

            <p>
              <strong>Device</strong> means any device that can access the
              Service such as a computer, a cellphone or a digital tablet.
            </p>

            <p>
              <strong>Do Not Track (DNT)</strong> is a concept that has been
              promoted by US regulatory authorities, in particular the U.S.
              Federal Trade Commission (FTC), for the Internet industry to
              develop and implement a mechanism for allowing internet users to
              control the tracking of their online activities across websites.
            </p>

            <p>
              <strong>Facebook Fan Page</strong> is a public profile named
              Khitma with Friends specifically created by the Company on the
              Facebook social network, accessible from{" "}
              <a href="https://www.facebook.com/khitmaapp" target="_blank">
                www.facebook.com/khitmaapp
              </a>
            </p>

            <p>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
              <br />
              For the purposes for GDPR, Personal Data means any information
              relating to You such as a name, an identification number, location
              data, online identifier or to one or more factors specific to the
              physical, physiological, genetic, mental, economic, cultural or
              social identity.
              <br />
              For the purposes of the CCPA, Personal Data means any information
              that identifies, relates to, describes or is capable of being
              associated with, or could reasonably be linked, directly or
              indirectly, with You.
            </p>

            <p>
              <strong>Sale,</strong> for the purpose of the CCPA (California
              Consumer Privacy Act), means selling, renting, releasing,
              disclosing, disseminating, making available, transferring, or
              otherwise communicating orally, in writing, or by electronic or
              other means, a Consumer's personal information to another business
              or a third party for monetary or other valuable consideration.
            </p>

            <p>
              <strong>Service</strong> refers to the Application or the Website
              or both.
            </p>

            <p>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of the Company. It refers
              to third-party companies or individuals employed by the Company to
              facilitate the Service, to provide the Service on behalf of the
              Company, to perform services related to the Service or to assist
              the Company in analyzing how the Service is used. For the purpose
              of the GDPR, Service Providers are considered Data Processors.
            </p>

            <p>
              <strong>Third-party Social Media Service</strong> refers to any
              website or any social network website through which a User can log
              in or create an account to use the Service.
            </p>

            <p>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the Service or from
              the Service infrastructure itself (for example, the duration of a
              page visit).
            </p>

            <p>
              <strong>Website</strong> refers to KeyFaya Labs, accessible from{" "}
              <a href="https://www.keyfaya.org" target="_blank">
                www.keyfaya.org
              </a>
            </p>

            <p>
              <strong>You</strong> means the individual accessing or using the
              Service, or the company, or other legal entity on behalf of which
              such individual is accessing or using the Service, as applicable.
              <br />
              Under GDPR (General Data Protection Regulation), You can be
              referred to as the Data Subject or as the User as you are the
              individual using the Service.
            </p>

            <h3>Collecting and Using Your Personal Data</h3>
            <h3 className="title-sub">Types of Data Collected</h3>

            <h4>
              Personal Data for Compliance Needs (e.g. Obtaining Tax Deduction
              for Donations)
            </h4>
            <p>
              While using Our Service, We may ask You to provide us with certain
              personally identifiable information that can be used to contact or
              identify You. This is needed for managing donation compliance and
              reporting. The personally identifiable information may include,
              but is not limited to:
            </p>

            <table className="tb-policy">
              <th>Information collected</th>
              <th>Use of data</th>

              <tr>
                <td>Email address </td>
                <td>For identification and contact</td>
              </tr>
              <tr>
                <td>First name and last name </td>
                <td>For identification </td>
              </tr>
              <tr>
                <td>Phone number </td>
                <td>For OTP verification and contact </td>
              </tr>
              <tr>
                <td>Address, State, Province, ZIP/Postal code, City </td>
                <td>For identifying your location on map </td>
              </tr>
              <tr>
                <td>Your device’s files and photos</td>
                <td>To upload images and other media on the app </td>
              </tr>
              <tr>
                <td>Usage Data </td>
                <td>For browsing and diagnostics</td>
              </tr>
            </table>
            <br />

            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>

            <h4>Information from Third-Party Social Media Services</h4>
            <p>
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
              <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Twitter</li>
              </ul>
            </p>
            <p>
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.
            </p>
            <p>
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media Service's
              account. If You choose to provide such information and Personal
              Data, during registration or otherwise, You are giving the Company
              permission to use, share, and store it in a manner consistent with
              this Privacy Policy.
            </p>

            <h4>Information Collected while Using the Application</h4>
            <p>
              While using Our Application, in order to provide features of Our
              Application, We may collect, with Your prior permission:
              <ul>
                <li>Information regarding your location</li>
                <li>
                  Information from your Device's phone book (contacts list)
                </li>
                <li>
                  Pictures and other information from your Device's camera and
                  photo library
                </li>
              </ul>
            </p>
            <p>
              We use this information to provide features of Our Service, to
              improve and customize Our Service. The information may be uploaded
              to the Company's servers and/or a Service Provider's server or it
              may be simply stored on Your device.
            </p>
            <p>
              You can enable or disable access to this information at any time,
              through Your Device settings.
            </p>

            <h4>Tracking Technologies and Cookies</h4>
            <p>
              We use Cookies and similar tracking technologies to track the
              activity on Our Service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze Our Service. The
              technologies We use may include:
              <ul>
                <li>
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a
                  small file placed on Your Device. You can instruct Your
                  browser to refuse all Cookies or to indicate when a Cookie is
                  being sent. However, if You do not accept Cookies, You may not
                  be able to use some parts of our Service. Unless you have
                  adjusted Your browser setting so that it will refuse Cookies,
                  our Service may use Cookies.
                </li>
                <li>
                  <strong>Flash Cookies.</strong> Certain features of our
                  Service may use local stored objects (or Flash Cookies) to
                  collect and store information about Your preferences or Your
                  activity on our Service. Flash Cookies are not managed by the
                  same browser settings as those used for Browser Cookies. For
                  more information on how You can delete Flash Cookies, please
                  read "Where can I change the settings for disabling, or
                  deleting local shared objects?" available at
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li>
                  <strong>Web Beacons.</strong> Certain sections of our Service
                  and our emails may contain small electronic files known as web
                  beacons (also referred to as clear gifs, pixel tags, and
                  single-pixel gifs) that permit the Company, for example, to
                  count users who have visited those pages or opened an email
                  and for other related website statistics (for example,
                  recording the popularity of a certain section and verifying
                  system and server integrity).
                </li>
              </ul>
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on Your personal computer or mobile device when You
              go offline, while Session Cookies are deleted as soon as You close
              Your web browser. Learn more about cookies: What Are Cookies?.
            </p>

            <p>
              We use both Session and Persistent Cookies for the purposes set
              out below:
            </p>
            <h5>Necessary / Essential Cookies</h5>
            <p>
              Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies are essential to provide You with services
              available through the Website and to enable You to use some of its
              features. They help to authenticate users and prevent fraudulent
              use of user accounts. Without these Cookies, the services that You
              have asked for cannot be provided, and We only use these Cookies
              to provide You with those services.
            </p>

            <h5>Cookies Policy / Notice Acceptance Cookies</h5>
            <p>
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies identify if users have accepted the use of
              cookies on the Website.
            </p>

            <h5>Functionality Cookies</h5>
            <p>
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies allow us to remember choices You make when
              You use the Website, such as remembering your login details or
              language preference. The purpose of these Cookies is to provide
              You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>

            <h5>Tracking and Performance Cookies</h5>
            <p>
              Type: Persistent Cookies
              <br />
              Administered by: Third-Parties
              <br />
              Purpose: These Cookies are used to track information about traffic
              to the Website and how users use the Website. The information
              gathered via these Cookies may directly or indirectly identify you
              as an individual visitor. This is because the information
              collected is typically linked to a pseudonymous identifier
              associated with the device you use to access the Website. We may
              also use these Cookies to test new pages, features or new
              functionality of the Website to see how our users react to them.
            </p>

            <p>
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>

            <h3 className="title-sub">Use of Your Personal Data</h3>
            <p>The Company may use Personal Data for the following purposes:</p>

            <p>
              <strong>To provide and maintain our Service,</strong> including to
              monitor the usage of our Service.
            </p>
            <p>
              <strong>To manage Your Account:</strong> to manage Your
              registration as a user of the Service. The Personal Data You
              provide can give You access to different functionalities of the
              Service that are available to You as a registered user.
            </p>
            <p>
              <strong>For the performance of a contract:</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services You have purchased or of any
              other contract with Us through the Service.
            </p>
            <p>
              <strong>To contact You:</strong> To contact You by email,
              telephone calls, SMS, or other equivalent forms of electronic
              communication, such as a mobile application's push notifications
              regarding updates or informative communications related to the
              functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their
              implementation.
            </p>
            <p>
              <strong>To provide You</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless You have opted not to receive such
              information.
            </p>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage
              Your requests to Us.
            </p>
            <p>
              <strong>For business transfers:</strong> We may use Your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of Our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by Us about our Service users is among
              the assets transferred.
            </p>
            <p>
              <strong>For other purposes:</strong> We may use Your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>

            <p>
              We may share Your personal information in the following
              situations:
              <ul>
                <li>
                  With Service Providers: We may share Your personal information
                  with Service Providers to monitor and analyze the use of our
                  Service, to contact You.
                </li>
                <li>
                  For business transfers: We may share or transfer Your personal
                  information in connection with, or during negotiations of, any
                  merger, sale of Company assets, financing, or acquisition of
                  all or a portion of Our business to another company.
                </li>
                <li>
                  With Affiliates: We may share Your information with Our
                  affiliates, in which case we will require those affiliates to
                  honor this Privacy Policy. Affiliates include Our parent
                  company and any other subsidiaries, joint venture partners or
                  other companies that We control or that are under common
                  control with Us.
                </li>
                <li>
                  With business partners: We may share Your information with Our
                  business partners to offer You certain products, services or
                  promotions.
                </li>
                <li>
                  With other users: when You share personal information or
                  otherwise interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside. If You interact with other users or
                  register through a Third-Party Social Media Service, Your
                  contacts on the Third-Party Social Media Service may see Your
                  name, profile, pictures and description of Your activity.
                  Similarly, other users will be able to view descriptions of
                  Your activity, communicate with You and view Your profile.
                </li>
                <li>
                  With Your consent: We may disclose Your personal information
                  for any other purpose with Your consent.
                </li>
              </ul>
            </p>

            <h3 className="title-sub">Retention of Your Personal Data</h3>
            <p>
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>

            <h3 className="title-sub">Transfer of Your Personal Data</h3>
            <p>
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p>
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>

            <h3>Disclosure of Your Personal Data</h3>
            <h3 className="title-sub">Business Transactions</h3>
            <p>
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>

            <h3 className="title-sub">Law enforcement</h3>
            <p>
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>

            <h3 className="title-sub">Other legal requirements</h3>
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
              <ul>
                <li>Comply with a legal obligation</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>Financial audits</li>
                <li>
                  Protect and defend the rights or property of the Company
                </li>
                <li>
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li>
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li>Protect against legal liability</li>
              </ul>
            </p>

            <h3 className="title-sub">Security of Your Personal Data</h3>
            <p>
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>

            <h2>
              Detailed Information on the Processing of Your Personal Data
            </h2>
            <p>
              The Service Providers We use may have access to Your Personal
              Data. These third-party vendors collect, store, use, process and
              transfer information about Your activity on Our Service in
              accordance with their Privacy Policies.
            </p>
            <h3>Analytics</h3>
            <p>
              We may use third-party Service providers to monitor and analyze
              the use of our Service.
            </p>
            <h4>Google Analytics</h4>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network.
              <br />
              You can opt-out of having made your activity on the Service
              available to Google Analytics by installing the Google Analytics
              opt-out browser add-on. The add-on prevents the Google Analytics
              JavaScript (ga.js, analytics.js and dc.js) from sharing
              information with Google Analytics about visits activity.
              <br />
              You may opt-out of certain Google Analytics features through your
              mobile device settings, such as your device advertising settings
              or by following the instructions provided by Google in their{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                Privacy Policy
              </a>{" "}
              <br />
              For more information on the privacy practices of Google, please
              visit the{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                Google Privacy & Terms
              </a>
            </p>

            <h4>Email Marketing</h4>
            <p>
              We may use Your Personal Data to contact You with newsletters,
              marketing or promotional materials and other information that may
              be of interest to You. You may opt-out of receiving any, or all,
              of these communications from Us by following the unsubscribe link
              or instructions provided in any email We send or by contacting Us.
            </p>
            <p>
              We may use Email Marketing Service Providers to manage and send
              emails to You.
            </p>
            <h5>
              <strong>Hubspot</strong>
            </h5>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a href="https://www.hubspot.com" target="_blank">
                www.hubspot.com
              </a>
            </p>

            <h3 className="title-sub">INFORMATION WE COLLECT</h3>

            <p>
              As further described below, when you use or otherwise access the
              Services, we collect information in multiple ways, including when
              you provide information directly to us, and when we passively
              collect information from your browser or device (e.g., by logging
              how you interact with our Services).
            </p>

            <h3 className="title-sub">
              Information That You Provide Directly to Us:
            </h3>

            <h4>Registration information:</h4>
            <p>
              When you register for our Services or open an account, we collect
              your contact and login details, address and any information you
              choose to provide us (such as a profile picture).You can edit your
              information by logging into your account and making changes at any
              time.
            </p>

            <h4>Creation of a campaign:</h4>
            <p>
              When you create a campaign, we collect the information you choose
              to provide us in relation to your campaign. For example, you can
              provide a campaign title, choose a campaign category, upload
              images and describe your campaign goal(s). Our payment processors
              collect and maintain information related to your withdrawal of
              funds from any campaign.
            </p>

            <h4>Donation information:</h4>
            <p>
              On the KeyFaya Labs Platform we collect your contact details (such
              as name and email address), zip code, country, and any information
              you choose to provide us (such as a description of why you have
              donated). Our payment processors collect your credit card details
              and other information necessary to process the donation. We also
              need to store donation information for financial audits of KeyFaya
              labs and as proof of transaction for the donations.
            </p>

            <h4>Communications:</h4>
            <p>
              You can post comments, leave feedback, send thank-you notes or
              otherwise communicate with other users through our Platform. Any
              content, including such comments, feedback, and notes are you
              choose to post through our Platform are available to the public by
              default. When you do so, we collect the information you choose to
              provide us, such as your contact details, any images you choose to
              upload and the contents and nature of your message. Please
              remember that any information that is disclosed in these areas
              becomes public information for both us and other users to use and
              share. Please be considerate and respectful of others while using
              the community to share your opinions. We reserve the right, but do
              not have the obligation, to review and monitor such posting or any
              other content on our Services, and to remove postings or content
              that may be viewed as inappropriate or offensive to others.
            </p>

            <h4>Information relating to third parties:</h4>
            <p>
              You can choose to provide us with information relating to third
              parties. For example, if you choose to share your campaign on the
              Platform with family and friends, we request access to information
              stored in your email and mobile device address books, such as
              names, email addresses, or phone numbers of your contacts
              (collectively, “Third-Party Data”).
            </p>
            <p>
              If you use any feature of the Services that allows you to
              communicate with third parties (such as to refer a third party to
              the Services or to communicate with them regarding a campaign or a
              donation), either by submitting Third-Party Data to the Services
              or otherwise permitting the Services to automatically access
              Third-Party Data in your possession, you acknowledge and agree
              that you have the authority of the relevant third party for us to
              access and use the relevant Third-Party Data and that you have
              notified these third parties and informed them how their
              information is collected and used by KeyFaya Labs to provide the
              Services.
            </p>
            <p>
              We reserve the right to identify you as the person who has made
              the referral in any messages that are sent to them. We use
              Third-Party Data to (1) contact such third party using the
              Third-Party Data provided, and/or (2) provide you with an editable
              template message designed to facilitate communication between you
              and such third party through the Services. In addition to sending
              the foregoing communications, we may also send reminders or
              related messages to you and to third parties on your behalf from
              time to time where permitted by applicable law. In each case, any
              such communications sent to third parties using Third-Party Data
              will provide a means to "opt out" of receiving further
              communication of the same nature.
            </p>

            <h4>Sensitive information:</h4>
            <p>
              You can also provide us with information about yourself or third
              parties that is considered sensitive. When you provide such
              information to us directly it is based on your implied consent.
            </p>
            <p>
              We collect the information listed in this section for the purposes
              described below in the section “ Our Use of Information Collected
              ”. While you are under no obligation to provide us with such
              information, should you choose to withhold such information, we
              may not be able to provide you with some or all of the Services.
            </p>

            <h4>Other Information</h4>
            <p>
              On the Platform, KeyFaya Labs may provide a Charity with the
              ability to run a promotion or set up an event. If you set up an
              event we collect your name, address and contact information so
              that the Charity or other entity associated with the event, and,
              to the extent permitted by law, to provide you with information on
              upcoming events, announcements, and other promotions.
            </p>

            <h3 className="title-sub">
              Information That is Passively or Automatically Collected:
            </h3>

            <h4>Device & Usage Information:</h4>
            <p>
              When you interact with KeyFaya Labs through the Services, we
              automatically receive and store certain information from devices
              that you use to access the Services. This information is collected
              passively by using various technologies, and includes the type of
              internet browser or mobile device you use, any website from which
              you have come to the Services, your operating system, and location
              data through an IP address that identifies the city and state
              where you logged into the Platform or your location if you have
              permitted your mobile device to provide that information to us.
              KeyFaya Labs either stores such information itself or such
              information is included in databases owned and maintained by
              KeyFaya Labs, or its agents or service providers.
            </p>

            <h4>Aggregated Data:</h4>
            <p>
              In an ongoing effort to better understand the use of our Services
              and serve you, KeyFaya Labs often conducts research on its
              customer demographics, interests and behavior based on information
              we collect. We can de-identify and aggregate the information
              collected through the Services for research purposes or any other
              purposes, and we can share this information with our affiliates,
              agents, business partners, or other third parties (e.g., Google
              Analytics).
            </p>

            <h4>Location Information:</h4>
            <p>
              When you use the Services to organize a fundraising campaign, the
              Services request that you provide your location. Please keep in
              mind that other users of the Services can view your location in
              connection with the campaign, if it is made available within
              KeyFaya Labs's Public Search Directory. We also use your location
              information as described above under “Device & Usage Information”,
              and in an aggregate way, as described above in the "Aggregated
              Data" section.
            </p>

            <h4>Cookies and Other Electronic Technologies:</h4>
            <p>
              When you interact with the Services, we try to make that
              experience simple and meaningful. When you visit our Platform, our
              web server, third party business providers, and/or the social
              networking services integrated into the Service, use cookies or
              other electronic technologies (such as web beacons or pixel tags)
              for various purposes. Cookies are small pieces of information that
              are issued to your computer or mobile device when you visit a
              website or access or use a mobile application, and that store and
              sometimes track information about your use of the Platform. Web
              beacons are transparent image files, which enable the Platform to
              track website usage information. Unlike cookies, web beacons are
              not placed on your computers. Pixel tags are tiny graphic images
              with unique identifiers that track online movements of our users.
              Unlike cookies, pixel tags are embedded in web pages. We will
              refer to the cookies and other technologies identified here as
              Cookies for purposes of this Policy.
            </p>

            <h5>
              <i>Type of Cookies</i>
            </h5>
            <p>
              Some Cookies are strictly necessary to make our Services available
              to you (“Essential cookies”). For example, to provide the chat and
              login functionality and to remember your consent and privacy
              choices. We cannot provide our Services without these Essential
              Cookies.
            </p>
            <p>
              We also use Cookies for functional purposes in order to maintain
              and improve our Services or improve your experiences, and for
              marketing purposes (collectively “Non-Essential Ccookies”). Some
              of the Non-Essential Cookies used are set by us, to collect and
              process certain data on our behalf, and some are set by third
              parties and our Social Networking Services.
            </p>

            <h5>
              <i>Information to and from Social Networking Services</i>
            </h5>
            <p>
              Non-essential Cookies include Cookies we use in conjunction with
              the Social Networking Services. One of the special features of the
              Services is that it allows you to enable or log into the Services
              via various social networking services like Facebook or Twitter,
              or upload videos using YouTube APIs ("Social Networking
              Service(s)"). By directly integrating these services, we aim to
              make your online experiences richer and more personalized. To take
              advantage of these features, we will ask you to provide your
              credentials for the applicable Social Networking Service in order
              to log in to our Services. When you add a Social Networking
              Services account to the Services or log into the Services using
              your Social Networking Services account, we will collect relevant
              information necessary to enable the Services to access that Social
              Networking Service. As part of such integration, the Social
              Networking Service will provide us with access to certain
              information that you have provided to the Social Networking
              Service (for example, your name and photograph, as well as other
              information that you permit the Social Networking Service to share
              with us).
            </p>
            <p>
              We will use, store, and disclose such information in accordance
              with this Policy. However, please remember that the manner in
              which Social Networking Services use, store, and disclose your
              information is governed by the policies of such third parties, and
              KeyFaya Labs shall have no liability or responsibility for the
              privacy practices or other actions of any Social Networking
              Services that may be enabled within the Services. For example, if
              you upload a picture or media content onto your fundraiser using
              the mobile application for the KeyFaya Labs Platform, your video
              will be uploaded using YouTube and subject to{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                Google’s Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://myaccount.google.com/permissions"
                target="_blank"
              >
                Google’s Security Settings.
              </a>{" "}
              You also have the option of using single sign on or posting your
              activities to Social Networking Services when you access content
              through the Services. By proceeding, you are allowing the Social
              Networking Service to access your information on the Platform and
              you are agreeing to the Social Networking Service’s terms of use
              and privacy policy in your use of the Service. You further
              acknowledge that if you choose to use this feature, your friends,
              followers, and subscribers on any Social Networking Services you
              have enabled will be able to view such activity. For example, if
              you sign on using Facebook, Facebook’s{" "}
              <a
                href="https://www.facebook.com/privacy/explanation"
                target="_blank"
              >
                privacy policy
              </a>{" "}
              and{" "}
              <a href="https://www.facebook.com/legal/terms" target="_blank">
                Terms of Service
              </a>{" "}
              would apply to the data Facebook might obtain from{" "}
              <a
                href="https://www.facebook.com/help/2207256696182627"
                target="_blank"
              >
                your activities
              </a>{" "}
              on KeyFaya Labs. Please visit the terms of use and privacy policy
              of the relevant Social Networking Service to learn more about your
              rights and choices, and to exercise your choice about those
              technologies.
            </p>

            <h5>
              <i>Analytics</i>
            </h5>
            <p>
              We use third-party web analytics services on our Services, such as
              those of Google Analytics, to help us analyze how users use the
              Services, including by noting the third-party website from which
              you arrive, and provide certain features to you. The information
              (including your IP address) collected by the technology will be
              disclosed to or collected directly by these service providers, who
              use the information to evaluate your use of the Services. This
              information can be used to place interest-based advertisements on
              the Platform. This may result in you seeing advertisements for our
              Platform when you visit other websites. To prevent Google
              Analytics from using your information for analytics, you can
              install the Google Analytics Opt out Browser Add-on by clicking{" "}
              <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">
                here.
              </a>{" "}
              Google’s ability to use and share information collected by Google
              Analytics about your visits to the Site is restricted by the{" "}
              <a
                href="https://marketingplatform.google.com/about/analytics/terms/us/"
                target="_blank"
              >
                Google Analytics Terms of Use
              </a>{" "}
              and the{" "}
              <a href="https://policies.google.com/privacy" target="_blank">
                Google Privacy Policy.
              </a>
            </p>

            <h5>
              <i>Why we use Cookies</i>
            </h5>
            <p>
              We use Cookies to collect information about your access to and use
              of the Platform: including to: (1) allow you to navigate and use
              all the features provided by our Platform; (2) customize elements
              of the layout and/or content within the Platform and remember that
              you have visited us before; (3) identify the number of unique
              visitors we receive; (4) improve the Platform and learn which
              functions of the Platform are most popular with users; and (5) how
              you use the Platform (e.g., by learning how long you spend on the
              Platform and where you have come to the Platform from. As we adopt
              additional technologies, we may gather additional information
              through other methods. We will notify you of such changes with
              updates to this Privacy Policy.)
            </p>

            <h4>Your Cookie preferences</h4>
            <p>
              Most web and mobile device browsers automatically accept cookies
              but, if you prefer, you can change your browser to prevent that or
              to notify you each time a cookie is set. You can also learn more
              about cookies by visiting www.allaboutcookies.org, which includes
              additional useful information on cookies and how to block cookies
              using different types of browsers or mobile devices.
            </p>
            <p>
              You can also consult the "Help" section of your browser for more
              information (e.g.,{" "}
              <a
                href="http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies"
                target="_blank"
              >
                Internet Explorer
              </a>
              ;{" "}
              <a
                href="https://support.google.com/chrome/answer/95647"
                target="_blank"
              >
                Google Chrome
              </a>
              ;{" "}
              <a
                href="http://kb.mozillazine.org/Cookies#Firefox"
                target="_blank"
              >
                Mozilla Firefox
              </a>
              ; or{" "}
              <a href="http://support.apple.com/kb/PH5042" target="_blank">
                Apple Safari
              </a>
              ). If you receive tailored advertising on your computer, you can
              generally control Cookies from being put on your computer to
              deliver tailored advertising by visiting, for advertising based in
              the United States either the{" "}
              <a href="https://optout.networkadvertising.org/" target="_blank">
                Network Advertising Initiative’s Consumer opt-out
              </a>{" "}
              link or the{" "}
              <a href="https://optout.aboutads.info/" target="_blank">
                Digital Advertising Alliance’s Consumer opt-out
              </a>{" "}
              link, or for advertising in Europe, the{" "}
              <a
                href="https://www.youronlinechoices.com/uk/your-ad-choices"
                target="_blank"
              >
                Digital Advertising Alliance Consumer Ad Choices opt-out
              </a>{" "}
              link. You can also manage the use of Flash technologies, including
              Cookies and local storage objects with the Flash management tools
              available at{" "}
              <a
                href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager02.html"
                target="_blank"
              >
                Adobe's website
              </a>
              . Please note that by blocking any or all Cookies, you may not
              have access to certain features or offerings of the Services.
            </p>
            <p>
              If you are accessing our Services through a mobile device, you can
              also update your privacy settings on your device by setting the
              “Limit Ad Tracking” and Diagnostics and Usage setting property
              located in the settings screen of your Apple iPhone or iPad, or by
              resetting your Android ID through apps that are available in the
              Play Store. You can also limit information collection by
              uninstalling the App on your device and you can use the standard
              uninstall process available as part of your device for this
              purpose. Finally, and in connection with the Social Networking
              Services, you can visit your settings with each of the Social
              Networking Services to exercise your choice about those
              technologies.
            </p>

            <h3 className="title-sub">OUR USE OF INFORMATION COLLECTED</h3>
            <p>
              KeyFaya Labs uses the information collected from the Services in a
              manner that is consistent with this Policy. We use the information
              that you provide (or otherwise permit the Services to access) for
              the following purposes:
            </p>

            <h4>Communicating With You</h4>
            <p>
              We use the information we collect to contact you for
              administrative purposes (e.g., to provide services and information
              that you request or to respond to comments and questions). For
              instance, if you contact us by email, we will use the information
              you provide to answer your question or resolve your problem.
            </p>
            <p>
              We also use the information we collect to send you communications
              relating to the Services, such as updates on promotions and
              events, communications relating to products and services offered
              by us and by third parties, and communications about services we
              believe will be of interest to you. For example, we can send
              periodic emails to registered users of the Services relating to
              their recent interactions with the Services, such as donating to a
              campaign or "hearting" a campaign.
            </p>
            <p>
              Mobile Services: If you access the Services through a mobile
              device and if you have chosen to provide us with your mobile
              number, or other electronic means to your mobile device, you agree
              that we can communicate with you regarding KeyFaya Labs by SMS,
              MMS, text message. In the event you change or deactivate your
              mobile telephone number, you agree to promptly update your KeyFaya
              Labs account information to ensure that your messages are not
              misdirected. Please note that your wireless service carrier’s
              standard charges, data rates, and other fees may apply where you
              access the Services through a mobile device. In addition,
              downloading, installing, or using certain Services on a mobile
              device may be prohibited or restricted by your carrier, and not
              all Services may work with all carriers or devices.
            </p>

            <h4>Providing the Services</h4>
            <p>
              We use the information we collect to operate, maintain, and
              provide our Services such as enabling access to the Platform,
              customer support or to complete transactions. We also may provide
              (i) a report to campaign or event organizers that tells them who
              responded to a particular offer or to facilitate the fulfillment
              of an order that you have made; or (ii) our gift registry partners
              with all the necessary data to accurately update their gift
              registries, including data related to the establishment of a
              donation registry and the applicable charities, campaigns on the
              Platform that established the donation registry.
            </p>

            <h4>Analytics and Product Development</h4>
            <p>
              We use the information we collect to analyze data usage trends and
              preferences in order to improve the accuracy, effectiveness,
              security, usability or popularity of our Services.
            </p>
            <p>
              If you are located in Europe, we process information as listed
              under A, B and C as necessary (i) to fulfill our obligations under
              our contract with you or in order to take steps at your request
              prior to entering into a contract, or (ii) for our legitimate
              interest, such as, to maintain our relationship with you or to
              protect you and us against fraud.
            </p>

            <h4>Customization</h4>
            <p>
              We use the information we collect, such as device identifiers, to
              learn how users interact with our Services in order to personalize
              the content of our Services, such as customizing the language and
              campaigns on our homepage based on your location.
            </p>
            <p>
              If you are located in Europe, we process information as listed
              under D as necessary for our legitimate interest.
            </p>

            <h4>Legal</h4>
            <p>
              We use information we collect to defend our legal rights, comply
              with state, local, federal or international laws, and enforce our
              Terms of Service and this Policy.
            </p>
            <p>
              If you are located in Europe, we process information as listed
              under E (i) to fulfill our obligations under our contract with you
              or in order to take steps at your request prior to entering into a
              contract; (ii) for our legitimate interest to maintain our
              relationship with you, (iii) to protect our business or protect
              you and us against fraud; or (iv) to comply with legal
              obligations.
            </p>

            <h5>Business Transfers:</h5>
            <p>
              As we develop our business, we might sell or buy businesses or
              assets. In the event of a corporate sale, merger, reorganization,
              dissolution, similar event, or steps taken in anticipation of such
              events (e.g., due diligence in a transaction), user information
              may be part of the transferred assets.
            </p>

            <h5>Affiliates and Subsidiaries:</h5>
            <p>
              We share your personal information among the KeyFaya Labs
              entities, including our affiliates and subsidiaries, in order to
              provide our Services including: hosting, marketing and publicizing
              campaigns; providing you with customer support; administering
              funds in connection with campaigns, sweepstakes, challenges or
              games; authenticating donors; sending you communications; and
              conducting the other activities described in this Policy.
            </p>

            <h5>Agents, Consultants, Vendors and Related Third Parties:</h5>
            <p>
              KeyFaya Labs sometimes hires other companies to perform certain
              business-related functions on KeyFaya Labs's behalf, and we
              provide access to or share your information with these companies
              so they can perform services for us. Examples of such functions
              include marketing, mailing information, data storage, security,
              fraud prevention, payment processing, legal services, and
              maintaining databases. We limit the personal information provided
              to these service providers to that which is reasonably necessary
              for them to perform their functions, and we require them to agree
              to maintain the confidentiality of such information.
            </p>

            <h5>Legal Requirements:</h5>
            <p>
              KeyFaya Labs can access, disclose, transfer and preserve your
              information when we have a good faith belief that doing so is
              necessary to: (i) comply with the law including with subpoenas,
              search warrants, court orders, and other legal process; and
              respond to inquiries or requests from government, regulatory, law
              enforcement, public authorities, or content protection
              organizations; (ii) protect and defend the legal rights, privacy,
              safety or property of KeyFaya Labs, its affiliates, subsidiaries,
              employees, agents, contractors, or its users; (iii) permit us to
              pursue available remedies, commence, participate in, or defend
              litigation, or limit the damages we may sustain; or (iv) enforce
              this Policy or any applicable Terms of Service.
            </p>

            <h5>Campaign Organizers and beneficiaries of campaigns:</h5>
            <p>
              We share your information with campaign organizers and, if not the
              same, the beneficiaries of the campaign. For example, if you
              donate to a campaign, we share your name, email address and other
              information you’ve provided in connection with your donation with
              the campaign organizer or beneficiary, who may contact you. For
              example, if an individual starts a campaign for a Charity and you
              donate to that campaign, then if the information is requested, (i)
              the Charity can receive information about both the campaign
              organizer, if not the same, and donors, and (ii) campaign
              organizer can receive donor information. In addition, if the
              beneficiary of a KeyFaya Labs Platform fundraiser is a Charity
              with a Charity Contact account on the Platform, Charity will have
              access to all donor information, regardless of the platform on
              which donors made the donations. On the Platform, the information
              is accessible to the Charity through the Platform records center.
              In the same scenario, beneficiary may communicate with you about
              your donation and/or may rely on your information in order to
              comply with its legal and auditing requirements.
            </p>

            <h5>Your consent</h5>
            <p>
              We may share your information in the event that you consent to the
              sharing of your information. For example, we may partner with
              third party organizations to arrange for specific campaigns. If
              you consent to our delivering your contact information to a
              specific partner, we share, with the partner organization, your
              name, email address and other information you’ve provided in
              connection with your donation to the specific campaign.
            </p>
            <p>
              On the Platform, if you enter a sweepstakes or register for an
              event, we will share your information with the people who are
              running the sweepstakes or conducting the event.
            </p>

            <h5>Aggregate and/or De-Identified Information</h5>
            <p>
              We aggregate and/or de-identify your information so that the
              information no longer relates to you individually. We may use and
              disclose aggregate and de-identified data for any lawful purpose.
            </p>

            <h5>Cookies and Other Electronic Technologies</h5>
            <p>
              Information is shared as stated in the Section entitled “Cookies
              and Other Electronic Technologies”.
            </p>

            <h5>Other Users of Our Services</h5>
            <p>
              We provide your information to other users of our Services if you
              choose to make your information publicly available in a publicly
              accessible area of the Services, such as in your campaign or in
              the comments.
            </p>

            <h3 className="title-sub">UNSOLICITED INFORMATION</h3>
            <p>
              Please be advised that some information you provide may be
              publicly accessible, such as information posted in forums or
              comment sections. We also collect information through customer
              support communications, your communication to us of ideas for new
              products or modifications to existing products, feedback and other
              unsolicited submissions (collectively, with publicly-accessible
              information, "Unsolicited Information"). By sending us Unsolicited
              Information, you further (a) agree that we are under no obligation
              of confidentiality, express or implied, with respect to the
              Unsolicited Information, (b) acknowledge that we may have
              something similar to the Unsolicited Information already under
              consideration or in development, (c) grant us an irrevocable,
              non-exclusive, royalty-free, perpetual, worldwide license to use,
              modify, prepare derivative works, publish, distribute and
              sublicense the Unsolicited Information, and (d) irrevocably waive,
              and cause to be waived, against KeyFaya Labs and its users any
              claims and assertions of any moral rights contained in such
              Unsolicited Information. This Unsolicited Information section
              shall survive any termination of your account or the Services.
            </p>

            <h4>CHILDREN</h4>
            <p>
              Our Services are not designed for use by individuals under the age
              of 13(or 16 for children located in Europe). If you are under the
              age of 13(or 16 for children located in Europe), please do not use
              the Services and/or submit any information through the Services.
              If you have reason to believe that a child under the age of 13(or
              16 for children located in Europe) has provided personal
              information to KeyFaya Labs through the Services, please contact
              us and we will delete that information from our databases to the
              extent required by law.
            </p>

            <h4>LINKS TO OTHER WEB SITES</h4>
            <p>
              This Policy applies only to the Services. The Services can contain
              links to other web sites not operated or controlled by KeyFaya
              Labs (the "Third-Party Sites"). The policies and procedures we
              described here do not apply to the Third-Party Sites. The links
              from the Services do not imply that KeyFaya Labs endorses or has
              reviewed the Third-Party Sites. We suggest contacting those sites
              directly for information on their respective privacy policies.
            </p>

            <h4>SECURITY</h4>
            <p>
              KeyFaya Labs has implemented technical and organizational security
              measures to protect the information provided via the Services from
              loss, misuse, and unauthorized access, disclosure, alteration, or
              destruction. For example, limiting access to information only to
              employees and authorized service providers who need to know such
              information for the purposes described in this Policy. However, no
              Internet or email transmission is ever fully secure or error free.
              In particular, email sent to or from the Services may not be
              secure. Therefore, you should take special care in deciding what
              information you send to us via email. Please keep this in mind
              when disclosing any information to KeyFaya Labs via the Internet.
            </p>

            <h4>DO-NOT-TRACK SIGNALS</h4>
            <p>
              Do Not Track (“DNT”) is a privacy preference that users can set in
              certain web browsers. We do not recognize or respond to
              browser-initiated DNT signals, as the Internet industry is
              currently still working toward defining exactly what DNT means,
              what it means to comply with DNT, and a common approach to
              responding to DNT. To learn more about Do Not Track, you can do so{" "}
              <a href="http://allaboutdnt.com/" target="_blank">
                here
              </a>
              .
            </p>

            <h4>RETENTION OF YOUR INFORMATION</h4>
            <p>
              We will only retain the information for as long as necessary for
              the purpose for which that information was collected and to the
              extent permitted or required by applicable laws. When we no longer
              need to use your information, we will remove it from our systems
              and records and/or take step to anonymize it so that you can no
              longer be identified from it in accordance with our internal
              document retention policies.
            </p>
            <p>
              When determining the retention period, we take into account
              various criteria, such as the type of products and services
              requested by or provided to you, the nature and length of our
              relationship with you, possible re-enrolment with our products or
              services, the impact on the services we provide to you if we
              delete some information about you, mandatory retention periods
              provided by law and the statute of limitations.
            </p>

            <h4>PRIVACY SHIELD</h4>
            <p>
              KeyFaya Labs has certified with the{" "}
              <a href="https://www.privacyshield.gov/welcome" target="_blank">
                EU-U.S. and Swiss-U.S. Privacy Shield
              </a>{" "}
              as set forth by the U.S. Department of Commerce regarding the
              collection, use, and retention of “personal data” (as defined
              under the Privacy Shield principles) from Europe. KeyFaya Labs has
              certified that it adheres to the Privacy Shield Principles of
              notice, choice, onward transfer, security, data integrity, access,
              and enforcement for such personal data. To learn more about the
              Privacy Shield Framework, visit the Department of Commerce’s
              Privacy Shield list by clicking{" "}
              <a href="https://www.privacyshield.gov/list" target="_blank">
                here
              </a>
              .
            </p>
            <p>
              As required under the principles, when KeyFaya Labs receives
              information under the Privacy Shield and then transfers it to a
              third-party service provider acting as an agent on KeyFaya Labs’s
              behalf, KeyFaya Labs has certain liability under the Privacy
              Shield if both (i) the agent processes the information in a manner
              inconsistent with the Privacy Shield and (ii) KeyFaya Labs is
              responsible for the event giving rise to the damage.
            </p>
            <p>
              If you have any questions or complaints about KeyFaya Labs’s
              privacy practices, including questions related to the Privacy
              Shield, you can contact us.
            </p>
            <p>
              Residents of Europe can elect to arbitrate unresolved complaints
              but prior to initiating such arbitration, you must: (1) contact
              KeyFaya Labs and afford us the opportunity to resolve the issue;
              (2) seek assistance from KeyFaya Labs’s designated independent
              recourse mechanism above; and (3) contact the U.S. Department of
              Commerce (either directly or through a European Data Protection
              Authority) and afford the Department of Commerce time to attempt
              to resolve the issue. Each party shall be responsible for its own
              attorney’s fees. Please be advised that, pursuant to the Privacy
              Shield, the arbitrator(s) can only impose individual-specific,
              non-monetary, equitable relief necessary to remedy any violation
              of the Privacy Shield Principles with respect to the individual.
              KeyFaya Labs is subject to the investigatory and enforcement
              powers of the U.S. Federal Trade Commission (FTC).
            </p>
            <p>YOUR RIGHTS AND CHOICES</p>
            <p>
              You can access and correct certain information about you by
              visiting the “Account Settings” portion of our Platform. You can
              control whether certain information you post to the Services is
              publicly available to others by adjusting the settings in your
              account. You can also request in writing copies of personal
              information about you held by us. If that information is
              inaccurate, please let us know and we will endeavor to make the
              necessary amendments, erase, or block the relevant information as
              you request. For more information on your rights if you are in
              Europe, please see the "Users in Europe" section below.
            </p>

            <h3 className="title-sub">USERS FROM OUTSIDE THE UNITED STATES</h3>
            <p>
              The Platform is hosted in the United States. If you are visiting
              the Platform from outside the United States, please be aware that
              your information may be transferred to, stored and processed in
              the United States where our servers are located and our central
              database is operated. The data protection and other laws of the
              United States and other countries might not be as comprehensive as
              those in your country. By using the Platform, you consent to your
              information being transferred to our facilities and to the
              facilities of those third parties with whom we share it as
              described in this Policy.
            </p>

            <h4>USERS IN EUROPE</h4>
            <p>
              If you are located in Europe, you have the right to ask for an
              overview of the information we process about you, and for a copy
              of your information. In addition, you may request us to update and
              correct inaccuracies, delete your information, restrict processing
              of your information, or exercise your right to data portability to
              easily transfer your information to another company. In some
              cases, you may object to the processing of your information and
              where we have asked you for your consent to process your
              information, you can withdraw it at any time. The withdrawal of
              consent shall not affect the lawfulness of the processing based on
              consent before its withdrawal. The above rights may be limited
              under applicable law.
            </p>
            <p>
              Marketing opt out. Each marketing communication we send you will
              contain instructions permitting you to "opt out" of receiving
              future marketing communications. In addition, if at any time you
              wish not to receive any future marketing communications or you
              wish to have your name deleted from our mailing lists, please
              contact us at the email address or mailing address set forth under
              “Contacting KeyFaya Labs”. If you opt out of receiving marketing
              communications or other information we think may interest you, we
              can still send you emails about your account or any Services you
              have requested or received from us.
            </p>
            <p>
              You can make a request to exercise any of these rights in relation
              to your information by sending the request to us at the email
              address or mailing address set forth under "Contacting KeyFaya
              Labs". For your own privacy and security, at our discretion, we
              may require you to provide your identity before providing the
              requested information. Please note that KeyFaya Labs may take up
              to 30 days to fulfill such requests.
            </p>
            <p>
              The above rights can be limited under applicable law. You also
              have the right to lodge a complaint with the local data protection
              authority if you believe that we have not complied with applicable
              data protection laws. You also have the right to lodge a complaint
              with the supervisory authority of your residence, place of work or
              where the incident took place.
            </p>

            <h3>GDPR Privacy</h3>
            <h3 className="title-sub">
              Legal Basis for Processing Personal Data under GDPR
            </h3>

            <p>
              We may process Personal Data under the following conditions:
              <ul>
                <li>
                  <strong>Consent:</strong> You have given Your consent for
                  processing Personal Data for one or more specific purposes.
                </li>
                <li>
                  <strong>Performance of a contract:</strong> Provision of
                  Personal Data is necessary for the performance of an agreement
                  with You and/or for any pre-contractual obligations thereof.
                </li>
                <li>
                  <strong>Legal obligations:</strong> Processing Personal Data
                  is necessary for compliance with a legal obligation to which
                  the Company is subject.
                </li>
                <li>
                  <strong>Vital interests:</strong> Processing Personal Data is
                  necessary in order to protect Your vital interests or of
                  another natural person.
                </li>
                <li>
                  <strong>Public interests:</strong> Processing Personal Data is
                  related to a task that is carried out in the public interest
                  or in the exercise of official authority vested in the
                  Company.
                </li>
                <li>
                  <strong>Legitimate interests:</strong> Processing Personal
                  Data is necessary for the purposes of the legitimate interests
                  pursued by the Company.
                </li>
              </ul>
              In any case, the Company will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>

            <h3 className="title-sub">Your Rights under the GDPR</h3>
            <p>
              The Company undertakes to respect the confidentiality of Your
              Personal Data and to guarantee You can exercise Your rights.
            </p>
            <p>
              You have the right under this Privacy Policy, and by law if You
              are within the EU, to:
              <ul>
                <li>
                  <strong>Request access to Your Personal Data.</strong> The
                  right to access, update or delete the information We have on
                  You. Whenever made possible, you can access, update or request
                  deletion of Your Personal Data directly within Your account
                  settings section. If you are unable to perform these actions
                  yourself, please contact Us to assist You. This also enables
                  You to receive a copy of the Personal Data We hold about You.
                </li>
                <li>
                  <strong>
                    Request correction of the Personal Data that We hold about
                    You.
                  </strong>{" "}
                  You have the right to to have any incomplete or inaccurate
                  information We hold about You corrected.
                </li>
                <li>
                  <strong>Object to processing of Your Personal Data.</strong>{" "}
                  This right exists where We are relying on a legitimate
                  interest as the legal basis for Our processing and there is
                  something about Your particular situation, which makes You
                  want to object to our processing of Your Personal Data on this
                  ground. You also have the right to object where We are
                  processing Your Personal Data for direct marketing purposes.
                </li>
                <li>
                  <strong>Request erasure of Your Personal Data.</strong> You
                  have the right to ask Us to delete or remove Personal Data
                  when there is no good reason for Us to continue processing it.
                </li>
                <li>
                  <strong>Request the transfer of Your Personal Data.</strong>{" "}
                  We will provide to You, or to a third-party You have chosen,
                  Your Personal Data in a structured, commonly used,
                  machine-readable format. Please note that this right only
                  applies to automated information which You initially provided
                  consent for Us to use or where We used the information to
                  perform a contract with You.
                </li>
                <li>
                  <strong>Withdraw Your consent.</strong> You have the right to
                  withdraw Your consent on using your Personal Data. If You
                  withdraw Your consent, We may not be able to provide You with
                  access to certain specific functionalities of the Service.
                </li>
              </ul>
            </p>

            <h3 className="title-sub">
              Exercising of Your GDPR Data Protection Rights
            </h3>
            <p>
              You may exercise Your rights of access, rectification,
              cancellation and opposition by contacting Us. Please note that we
              may ask You to verify Your identity before responding to such
              requests. If You make a request, We will try our best to respond
              to You as soon as possible.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about Our collection and use of Your Personal Data. For more
              information, if You are in the European Economic Area (EEA),
              please contact Your local data protection authority in the EEA.
            </p>

            <h4>OTHER TERMS</h4>
            <p>
              Your access to and use of the Services is subject to the Terms of
              Service and such other terms, which may be made available to you
              in connection with your use of the Services.
            </p>

            <h4>CHANGES TO KEYFAYA LABS'S PRIVACY POLICY</h4>
            <p>
              KeyFaya Labs reserves the right to update or modify this Policy at
              any time and from time to time. We will notify you of any updates
              or changes we make to this Policy. If you disagree with our
              revisions to the Policy, you can deactivate your account or
              discontinue the use of our Services. Please review this Policy
              periodically for any updates or changes to this Policy.
            </p>
            <p>
              If you are in Europe, you will not be required to agree to any
              changes to this Policy, but you do acknowledge that you have read
              and understood its terms.
            </p>
            <p>__________________-</p>

            <h3 className="title-main">
              The California Consumer Privacy Act of 2018 (“CCPA”) provides
              California residents with additional rights.
            </h3>

            <h2>CCPA Privacy</h2>
            <hr />

            <p>
              This privacy notice section for California residents supplements
              the information contained in Our Privacy Policy and it applies
              solely to all visitors, users, and others who reside in the State
              of California.
            </p>

            <h3>Categories of Personal Information Collected</h3>
            <p>
              We collect information that identifies, relates to, describes,
              references, is capable of being associated with, or could
              reasonably be linked, directly or indirectly, with a particular
              Consumer or Device. The following is a list of categories of
              personal information which we may collect or may have been
              collected from California residents within the last twelve (12)
              months.
            </p>
            <p>
              Please note that the categories and examples provided in the list
              below are those defined in the CCPA. This does not mean that all
              examples of that category of personal information were in fact
              collected by Us, but reflects our good faith belief to the best of
              our knowledge that some of that information from the applicable
              category may be and may have been collected. For example, certain
              categories of personal information would only be collected if You
              provided such personal information directly to Us.
            </p>

            <h3 className="title-sub">Category A: Identifiers.</h3>
            <p>
              Examples: A real name, alias, postal address, unique personal
              identifier, online identifier, Internet Protocol address, email
              address, account name, driver's license number, passport number,
              or other similar identifiers.
              <br />
              Collected: Yes.
            </p>

            <h3 className="title-sub">
              Category B: Personal information categories listed in the
              California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
            </h3>
            <p>
              Examples: A name, signature, Social Security number, physical
              characteristics or description, address, telephone number,
              passport number, driver's license or state identification card
              number, insurance policy number, education, employment, employment
              history, bank account number, credit card number, debit card
              number, or any other financial information, medical information,
              or health insurance information. Some personal information
              included in this category may overlap with other categories.
              <br />
              Collected: Yes.
            </p>

            <h3 className="title-sub">
              Category C: Protected classification characteristics under
              California or federal law.
            </h3>
            <p>
              Examples: Age (40 years or older), race, color, ancestry, national
              origin, citizenship, religion or creed, marital status, medical
              condition, physical or mental disability, sex (including gender,
              gender identity, gender expression, pregnancy or childbirth and
              related medical conditions), sexual orientation, veteran or
              military status, genetic information (including familial genetic
              information).
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">Category D: Commercial information.</h3>
            <p>
              Examples: Records and history of products or services purchased or
              considered.
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">Category E: Biometric information.</h3>
            <p>
              Examples: Genetic, physiological, behavioral, and biological
              characteristics, or activity patterns used to extract a template
              or other identifier or identifying information, such as,
              fingerprints, faceprints, and voiceprints, iris or retina scans,
              keystroke, gait, or other physical patterns, and sleep, health, or
              exercise data.
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">
              Category F: Internet or other similar network activity.
            </h3>
            <p>
              Examples: Interaction with our Service or advertisement.
              <br />
              Collected: Yes.
            </p>

            <h3 className="title-sub">Category G: Geolocation data.</h3>
            <p>
              Examples: Approximate physical location.
              <br />
              Collected: Yes.
            </p>

            <h3 className="title-sub">Category H: Sensory data.</h3>
            <p>
              Examples: Audio, electronic, visual, thermal, olfactory, or
              similar information.
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">
              Category I: Professional or employment-related information.
            </h3>
            <p>
              Examples: Current or past job history or performance evaluations.
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">
              Category J: Non-public education information (per the Family
              Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
              C.F.R. Part 99)).
            </h3>
            <p>
              Examples: Education records directly related to a student
              maintained by an educational institution or party acting on its
              behalf, such as grades, transcripts, class lists, student
              schedules, student identification codes, student financial
              information, or student disciplinary records.
              <br />
              Collected: No.
            </p>

            <h3 className="title-sub">
              Category K: Inferences drawn from other personal information.
            </h3>
            <p>
              Examples: Profile reflecting a person's preferences,
              characteristics, psychological trends, predispositions, behavior,
              attitudes, intelligence, abilities, and aptitudes.
              <br />
              Collected: No.
            </p>

            <p>Under CCPA, personal information does not include:</p>
            <p>
              Publicly available information from government records
              <br />
              Deidentified or aggregated consumer information
            </p>

            <p>
              Information excluded from the CCPA's scope, such as:
              <ul>
                <li>
                  Health or medical information covered by the Health Insurance
                  Portability and Accountability Act of 1996 (HIPAA) and the
                  California Confidentiality of Medical Information Act (CMIA)
                  or clinical trial data
                </li>
                <li>
                  Personal Information covered by certain sector-specific
                  privacy laws, including the Fair Credit Reporting Act (FRCA),
                  the Gramm-Leach-Bliley Act (GLBA) or California Financial
                  Information Privacy Act (FIPA), and the Driver's Privacy
                  Protection Act of 1994
                </li>
              </ul>
            </p>

            <h3>Sources of Personal Information</h3>
            <p>
              We obtain the categories of personal information listed above from
              the following categories of sources:
            </p>
            <p>
              <strong>Directly from You.</strong> For example, from the forms
              You complete on our Service, preferences You express or provide
              through our Service.
            </p>
            <p>
              <strong>Indirectly from You.</strong> For example, from observing
              Your activity on our Service.
            </p>
            <p>
              <strong>Automatically from You.</strong> For example, through
              cookies We or our Service Providers set on Your Device as You
              navigate through our Service.
            </p>
            <p>
              <strong>From Service Providers.</strong> For example, third-party
              vendors to monitor and analyze the use of our Service, or other
              third-party vendors that We use to provide the Service to You.
            </p>

            <h3>
              Use of Personal Information for Business Purposes or Commercial
              Purposes
            </h3>
            <p>
              We may use or disclose personal information We collect for
              "business purposes" or "commercial purposes" (as defined under the
              CCPA), which may include the following examples:
            </p>
            <p>To operate our Service and provide You with our Service.</p>
            <p>
              To provide You with support and to respond to Your inquiries,
              including to investigate and address Your concerns and monitor and
              improve our Service.
            </p>
            <p>
              To fulfill or meet the reason You provided the information. For
              example, if You share Your contact information to ask a question
              about our Service, We will use that personal information to
              respond to Your inquiry.
            </p>
            <p>
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </p>
            <p>
              As described to You when collecting Your personal information or
              as otherwise set forth in the CCPA.
            </p>
            <p>For internal administrative and auditing purposes.</p>
            <p>
              To detect security incidents and protect against malicious,
              deceptive, fraudulent or illegal activity, including, when
              necessary, to prosecute those responsible for such activities.
            </p>
            <p>
              Please note that the examples provided above are illustrative and
              not intended to be exhaustive. For more details on how we use this
              information, please refer to the "Use of Your Personal Data"
              section.
            </p>
            <p>
              If We decide to collect additional categories of personal
              information or use the personal information We collected for
              materially different, unrelated, or incompatible purposes We will
              update this Privacy Policy.
            </p>

            <h3>
              Disclosure of Personal Information for Business Purposes or
              Commercial Purposes
            </h3>
            <p>
              We may use or disclose and may have used or disclosed in the last
              twelve (12) months the following categories of personal
              information for business or commercial purposes:
            </p>
            <ul>
              <li>Category A: Identifiers</li>
              <li>
                Category B: Personal information categories listed in the
                California Customer Records statute (Cal. Civ. Code §
                1798.80(e))
              </li>
              <li>Category F: Internet or other similar network activity.</li>
              <li>Category G: Geolocation data</li>
            </ul>
            <p>
              Please note that the categories listed above are those defined in
              the CCPA. This does not mean that all examples of that category of
              personal information were in fact disclosed, but reflects our good
              faith belief to the best of our knowledge that some of that
              information from the applicable category may be and may have been
              disclosed.
            </p>
            <p>
              When We disclose personal information for a business purpose or a
              commercial purpose, We enter a contract that describes the purpose
              and requires the recipient to both keep that personal information
              confidential and not use it for any purpose except performing the
              contract.
            </p>

            <h3>Sale of Personal Information</h3>
            <p>
              As defined in the CCPA, "sell" and "sale" mean selling, renting,
              releasing, disclosing, disseminating, making available,
              transferring, or otherwise communicating orally, in writing, or by
              electronic or other means, a consumer's personal information by
              the business to a third party for valuable consideration. This
              means that We may have received some kind of benefit in return for
              sharing personal Iinformation, but not necessarily a monetary
              benefit.
            </p>
            <p>
              Please note that the categories listed below are those defined in
              the CCPA. This does not mean that all examples of that category of
              personal information were in fact sold, but reflects our good
              faith belief to the best of our knowledge that some of that
              information from the applicable category may be and may have been
              shared for value in return.
            </p>
            <p>
              We may sell and may have sold in the last twelve (12) months the
              following categories of personal information:
            </p>
            <ul>
              <li>Category A: Identifiers</li>
              <li>
                Category B: Personal information categories listed in the
                California Customer Records statute (Cal. Civ. Code §
                1798.80(e))
              </li>
              <li>Category F: Internet or other similar network activity</li>
              <li>Category G: Geolocation data</li>
            </ul>

            <h3>Share of Personal Information</h3>
            <p>
              We may share Your personal information identified in the above
              categories with the following categories of third parties:
              <ul>
                <li>Service Providers</li>
                <li>Our affiliates</li>
                <li>Our business partners</li>
                <li>
                  Third party vendors to whom You or Your agents authorize Us to
                  disclose Your personal information in connection with products
                  or services We provide to You
                </li>
              </ul>
            </p>

            <h3>
              Sale of Personal Information of Minors Under 16 Years of Age
            </h3>
            <p>
              We do not sell the personal information of Consumers We actually
              know are less than 16 years of age, unless We receive affirmative
              authorization (the "right to opt-in") from either the Consumer who
              is between 13 and 16 years of age, or the parent or guardian of a
              Consumer less than 13 years of age. Consumers who opt-in to the
              sale of personal information may opt-out of future sales at any
              time. To exercise the right to opt-out, You (or Your authorized
              representative) may submit a request to Us by contacting Us.
            </p>
            <p>
              If You have reason to believe that a child under the age of 13 (or
              16) has provided Us with personal information, please contact Us
              with sufficient detail to enable Us to delete that information.
            </p>

            <h3>Your Rights under the CCPA</h3>
            <p>
              The CCPA provides California residents with specific rights
              regarding their personal information. If You are a resident of
              California, You have the following rights:
            </p>
            <p>
              <strong>The right to notice.</strong> You have the right to be
              notified which categories of Personal Data are being collected and
              the purposes for which the Personal Data is being used.
            </p>
            <p>
              <strong>The right to request.</strong> Under CCPA, You have the
              right to request that We disclose information to You about Our
              collection, use, sale, disclosure for business purposes and share
              of personal information. Once We receive and confirm Your request,
              We will disclose to You:
              <ul>
                <li>
                  The categories of personal information We collected about You
                </li>
                <li>
                  The categories of sources for the personal information We
                  collected about You
                </li>
                <li>
                  Our business or commercial purpose for collecting or selling
                  that personal information
                </li>
                <li>
                  The categories of third parties with whom We share that
                  personal information
                </li>
                <li>
                  The specific pieces of personal information We collected about
                  You
                </li>
                <li>
                  If we sold Your personal information or disclosed Your
                  personal information for a business purpose, We will disclose
                  to You:
                  <ul>
                    <li>
                      The categories of personal information categories sold
                    </li>
                    <li>
                      The categories of personal information categories
                      disclosed
                    </li>
                  </ul>
                </li>
              </ul>
            </p>

            <p>
              <strong>
                The right to say no to the sale of Personal Data (opt-out).
              </strong>{" "}
              You have the right to direct Us to not sell Your personal
              information. To submit an opt-out request please contact Us.
            </p>
            <p>
              <strong>The right to delete Personal Data.</strong> You have the
              right to request the deletion of Your Personal Data, subject to
              certain exceptions. Once We receive and confirm Your request, We
              will delete (and direct Our Service Providers to delete) Your
              personal information from our records, unless an exception
              applies. We may deny Your deletion request if retaining the
              information is necessary for Us or Our Service Providers to:
              <ul>
                <li>
                  Complete the transaction for which We collected the personal
                  information, provide a good or service that You requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with You, or otherwise perform
                  our contract with You.
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities.
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality.
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise their free speech rights, or exercise another right
                  provided for by law.
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act (Cal. Penal Code § 1546 et. seq.).
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the
                  information's deletion may likely render impossible or
                  seriously impair the research's achievement, if You previously
                  provided informed consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on Your relationship with Us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which You provided it.
                </li>
              </ul>
            </p>

            <p>
              <strong>The right not to be discriminated against.</strong> You
              have the right not to be discriminated against for exercising any
              of Your consumer's rights, including by:
              <ul>
                <li>Denying goods or services to You</li>
                <li>
                  Charging different prices or rates for goods or services,
                  including the use of discounts or other benefits or imposing
                  penalties
                </li>
                <li>
                  Providing a different level or quality of goods or services to
                  You
                </li>
                <li>
                  Suggesting that You will receive a different price or rate for
                  goods or services or a different level or quality of goods or
                  services
                </li>
              </ul>
            </p>

            <h3>Exercising Your CCPA Data Protection Rights</h3>
            <p>
              In order to exercise any of Your rights under the CCPA, and if You
              are a California resident, You can contact Us:
            </p>
            <p>
              By email:{" "}
              <a href="mailto:khitmaapp@gmail.com">khitmaapp@gmail.com</a>
            </p>
            <p>
              Only You, or a person registered with the California Secretary of
              State that You authorize to act on Your behalf, may make a
              verifiable request related to Your personal information.
            </p>
            <p>Your request to Us must:</p>
            <p>
              Provide sufficient information that allows Us to reasonably verify
              You are the person about whom We collected personal information or
              an authorized representative
            </p>
            <p>
              Describe Your request with sufficient detail that allows Us to
              properly understand, evaluate, and respond to it
            </p>
            <p>
              We cannot respond to Your request or provide You with the required
              information if we cannot:
            </p>
            <p>Verify Your identity or authority to make the request</p>
            <p>And confirm that the personal information relates to You</p>
            <p>
              We will disclose and deliver the required information free of
              charge within 45 days of receiving Your verifiable request. The
              time period to provide the required information may be extended
              once by an additional 45 days when reasonable necessary and with
              prior notice.
            </p>
            <p>
              Any disclosures We provide will only cover the 12-month period
              preceding the verifiable request's receipt.
            </p>
            <p>
              For data portability requests, We will select a format to provide
              Your personal information that is readily useable and should allow
              You to transmit the information from one entity to another entity
              without hindrance.
            </p>

            <h3>Do Not Sell My Personal Information</h3>
            <p>
              You have the right to opt-out of the sale of Your personal
              information. Once We receive and confirm a verifiable consumer
              request from You, we will stop selling Your personal information.
              To exercise Your right to opt-out, please contact Us.
            </p>
            <p>
              The Service Providers we partner with (for example, our analytics
              or advertising partners) may use technology on the Service that
              sells personal information as defined by the CCPA law. If you wish
              to opt out of the use of Your personal information for
              interest-based advertising purposes and these potential sales as
              defined under CCPA law, you may do so by following the
              instructions below.
            </p>
            <p>
              Please note that any opt out is specific to the browser You use.
              You may need to opt out on every browser that You use.
            </p>

            <h3 className="title-sub">Website</h3>
            <p>
              You can opt out of receiving ads that are personalized as served
              by our Service Providers by following our instructions presented
              on the Service:
            </p>
            <p>
              The NAI's opt-out platform:{" "}
              <a
                href="http://www.networkadvertising.org/choices/"
                target="_blank"
              >
                http://www.networkadvertising.org/choices/
              </a>
            </p>
            <p>
              The EDAA's opt-out platform{" "}
              <a href="http://www.youronlinechoices.com/" target="_blank">
                http://www.youronlinechoices.com/
              </a>
            </p>
            <p>
              The DAA's opt-out platform:{" "}
              <a
                href="http://optout.aboutads.info/?c=2&lang=EN"
                target="_blank"
              >
                http://optout.aboutads.info/?c=2&lang=EN
              </a>
            </p>
            <p>
              The opt out will place a cookie on Your computer that is unique to
              the browser You use to opt out. If you change browsers or delete
              the cookies saved by your browser, You will need to opt out again.
            </p>

            <h3 className="title-sub">Mobile Devices</h3>
            <p>
              Your mobile device may give You the ability to opt out of the use
              of information about the apps You use in order to serve You ads
              that are targeted to Your interests:
            </p>
            <p>
              "Opt out of Interest-Based Ads" or "Opt out of Ads
              Personalization" on Android devices
            </p>
            <p>"Limit Ad Tracking" on iOS devices,</p>
            <p>
              You can also stop the collection of location information from Your
              mobile device by changing the preferences on Your mobile device.
            </p>

            <h3>
              "Do Not Track" Policy as Required by California Online Privacy
              Protection Act (CalOPPA)
            </h3>
            <p>Our Service does not respond to Do Not Track signals.</p>
            <p>
              However, some third party websites do keep track of Your browsing
              activities. If You are visiting such websites, You can set Your
              preferences in Your web browser to inform websites that You do not
              want to be tracked. You can enable or disable DNT by visiting the
              preferences or settings page of Your web browser.
            </p>

            <h3>
              Your California Privacy Rights (California's Shine the Light law)
            </h3>
            <p>
              Under California Civil Code Section 1798 (California's Shine the
              Light law), California residents with an established business
              relationship with us can request information once a year about
              sharing their Personal Data with third parties for the third
              parties' direct marketing purposes.
            </p>
            <p>
              If you'd like to request more information under the California
              Shine the Light law, and if You are a California resident, You can
              contact Us using the contact information provided below.
            </p>

            <h3 className="title-sub">
              California Privacy Rights for Minor Users (California Business and
              Professions Code Section 22581)
            </h3>
            <p>
              California Business and Professions Code section 22581 allow
              California residents under the age of 18 who are registered users
              of online sites, services or applications to request and obtain
              removal of content or information they have publicly posted.
            </p>
            <p>
              To request removal of such data, and if You are a California
              resident, You can contact Us using the contact information
              provided below, and include the email address associated with Your
              account.
            </p>
            <p>
              Be aware that Your request does not guarantee complete or
              comprehensive removal of content or information posted online and
              that the law may not permit or require removal in certain
              circumstances.
            </p>

            <p>
              A number of statutory exceptions apply under the CCPA and to this
              California Consumer Privacy Act Notice, including the following,
              which limit certain rights under CCPA:
              <ul className="list-numeric">
                <li>
                  personal information reflecting a written or verbal
                  communication or a transaction between the business and the
                  consumer, where the consumer is a natural person who is acting
                  as an employee, owner, director, officer, or contractor of a
                  company, partnership, sole proprietorship, non-profit, or
                  government agency and whose communications or transaction with
                  the business occur solely within the context of the business
                  conducting due diligence regarding, or providing or receiving
                  a product or service to or from such company, partnership,
                  sole proprietorship, non-profit, or government agency
                </li>
                <li>
                  personal information that is collected by a business about a
                  natural person in the course of the natural person acting as a
                  job applicant to, an employee of, owner of, director of,
                  officer of, medical staff member of, or contractor of that
                  business to the extent that the natural person’s personal
                  information is collected and used by the business solely
                  within the context of the natural person’s role or former role
                  as a job applicant to, an employee of, owner of, director of,
                  officer of, medical staff member of, or a contractor of that
                  business
                </li>
                <li>
                  personal information that is collected by a business that is
                  emergency contact information of the natural person acting as
                  a job applicant to, an employee of, owner of, director of,
                  officer of, medical staff member of, or contractor of that
                  business to the extent that the personal information is
                  collected and used solely within the context of having an
                  emergency contact on file
                </li>
                <li>
                  personal information that is necessary for the business to
                  retain to administer benefits for another natural person
                  relating to the natural person acting as a job applicant to,
                  an employee of, owner of, director of, officer of, medical
                  staff member of, or contractor of that business to the extent
                  that the personal information is collected and used solely
                  within the context of administering those benefits.
                </li>
              </ul>
            </p>

            <p>
              For California residents, please note the personal information we
              collect, and the purposes for collecting such information, which
              we collect directly from you or automatically when you visit our
              Platform:
              <ul>
                <li>
                  CA Personal Information. Consistent with the "Information We
                  Collect" section in the Privacy Policy, we may collect the
                  following:
                  <ul>
                    <li>
                      Identifiers: namely a real name, alias, postal address,
                      Internet Protocol address, email address, or other similar
                      identifiers, physical characteristics or description,
                      telephone number, education, employment, employment
                      history, and health information
                    </li>
                    <li>
                      Commercial Information: credit card/debit card details,
                      bank account, or other financial information, records of
                      services obtained, or considered, or other purchasing or
                      consuming histories or tendencies;
                    </li>
                    <li>
                      Internet or Other Electronic Network Activity: browsing
                      history, search history, and information regarding a
                      consumer’s interaction with an Internet Web site,
                      application, or advertisement.
                    </li>
                  </ul>
                </li>
              </ul>
              Use of CA Personal Information. We use your personal information
              for the purposes described in the "Our Use of Information
              Collected" section in the Privacy Policy, such as to provide our
              Services and communicate with you.
            </p>

            <p>
              CA Personal Information Sold or Disclosed for Business Purposes.
              <ul>
                <li>KeyFaya Labs does not sell your data.</li>
              </ul>
            </p>

            <h4>CALIFORNIA RESIDENTS' PRIVACY RIGHTS UNDER THE CCPA</h4>
            <p>
              California residents have rights to request access to certain
              personal information collected about them over the past 12 months
              or deletion of their personal information, subject to certain
              exceptions, and may not be discriminated against because they
              exercise any of their rights under the CCPA in violation of Cal.
              Civ. Code §1798.125. They can make requests as follows:
            </p>
            <p>
              In order to protect your privacy and security, we will verify your
              identity prior to responding to your request, by asking for
              information such as your name, email, and/or information already
              maintained by KeyFaya Labs about you or additional proof where
              necessary. If you are making this request on behalf of someone
              else, we may request the authorized agent provide us with a copy
              of a valid power of attorney or evidence of the written
              authorization.
            </p>
            <p>
              In addition, please exercise your choices regarding any action
              taken by the Social Networking Services we employ, which links are
              provided here:
            </p>

            <table border="1">
              <tr>
                <th>Facebook</th>
                <td>
                  <a
                    href="https://www.facebook.com/privacy/explanation"
                    target="_blank"
                  >
                    Facebook Privacy Policy
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.facebook.com/legal/terms"
                    target="_blank"
                  >
                    Facebook Terms of Service
                  </a>
                </td>
              </tr>

              <tr>
                <th>Instagram</th>
                <td>
                  <a
                    href="https://help.instagram.com/519522125107875"
                    target="_blank"
                  >
                    Instagram Privacy Policy
                  </a>
                </td>
                <td>
                  <a
                    href="https://help.instagram.com/581066165581870"
                    target="_blank"
                  >
                    Instagram Terms of Service
                  </a>
                </td>
              </tr>

              <tr>
                <th>LinkedIn</th>
                <td>
                  <a
                    href="https://www.linkedin.com/legal/privacy-policy?trk=lithograph_footer-privacy-policy"
                    target="_blank"
                  >
                    LinkedIn Privacy Policy
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.linkedin.com/legal/l/service-terms"
                    target="_blank"
                  >
                    LinkedIn Terms of Service
                  </a>
                </td>
              </tr>

              <tr>
                <th>Vimeo</th>
                <td>
                  <a href="https://twitter.com/en/privacy" target="_blank">
                    Vimeo Privacy Policy
                  </a>
                </td>
                <td>
                  <a href="https://twitter.com/en/tos" target="_blank">
                    Vimeo Terms of Service
                  </a>
                </td>
              </tr>

              <tr>
                <th>YouTube</th>
                <td>
                  <a href="https://policies.google.com/privacy" target="_blank">
                    YouTube Privacy Policy
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/static?template=terms"
                    target="_blank"
                  >
                    YouTube Terms of Service
                  </a>
                </td>
              </tr>
            </table>

            <br />

            <h3>Changes to this Privacy Policy</h3>
            <p>
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              "Last updated" date at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us:
              <ul>
                <li>
                  By email:{" "}
                  <a href="mailto:khitmaapp@gmail.com">khitmaapp@gmail.com</a>
                </li>
                <li>
                  For the KeyFaya Labs Platform please contact us at{" "}
                  <a href="http://www.KeyFaya.org" target="_blank">
                    www.KeyFaya.org.
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
