import {
  KhitmahType,
  KhitmahMemorialInfo,
  KhitmahFundRaiserInfo,
  Status,
  JuzzInfo,
} from '../../Models/khitmah';
import {KHITMAH_FORM_FIELDS} from '../../Utils/types';
import {Khitmah} from '../../Models/khitmah';
import moment from 'moment';
import {getInviteCode} from '../utils';
import {User} from '../../Models/user';
import {
  KhitmahPledge,
  Status as KhitmahPledgeStatus,
} from '../../Models/khitmahPledge';
import {
  FundRaiserAccount,
  AccountInfo,
  AccountType,
} from '../../Models/fundraiserAccount';
import {Notification, NotificationData} from '../../Models/notification';

export function generateKhitmahModelObj(values: KHITMAH_FORM_FIELDS): Khitmah {
  const juzzInfo: Map<string, JuzzInfo> = {} as Map<string, JuzzInfo>;
  const locationInfo: Map<string, Map<string, number>> = {} as Map<
    string,
    Map<string, number>
  >;
  const donationInfo: Map<string, Map<string, Map<string, number>>> = {} as Map<
    string,
    Map<string, Map<string, number>>
  >;

  const khitmah: Khitmah = {
    friendName: values.friendName,
    description: values.description,
    joiningCode: getInviteCode(),
    type: values.khitmahType,
    privacyType: values.khitmahPrivacy,
    endDate: moment().add(90, 'days').set({h: 23, m: 59}).toDate(),
    thankYouMessage: values.thankYouMessage,
    isEnded: false,
    hasFundRaiser: false,
    isFundRaiserEnded: false,
    stats: {
      claimedJuzz: 0,
      completedQuran: 0,
      completedJuzz: 0,
      donorCount: 0,
      juzzInfo: juzzInfo,
      locationInfo: locationInfo,
      donationInfo: donationInfo,
    },
    status: Status.CREATED,
    city: values.city,
    country: values.country,
  };
  if (
    values.memorialTime ||
    values.memorialEndTime ||
    values.memorialDate ||
    values.memorialLocation ||
    values.memorialLocationType
  ) {
    const memorialInfo: KhitmahMemorialInfo = {
      time: values.memorialTime,
      endTime: values.memorialEndTime,
      date: values.memorialDate,
      location: values.memorialLocation,
      locationType: values.memorialLocationType,
    };
    khitmah.memorial = memorialInfo;
  }
  if (
    values.amount &&
    values.paymentAccountNumber &&
    values.causeOfFundRaiser
  ) {
    const fundRaiserInfo: KhitmahFundRaiserInfo = {
      requestedAmount: +values.amount,
      receivedAmount: 0,
      cause: values.causeOfFundRaiser,
      thankYouMessage: values.thankYouMessageFundRaiser,
    };
    khitmah.fundRaiser = fundRaiserInfo;
    khitmah.hasFundRaiser = true;
  }

  if (values.khitmahType === KhitmahType.DECEASED) {
    khitmah.ageOfDeceased = values.ageOfDeceased;
    khitmah.dateOfPassing = values.dateOfPassing;
  }

  if (values.phoneNumber) {
    khitmah.phoneNumber = values.phoneNumber;
  }

  return khitmah;
}

export function generateUserModelObj(
  fullName: string,
  email: string,
  profileImageUrl: string,
  language: string,
  city?: string,
  country?: string,
  age?: string,
): User {
  const user: User = {
    fullName: fullName,
    email: email,
    profileImageUrl: profileImageUrl,
    language: language,
  };
  if (age) {
    user.age = age;
  }

  if (city) {
    user.city = city;
  }

  if (country) {
    user.country = country;
  }

  return user;
}

export function generateKhitmahPledgeModelObj(
  userId: string,
  khitmahId: string,
  khitmahOwnerId: string,
  juzzNumber: number,
  friendName: string,
  friendImageUrl: string | null,
  userCity: string,
  userCountry: string,
  khitmahSponsorMsg?: string,
): KhitmahPledge {
  const khitmahPledge: KhitmahPledge = {
    juzzNumber: juzzNumber,
    khitmahId: khitmahId,
    userId: userId,
    userCity: userCity,
    userCountry: userCountry,
    khitmahOwnerId: khitmahOwnerId,
    friendName: friendName,
    friendImageUrl: friendImageUrl ? friendImageUrl : '',
    status: KhitmahPledgeStatus.CLAIMED,
    notificationCount: 0,
    lastNotificationSentAt: null,
    reminderCount: 0,
    reminderDate: null,
    deletedAt: null,
    khitmahSponsorMsg: khitmahSponsorMsg || '',
  };
  return khitmahPledge;
}

export function generateFundraiserAccountModelObj(
  khitmahRef: any,
  accountId: string,
  accountType: AccountType,
  khitmahOwnerId: string,
): FundRaiserAccount {
  const account: AccountInfo = {
    id: accountId,
    type: accountType,
  };
  const fundraiserAccount: FundRaiserAccount = {
    khitmahId: khitmahRef.id,
    khitmah: khitmahRef,
    account: account,
    khitmahOwnerId: khitmahOwnerId,
  };
  return fundraiserAccount;
}

export function generateNotificationModelObj(
  title: string,
  message: string,
  data: NotificationData,
) {
  const notification: Notification = {
    title,
    msg: message,
    isRead: false,
    data,
  };
  return notification;
}
