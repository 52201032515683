import {User} from './../../Models/user';
import {trackEvent} from './index';
import {
  MIXPANEL_EVENTS,
  MIXPANEL_PROPERTIES,
} from '../../Constants/mixpanelConstants';
import {Khitmah} from '../../Models/khitmah';

interface EventProperties {
  [key: string]: any;
}

export const trackUserSignedup = (country?: string, city?: string) => {
  let properties: EventProperties = {};
  if (country) properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  if (city) properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.USER_SIGNUP, properties);
};

export const trackKhitmahCreated = (type: string) => {
  let properties: EventProperties = {};

  properties[MIXPANEL_PROPERTIES.TYPE] = type;
  trackEvent(MIXPANEL_EVENTS.KHITMAH_CREATED, properties);
};

export const trackPledgeClaimed = (country: string, city: string) => {
  let properties: EventProperties = {};

  properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.PLEDGE_CLAIMED, properties);
};

export const trackPledgeCompleted = (country: string, city: string) => {
  let properties: EventProperties = {};

  properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.PLEDGE_COMPLETED, properties);
};

export const trackFundsRaised = (
  amountRaised: number,
  fundRaiserType: string,
  khitmah: Khitmah,
) => {
  let properties: EventProperties = {};

  properties[MIXPANEL_PROPERTIES.FUND_RAISER_TYPE] = fundRaiserType;
  properties[MIXPANEL_PROPERTIES.AMOUNT_RAISED] = amountRaised;
  properties[MIXPANEL_PROPERTIES.KHITMAH_NAME] = khitmah.friendName;
  properties[MIXPANEL_PROPERTIES.KHITMAH_OWNER_NAME] = khitmah.user.fullName;
  if (khitmah.fundRaiser?.requestedAmount)
    properties[MIXPANEL_PROPERTIES.GOAL] = khitmah.fundRaiser?.requestedAmount;

  trackEvent(MIXPANEL_EVENTS.FUND_RAISED, properties);
};

export const trackPayItForward = (amountRaised: number, user: User) => {
  let properties: EventProperties = {};

  properties[MIXPANEL_PROPERTIES.PAY_IT_FORWARD_DONOR_NAME] = user.fullName;
  properties[MIXPANEL_PROPERTIES.PAY_IT_FORWARD_AMOUNT] = amountRaised;
  properties[MIXPANEL_PROPERTIES.CITY] = user.city ?? 'Unavailable';
  properties[MIXPANEL_PROPERTIES.COUNTRY] = user.country ?? 'Unavailable';

  trackEvent(MIXPANEL_EVENTS.PAY_IT_FORWARD, properties);
};
