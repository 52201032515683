import {generateNotificationModelObj} from '../../../Utils/Mapper/dataToModel';
import {INBOX_NOTIFICATIONS, MESSAGES} from '../../../Constants/database';
import firestore from '../../../Firebase/firestore';
import {getErrorMessageFromFirestoreError} from '../../../Utils/firestoreErrorMap';
import {NotificationData, NotificationType} from '../../../Models/notification';
import {getMessageByType} from './getMessages';
import {updateNotificationReadCount} from './updateNotification';
import {LANGUAGES} from '../../../Constants/appConstants';

export const generateUserWelcomeNotification = async (
  userId: string,
  locale: string,
  username: string,
) => {
  const userInboxCollection = firestore().collection(INBOX_NOTIFICATIONS);
  const userInboxDoc = userInboxCollection.doc(userId);
  const doc = await userInboxDoc.get();

  /**  Only create the user welcome notification if user inbox doesn't exists.
   *  This is done to overcome multiple notification generation on social login.
   */
  if (!doc.exists) {
    try {
      const messageObj: any = await getMessageByType(
        NotificationType.WELCOME_USER,
      );
      let {title, msg} = messageObj[locale]
        ? messageObj[locale]
        : messageObj[LANGUAGES.ENGLISH];
      title = title.replace(/\{0\}/, username); // Replacing title placeholder with username

      const data: NotificationData = {
        type: NotificationType.WELCOME_USER,
        userId,
      };

      createNotification(userId, title, msg, data);
    } catch (error) {
      throw new Error(error);
    }
  }
};

export const generateKhitmahCreatedNotification = async (
  userId: string,
  locale: string,
  khitmahId: string,
) => {
  try {
    const messageObj: any = await getMessageByType(
      NotificationType.KHITMAH_CREATED,
    );
    let {title, msg} = messageObj[locale]
      ? messageObj[locale]
      : messageObj[LANGUAGES.ENGLISH];

    const data: NotificationData = {
      type: NotificationType.KHITMAH_CREATED,
      khitmahId,
      userId,
    };

    createNotification(userId, title, msg, data);
  } catch (error) {
    throw new Error(error);
  }
};

export const generateKhitmahFundraiserNotification = async (
  userId: string,
  locale: string,
  khitmahId: string,
) => {
  try {
    const messageObj: any = await getMessageByType(
      NotificationType.KHITMAH_WITH_FUNDRAISER_CREATED,
    );
    let {title, msg} = messageObj[locale]
      ? messageObj[locale]
      : messageObj[LANGUAGES.ENGLISH];

    const data: NotificationData = {
      type: NotificationType.KHITMAH_WITH_FUNDRAISER_CREATED,
      khitmahId,
      userId,
    };

    createNotification(userId, title, msg, data);
  } catch (error) {
    throw new Error(error);
  }
};

const createNotification = async (
  userId: string,
  title: string,
  message: string,
  data: NotificationData,
) => {
  try {
    await updateNotificationReadCount(userId);
    const notification = generateNotificationModelObj(title, message, data);
    const userInboxDoc = firestore()
      .collection(INBOX_NOTIFICATIONS)
      .doc(userId);
    userInboxDoc.collection(MESSAGES).add({
      ...notification,
      updatedAt: firestore.FieldValue.serverTimestamp(),
      createdAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(error);
  }
};
