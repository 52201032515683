import React, { useContext, useEffect, useState } from "react";
import {
  compareCreatedAtDescending,
  getKhitmahsOfUser,
} from "@khitmah/shared/src/Services/Khitmah/getAllKhitmahs";
import KWHTable from "../../Components/Common/Table";
import { MyKhitmahsListColumns } from "./tableConfig.jsx";
import Loader from "../../Components/Common/Loader";
import { withHeader } from "../../Components/Header";
import useMyKhitmahs from "../../Hooks/useMyKhitmahs";

function MyKhitmahs(props) {
  const { isMyKhitmahsLoading, myKhitmahs, moveToDetails } = useMyKhitmahs();

  return isMyKhitmahsLoading ? (
    <Loader showLoader={true} />
  ) : (
    <div className="my-khitmah-screen generic-inner-sec">
      <KWHTable
        columns={MyKhitmahsListColumns}
        tableData={myKhitmahs}
        handleRowClick={moveToDetails}
      />
    </div>
  );
}

export default withHeader(MyKhitmahs);
