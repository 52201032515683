/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { signUpUser } from "@khitmah/shared/src/Services/User/Authentication/signUpUser";
import { AGE } from "@khitmah/shared/src/Constants/pickerConstants";
import { Form, Formik } from "formik";
import schema from "@khitmah/shared/src/Schemas/schemaSignUp";
import FormInput from "../Common/FormInput";
import "./styles.scss";
import { SIGN_UP } from "@khitmah/shared/src/Constants/form";
import { FIELD_TYPES } from "../../Constants/FormConstants";
import { getCities, getCountries } from "../../Models/Geo";
import { CAlert } from "@coreui/react";
import KWHModal from "../Common/Modal";
import Button from "../Common/Button";
import { useTranslation } from "react-i18next";
import { getBrowserDefaultLanguage } from "../../Helper/Common";
import { setMixpanelUser } from "../../Services/Mixpanel";
import { trackUserSignedup } from "../../Services/Mixpanel/trackEvents";

const countries = getCountries();

const SignUpForm = (props) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [cities, setCities] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    return () => {
      setErrorMessage("");
      setIsLoading(false);
    };
  }, []);

  const togglePasswordType = () => {
    if (passwordType === "password") setPasswordType("text");
    else setPasswordType("password");
  };

  const toggleConfirmPasswordType = () => {
    if (confirmPasswordType === "password") setConfirmPasswordType("text");
    else setConfirmPasswordType("password");
  };
  const getCitiesByCountry = (country) => {
    setCities(getCities(country));
  };

  const signUp = async (values) => {
    setErrorMessage("");
    setIsLoading(true);
    const { fullName, email, password, city, country, age } = values;
    try {
      const locale = getBrowserDefaultLanguage();
      const uId = await signUpUser(
        fullName,
        email,
        password,
        city,
        country,
        age,
        locale
      );
      setMixpanelUser(uId);
      trackUserSignedup(country, city);
      setIsLoading(false);
      setShowModal(true);
      props.navigateToLogin();
    } catch (error) {
      console.log("error", error);
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  const navigateToTermsAndCondition = () => {
    props.navigateToTermsAndCondition();
  };

  const closeModal = () => {
    setShowModal(false);
    props.navigateToLogin();
  };

  let initialValues = {
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    city: "",
    country: "",
    age: 18,
    tocAccepted: false,
  };
  return (
    <>
      <KWHModal
        visible={showModal}
        modalTitle={t("signUpScreen.signupSuccessful")}
        modalBody={t("loginScreen.verifyYourEmailText")}
        // onClose={closeModal}
        onOkClick={closeModal}
        btnSaveText={t("common.ok")}
      />
      <Formik
        noValidate={true}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={signUp}
        validateOnMount={true}
      >
        {(props) => {
          const { values, setFieldValue, isValid } = props;
          return (
            <>
              <Form>
                <FormInput
                  name={SIGN_UP.FULL_NAME}
                  label={t("signUpScreen.fullName")}
                  fieldType="text"
                  {...props}
                />

                <FormInput
                  name={SIGN_UP.EMAIL}
                  label={t("common.email")}
                  fieldType="email"
                  {...props}
                />

                <div className="pass-field-box">
                  <FormInput
                    name={SIGN_UP.PASSWORD}
                    label={t("signUpScreen.password")}
                    fieldType={passwordType}
                    {...props}
                  />

                  <span
                    className={`eye-btn ${passwordType === "text" && "open"}`}
                    onClick={togglePasswordType}
                  ></span>
                </div>

                <div className="pass-field-box">
                  <FormInput
                    name={SIGN_UP.CONFIRM_PASSWORD}
                    label={t("signUpScreen.confirmPassword")}
                    fieldType={confirmPasswordType}
                    {...props}
                  />
                  <span
                    className={`eye-btn ${
                      confirmPasswordType === "text" && "open"
                    }`}
                    onClick={toggleConfirmPasswordType}
                  ></span>
                </div>

                <FormInput
                  name={SIGN_UP.COUNTRY}
                  label={t("signUpScreen.selectCountry")}
                  options={countries}
                  type={FIELD_TYPES.AUTO_COMPLETE_SEARCH}
                  handlePickerChange={(value) => {
                    getCitiesByCountry(value);
                    setFieldValue(SIGN_UP.COUNTRY, value);
                  }}
                  value={values[SIGN_UP.COUNTRY]}
                  placeholder={t("common.selectAnItem")}
                  {...props}
                />
                <FormInput
                  name={SIGN_UP.CITY}
                  label={t("signUpScreen.selectCity")}
                  options={cities}
                  type={FIELD_TYPES.AUTO_COMPLETE_SEARCH}
                  handlePickerChange={(value) => {
                    setFieldValue(SIGN_UP.CITY, value);
                  }}
                  value={values[SIGN_UP.CITY]}
                  disabled={!values[SIGN_UP.COUNTRY]}
                  placeholder={t("common.selectAnItem")}
                  {...props}
                />

                <FormInput
                  name={SIGN_UP.AGE}
                  label={t("signUpScreen.age")}
                  type={FIELD_TYPES.NUMBER}
                  min={1}
                  max={120}
                  maxlength={3}
                  {...props}
                />

                <div className="read-term-box">
                  <FormInput
                    hideLabel={true}
                    name={SIGN_UP.TOC}
                    label={t("signUpScreen.termsAndConditions")}
                    type={FIELD_TYPES.CHECKBOX}
                    fieldType="checkbox"
                    {...props}
                  >
                    <div className="txt">
                      <>
                        {t("signUpScreen.termsAndConditionsStart")}
                        <a href="/terms" target="blank">
                          {t("signUpScreen.termsAndConditions")}
                        </a>
                        {t("signUpScreen.termsAndConditionEnd")}
                      </>
                    </div>
                  </FormInput>
                </div>

                {errorMessage && (
                  <div className="alert alert-danger text-center mt-2">
                    {errorMessage}
                  </div>
                )}

                <Button
                  type="submit"
                  disabled={!isValid || isLoading}
                  loading={isLoading}
                  btnText={t("common.signUp")}
                  className="primary-btn signup-btn"
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default SignUpForm;
