import {Entity} from './entity';
import {documentSnapshotToKhitmah, KhitmahType} from './khitmah';
import {documentSnapshotToUser} from './user';

export enum Status {
  RECEIVED = 'RECEIVED',
  DONATE_INPROGRESS = 'DONATE_INPROGRESS',
  DONATED = 'DONATED',
  DONATE_FAILED = 'DONATE_FAILED',
}

export enum TransactionMode {
  VISA = 'VISA',
  PAYPAL = 'PAYPAL',
  AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
  MASTERCARD = 'MASTERCARD',
  MAESTRO = 'MAESTRO',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
}

export interface DonationPledge extends Entity {
  amount: number;
  fee: number;
  serviceFee: number;
  friendName: string;
  isAnonymous: boolean;
  khitmah: any;
  khitmahId: number;
  khitmahType: KhitmahType;
  message: string;
  payer: any;
  payerCity: string;
  payerCountry: string;
  payerId: string;
  payerName: string;
  status: Status;
  transaction: any;
  transactionId: string;
  transactionMode: TransactionMode;
}

export const documentSnapshotToDonationPledge = (snap: any) => {
  const donationPledge = snap.data() as DonationPledge;
  if (snap.id === null) {
    throw new Error('donation pledge snapshot document id not found');
  }
  donationPledge.id = snap.id;

  return donationPledge;
};

export const fetchDonationPledgeReferences = async (
  donationPledge: DonationPledge,
) => {
  const khitmahSnap = await donationPledge.khitmah.get();
  donationPledge.khitmah = documentSnapshotToKhitmah(khitmahSnap);
  const userSnap = await donationPledge.payer.get();
  donationPledge.payer = documentSnapshotToUser(userSnap);

  return donationPledge;
};
