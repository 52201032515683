import React from "react";
import {
  compareRelevance,
  getSuggestedKhitmahs,
} from "@khitmah/shared/src/Services/Khitmah/getAllKhitmahs";
import { useContext, useEffect, useState } from "react";
import { AppRoutes } from "../Constants/RouteConstants";
import { AppContext } from "../Context/AppContext";
import { KhitmahListModel } from "../Helper/KhitmahList";
import { useHistory } from "react-router-dom";
import {
  documentSnapshotToKhitmah,
  fetchKhitmahReferences,
} from "@khitmah/shared/src/Models/khitmah";
import { convertKhitmahtoImplicitTypes } from "../Helper/Common";

function usePublicKhitmahs() {
  const {
    state: { loggedInUser, fetchingUserDetails },
  } = useContext(AppContext);

  const [isPublicKhitmahsLoading, setIsPublicKhitmahsLoading] = useState(
    fetchingUserDetails
  );
  const [publicKhitmahs, setPublicKhitmahs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!!loggedInUser.id) {
      setIsPublicKhitmahsLoading(true);
      getSuggestedKhitmahs(loggedInUser.id).onSnapshot(
        (snapshot) => {
          const data = snapshot.docs
            .map((doc) => doc.exists && documentSnapshotToKhitmah(doc))
            .sort(compareRelevance(loggedInUser));
          setPublicKhitmahs(data);
          setIsPublicKhitmahsLoading(false);
        },
        (error) => {
          setIsPublicKhitmahsLoading(false);
          console.log("Error in fetching public khitmahs", error);
        }
      );
    }
  }, [loggedInUser]);

  const moveToDetails = async (item) => {
    history.push(AppRoutes.KHITMAH_DETAILS, {
      khitmahId: item.id,
    });
  };

  return {
    publicKhitmahs,
    isPublicKhitmahsLoading,
    moveToDetails,
  };
}

export default usePublicKhitmahs;
