import { trackEvent } from "./index";

import {
  MIXPANEL_EVENTS,
  MIXPANEL_PROPERTIES,
} from "@khitmah/shared/src/Constants/mixpanelConstants";

export const trackUserSignedup = (country, city) => {
  const properties = {};
  if (country) properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  if (city) properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.USER_SIGNUP, properties);
};

export const trackKhitmahCreated = (type) => {
  let properties = {};

  properties[MIXPANEL_PROPERTIES.TYPE] = type;
  trackEvent(MIXPANEL_EVENTS.KHITMAH_CREATED, properties);
};

export const trackPledgeClaimed = (country, city) => {
  let properties = {};

  properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.PLEDGE_CLAIMED, properties);
};

export const trackPledgeCompleted = (country, city) => {
  let properties = {};

  properties[MIXPANEL_PROPERTIES.COUNTRY] = country;
  properties[MIXPANEL_PROPERTIES.CITY] = city;
  trackEvent(MIXPANEL_EVENTS.PLEDGE_COMPLETED, properties);
};

export const trackFundsRaised = (amountRaised, fundRaiserType, khitmah) => {
  let properties = {};

  properties[MIXPANEL_PROPERTIES.FUND_RAISER_TYPE] = fundRaiserType;
  properties[MIXPANEL_PROPERTIES.AMOUNT_RAISED] = amountRaised;
  properties[MIXPANEL_PROPERTIES.KHITMAH_NAME] = khitmah.friendName;
  properties[MIXPANEL_PROPERTIES.KHITMAH_OWNER_NAME] = khitmah.user.fullName;
  if (khitmah.fundRaiser?.requestedAmount)
    properties[MIXPANEL_PROPERTIES.GOAL] = khitmah.fundRaiser?.requestedAmount;

  trackEvent(MIXPANEL_EVENTS.FUND_RAISED, properties);
};

export const trackPayItForward = (amountRaised, user) => {
  let properties = {};

  properties[MIXPANEL_PROPERTIES.PAY_IT_FORWARD_DONOR_NAME] = user.fullName;
  properties[MIXPANEL_PROPERTIES.PAY_IT_FORWARD_AMOUNT] = amountRaised;
  properties[MIXPANEL_PROPERTIES.CITY] = user.city ?? "Unavailable";
  properties[MIXPANEL_PROPERTIES.COUNTRY] = user.country ?? "Unavailable";

  trackEvent(MIXPANEL_EVENTS.PAY_IT_FORWARD, properties);
};
