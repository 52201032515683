import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AssetsConstants from "../../Constants/AssetsConstants";
import ButtonPrimary from "../ButtonPrimary";
import "./style.scss";

function OnBoardingTutorial(props) {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const { closeOnBoarding } = props;

  const renderTutorialImage = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="onboard-step1">
              <div className="img-box">
                <img
                  src={AssetsConstants.onboardStep1}
                  alt="tutorial_image_1"
                />
              </div>

              <h2>
                Welcome & Thanks for installing <br />
                Khitmah with friends
              </h2>

              <p>
                We hope you will enjoy using it as much as we did designing it.
                The following turorials will help you get started with the basic
                functionality of the application. Have a great time & don’t
                hesitate to share your experience.
              </p>
            </div>
          </>
        );
      case 2:
        return (
          <div className="onboard-step2">
            <h2>Create New Khitmah</h2>
            <div className="img-box">
              <img src={AssetsConstants.onboardStep2} alt="tutorial_image_1" />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="onboard-step3">
            <h2>Join A Khitmah</h2>
            <div className="img-box">
              <img src={AssetsConstants.onboardStep3} alt="tutorial_image_1" />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="onboard-step4">
            <h2>Juz' Claim</h2>
            <div className="img-box">
              <img src={AssetsConstants.onboardStep4} alt="tutorial_image_1" />
            </div>
          </div>
        );
      case 5:
        return (
          <>
            <div className="onboard-step5">
              <div className="img-box">
                <img src={AssetsConstants.onboardStep5} alt="" />
              </div>

              <h2>Thank you for completing the tutorial!</h2>

              <p>
                Now create your own khitmah or join others. To start using all
                the features press the button below.
              </p>

              <ButtonPrimary
                name={"Continue To Khitmah"}
                onClick={closeOnBoarding}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className="overlay"></div>
      <div className="onboarding-popup">
        <div className="content-box">{renderTutorialImage()}</div>

        {step !== 5 && (
          <div className="bullet-skip-box">
            <ul>
              <li className={step === 1 ? "active" : ""}></li>
              <li className={step === 2 ? "active" : ""}></li>
              <li className={step === 3 ? "active" : ""}></li>
              <li className={step === 4 ? "active" : ""}></li>
              <li className={step === 5 ? "active" : ""}></li>
            </ul>
            <button className="skip-btn" onClick={closeOnBoarding}>
              {t("common.skipTutorial")}
            </button>
          </div>
        )}

        {step !== 1 && step !== 5 && (
          <button
            className="prev-btn primary-btn"
            onClick={() => setStep(step - 1)}
          >
            {t("common.previous")}
          </button>
        )}

        {step !== 5 && (
          <button
            className="next-btn primary-btn"
            onClick={() => setStep(step + 1)}
          >
            {t("common.next")}
          </button>
        )}
      </div>
    </>
  );
}

export default OnBoardingTutorial;
