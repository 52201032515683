import { Status } from "@khitmah/shared/src/Models/khitmahPledge";
import RenderText from "../Components/Common/RenderText";

export const PledgesListModel = (pledges) => ({
  pledges: pledges.map((p) => formatPledgeDetail(p)),
});

const formatPledgeDetail = (pledge) => ({
  ...pledge,
  friendName: pledge.friendName,
  friendImageUrl: pledge.friendImageUrl,
  juzzNumber: pledge.juzzNumber,
  khitmahId: pledge.khitmahId,
  khitmahOwnerId: pledge.khitmahOwnerId,
  reminderDate: pledge.reminderDate,
  status: pledge.status,
  createdAt: pledge.createdAt,

  //   hasFundRaiser: pledge.hasFundRaiser,
  //   completedCount: pledge.stats.completedCount || 0,
  //   progress: pledge.progress || "",
  //   fundRaiser: pledge.fundRaiser || "",
  //   claimedJuzz: pledge.stats.claimedJuzz,
  //   completedJuzz: pledge.stats.completedJuzz,
});

export const getStatusText = (status) => {
  let statusText = "";
  if (status === Status.CLAIMED) {
    statusText = RenderText("khitmah.continueReading");
  } else if (status === Status.COMPLETED) {
    statusText = RenderText("khitmah.completed");
  } else if (status === Status.LEFT) {
    statusText = RenderText("khitmah.claimRemoved");
  } else if (status === Status.REMOVED) {
    statusText = RenderText("khitmah.claimRemoved");
  }
  return statusText;
};

export const getStatusColor = (status) => {
  let statusColor = "";
  if (status === Status.CLAIMED) {
    statusColor = "continue";
  } else if (status === Status.COMPLETED) {
    statusColor = "";
  } else if (status === Status.LEFT) {
    statusColor = "removed";
  } else if (status === Status.REMOVED) {
    statusColor = "removed";
  }
  return statusColor;
};
