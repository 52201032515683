import React, { Component, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useTranslation } from "react-i18next";

const isNumber = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const Multipicker = (props) => {
  const {
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    selected,
    items,
    handlePickerChange,
    disabled,
    placeholder,
  } = props;

  const { t } = useTranslation();

  // keyValueMaping e.g. {FUNERAL_ARRANGEMENTS: "khitmah.funeralArrangements"}
  let keyValueMaping = {};
  if (typeof items[0] === "object") {
    items.forEach((item) => {
      keyValueMaping = { ...keyValueMaping, [item.value]: t(item.label) };
    });
  }

  // const optionsMaping =
  //   typeof items[0] === "string"
  //     ? items
  //     : items.map((item) => {
  //         return {
  //           key: item.value,
  //           label: t(item.label),
  //         };
  //       });

  const value = !!selected.length
    ? selected.length === 1
      ? `${keyValueMaping[selected[0]]}`
      : `${keyValueMaping[selected[0]]} +${t(selected.length - 1)} ${t(
          "common.more"
        )}`
    : t("common.selectOptions");

  return (
    <>
      <div className="field-group">
        <label>{t(label)}</label>
        <>
          <div className="form-control-multi-select">
            <DropdownMultiselect
              options={
                typeof items[0] === "object"
                  ? Object.values(keyValueMaping)
                  : items
              }
              name={name}
              placeholder={value}
              showSelectToggle={false}
              placeholderMultipleChecked={value}
              handleOnChange={(value) => {
                if (typeof items[0] === "object") {
                  const keys = value.map((v) =>
                    getKeyByValue(keyValueMaping, v)
                  );
                  handlePickerChange(keys);
                } else handlePickerChange(value);
              }}
              {...props}
              selected={
                typeof items[0] === "object"
                  ? selected.map((s) => keyValueMaping[s])
                  : selected
              }
            />
          </div>
          {errors[name] && touched[name] && (
            <div className="invalid-feedback d-block">{t(errors[name])}</div>
          )}
        </>
      </div>
    </>
  );
};
export default Multipicker;
