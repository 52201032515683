import React from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import "./style.scss";
import InfiniteScroll from "react-infinite-scroll-component";

const withScrollbar = (child) => {
  return (props) => {
    return props.scrollable ? (
      <Scrollbars
        className="m-custom-scroll"
        renderThumbVertical={(props) => (
          <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
      >
        {child(props)}
      </Scrollbars>
    ) : (
      <>{child(props)}</>
    );
  };
};

const TBody = (props) => {
  const { handleRowClick, columns, tableData, selectedClass } = props;
  const { t } = useTranslation();
  return (
    <>
      <table class="table common-data-table">
        <tbody key={props.key}>
          {tableData?.map((dataObj, index) => {
            return (
              <>
                <tr className="spacer" key={index}>
                  <td colSpan="5"></td>
                </tr>
                <tr
                  className={`${dataObj.selected && selectedClass} ${
                    typeof handleRowClick === "function" ? "pointer" : ""
                  }`}
                  key={dataObj.id + index}
                  onClick={() =>
                    typeof handleRowClick === "function" &&
                    handleRowClick(dataObj)
                  }
                >
                  {columns?.map((columnObj, columnIndex) => {
                    if (columnObj.type !== "hidden") {
                      return (
                        <td
                          className={columnObj.classNameTd}
                          key={columnObj.id}
                          width={columnObj.columnWidth}
                        >
                          {<columnObj.component data={dataObj} />}
                        </td>
                      );
                    } else {
                      return null;
                    }
                  })}
                </tr>
              </>
            );
          }) ?? (
            <tr>
              <td colSpan="5">
                {" "}
                <div className="no-record-found">
                  {t("common.noRecordFound")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

const ScrollableTBody = withScrollbar(TBody);

function KWHTable(props) {
  const { columns, key } = props;
  return (
    <>
      <table class="table common-data-table" key={key}>
        <thead>
          <tr>
            {columns?.map((column) => (
              <th className={column.classNameTh} width={column.columnWidth}>
                {column.name()}
              </th>
            ))}
          </tr>
        </thead>
      </table>

      <ScrollableTBody {...props} />
    </>
  );
}

export default KWHTable;
