import React, { useCallback, useContext, useEffect, useState } from "react";
import constate from "constate";
import { QURAN_TRANSLATION } from "@khitmah/shared/src/Constants/appConstants";
import { readJuzz } from "@khitmah/shared/src/Services/ReadQuran/readJuzz";
import {
  bookmarkJuzzPage,
  removePledgeAndRemoveBookmark,
  updatePledgeStatus,
} from "@khitmah/shared/src/Services/KhitmahPledge/updatePledge";
import { useLocation, useHistory } from "react-router-dom";
import { withModal } from "../Components/Common/Modal";
import {
  documentSnapshotToKhitmahPledge,
  Status,
} from "@khitmah/shared/src/Models/khitmahPledge";
import { useTranslation } from "react-i18next";
import { getPledgeById } from "@khitmah/shared/src/Services/KhitmahPledge/getAllKhitmahPledge";
import { AppContext } from "../Context/AppContext";
import { AppRoutes } from "../Constants/RouteConstants";
import { trackPledgeCompleted } from "../Services/Mixpanel/trackEvents";

const ReadJuzHook = ({ showModalWithOk, showModalWithYesAndNo }) => {
  const location = useLocation();
  const history = useHistory();
  let { id, khitmah } = location.state;
  const [juzzNumber, setJuzzNumber] = useState(null);
  const [bookmarkPage, setBookmarkPage] = useState(null);
  const [language, setLanguage] = useState(QURAN_TRANSLATION.NO_TRANSLATION);
  const [juzzPageDetails, setJuzzPageDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();
  const [pledgeDetails, setPledgeDetails] = useState({});

  const {
    state: { isPageUpdated },
  } = useContext(AppContext);

  useEffect(() => {
    setLoading(true);
    if (juzzNumber) {
      readJuzz(
        juzzNumber,
        language === QURAN_TRANSLATION.NO_TRANSLATION
          ? QURAN_TRANSLATION.ARABIC
          : language,
        currentPage
      )
        .then((juzzDetails) => {
          setJuzzPageDetails(juzzDetails);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, [language]);

  useEffect(() => {
    setLoading(true);
    getPledgeById(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          // console.log('No such document!');
          showModalWithOk(
            t("common.somethingWentWrong"),
            t("khitmah.unableToLoadJuzPledgedMessage"),
            () => {
              history.push(AppRoutes.DASHBOARD);
            }
          );
        } else {
          let khitmahPledge = documentSnapshotToKhitmahPledge(doc);
          setPledgeDetails(khitmahPledge);

          setJuzzNumber(khitmahPledge.juzzNumber);
          setIsCompleted(khitmahPledge.status === Status.COMPLETED);
          readJuzz(
            khitmahPledge.juzzNumber,

            language === QURAN_TRANSLATION.NO_TRANSLATION
              ? QURAN_TRANSLATION.ARABIC
              : language,

            khitmahPledge.bookmarkPage
          )
            .then((juzzDetails) => {
              setJuzzPageDetails(juzzDetails);
              setCurrentPage(
                khitmahPledge.bookmarkPage ?? juzzDetails.currentPage
              );
              setBookmarkPage(khitmahPledge.bookmarkPage);
              setIsBookmarked(
                khitmahPledge.bookmarkPage === juzzDetails.currentPage
              );
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        }
      });
  }, [id]);

  const onClickActionItem = (event) => {
    if (event === "language") {
      setOpenLanguage(!openLanguage);
      if (openChangeStatus) {
        setOpenChangeStatus(false);
      }
    } else if (event === "status") {
      setOpenChangeStatus(!openChangeStatus);
      if (openLanguage) {
        setOpenLanguage(false);
      }
    }
  };

  const bookmarkPageNumber = async (pageNumber) => {
    setLoading(true);
    try {
      await bookmarkJuzzPage(pageNumber, id);
      setIsBookmarked(true);
      setBookmarkPage(pageNumber);

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const cancelBookmark = async () => {
    setLoading(true);
    try {
      await bookmarkJuzzPage(null, id);
      setLoading(false);
      setIsBookmarked(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const unClaimJuzz = () => {
    showModalWithYesAndNo(
      t("pledges.unClaim"),
      t("pledges.removeClaimConfirmationMessage"),
      () => {
        setLoading(true);
        removePledgeAndRemoveBookmark(id)
          .then((success) => {
            setLoading(false);
            history.goBack();
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    );
  };

  const markAsComplete = () => {
    showModalWithYesAndNo(
      t("pledges.markAsComplete"),
      t("pledges.markAsCompleteConfirmationMessage"),
      () => {
        setLoading(true);
        updatePledgeStatus(Status.COMPLETED, id)
          .then((success) => {
            trackPledgeCompleted(
              pledgeDetails.userCountry,
              pledgeDetails.userCity
            );

            setLoading(false);
            history.goBack();
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    );
  };

  const readJuzzByPage = (pageNumber) => {
    setLoading(true);
    readJuzz(
      juzzNumber,
      language === QURAN_TRANSLATION.NO_TRANSLATION
        ? QURAN_TRANSLATION.ARABIC
        : language,
      pageNumber
    )
      .then((juzzDetails) => {
        setJuzzPageDetails(juzzDetails);
        setCurrentPage(juzzDetails.currentPage);
        setIsBookmarked(bookmarkPage === juzzDetails.currentPage);
        setLoading(false);
      })
      .catch((error) => alert("Something went wrong"));
  };

  const loadNextPage = () => {
    setOpenChangeStatus(false);
    setOpenLanguage(false);
    readJuzzByPage(currentPage + 1);
  };

  const loadPrevPage = () => {
    setOpenChangeStatus(false);
    setOpenLanguage(false);
    readJuzzByPage(currentPage - 1);
  };

  const toggleBookmark = () => {
    if (!isBookmarked) {
      bookmarkPageNumber(currentPage);
    } else {
      cancelBookmark();
    }
  };

  return {
    loading,
    juzzPageDetails,
    readJuzzByPage,
    juzzNumber,
    currentPage,
    language,
    loadNextPage,
    loadPrevPage,
    toggleBookmark,
    isBookmarked,
    onClickActionItem,
    openLanguage,
    openChangeStatus,
    setLanguage,
    unClaimJuzz,
    markAsComplete,
    isCompleted,
    sponsorMsg: khitmah?.sponsorMsg,
  };
};

const [ReadJuzProvider, ReadJuzContext] = constate(ReadJuzHook);

export { ReadJuzContext };

export default withModal(ReadJuzProvider);
