import {Entity} from './entity';

export enum NotificationType {
  FUND_RAISED_COMPLETED = 'FUND_RAISED_COMPLETED',
  DONATION_PLEDGE = 'DONATION_PLEDGE',
  DONATION_PLEDGE_THANKS_NOTE = 'DONATION_PLEDGE_THANKS_NOTE',
  KHITMAH_PLEDGE_CLAIMED = 'KHITMAH_PLEDGE_CLAIMED',
  KHITMAH_PLEDGE_COMPLETED = 'KHITMAH_PLEDGE_COMPLETED',
  KHITMAH_PLEDGE_LEFT = 'KHITMAH_PLEDGE_LEFT',
  KHITMAH_PLEDGE_REMINDER = 'KHITMAH_PLEDGE_REMINDER',
  KHITMAH_REMOVED = 'KHITMAH_REMOVED',
  KHITMAH_COMPLETED = 'KHITMAH_COMPLETED',
  WELCOME_USER = 'WELCOME_USER',
  KHITMAH_CREATED = 'KHITMAH_CREATED',
  KHITMAH_WITH_FUNDRAISER_CREATED = 'KHITMAH_WITH_FUNDRAISER_CREATED',
  KHITMAH_SPONSOR_MESSAGE_NOTIFY = 'KHITMAH_SPONSOR_MESSAGE_NOTIFY',
}

export interface NotificationData {
  type: NotificationType;
  khitmahId?: string;
  khitmahPledgeId?: string;
  userId?: string;
}

export interface Notification extends Entity {
  data: NotificationData;
  isRead: boolean;
  msg: string;
  title: string;
}

export const documentSnapshotToNotification = (snap: any) => {
  const notification = snap.data() as Notification;
  if (snap.id === null) {
    throw new Error('notification snapshot document id not found');
  }
  notification.id = snap.id;

  return notification;
};
