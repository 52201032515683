import React from "react";
import { Field } from "formik";
import Select, { createFilter } from "react-select";
import { FixedSizeList as List } from "react-window";
import { useTranslation } from "react-i18next";

const height = 35;

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const AutoCompleteSearch = (props) => {
  const { t } = useTranslation();

  const {
    name,
    label,
    options,
    value,
    handlePickerChange,
    disabled,
    placeholder,
    errors,
    touched,
  } = props;

  const values =
    options && options?.length > 0
      ? options.map((item) => ({ value: item, label: item }))
      : [];
  const selected = values?.find((val) => val.value === value);

  return (
    <div className="field-group  pointer">
      <label htmlFor={name}>{label}</label>
      <Field
        name={name}
        value={value}
        component={() => {
          return (
            <Select
              options={values}
              isDisabled={disabled}
              isClearable={false}
              value={selected}
              placeholder={placeholder}
              onChange={(event) => {
                handlePickerChange(event.value);
              }}
              filterOption={createFilter({ ignoreAccents: false })}
              components={{ MenuList }}
            />
          );
        }}
      />
      {errors[name] && touched[name] && (
        <div className="invalid-feedback d-block">{t(errors[name])}</div>
      )}
    </div>
  );
};

export default React.memo(AutoCompleteSearch);
