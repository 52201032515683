import React, { useContext } from "react";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarFooter,
} from "@coreui/react";

import { cilAccountLogout } from "@coreui/icons";
import ic_logout from "./../Assets/Images/ic_logout.svg";
import "./style.scss";
import AssetsConstants from "../Constants/AssetsConstants";

// sidebar nav config
import navigation from "./nav";
import IconButton from "../Components/IconButton";
import { useStyles } from "react-styles-hook";
import { logoutUser } from "@khitmah/shared/src/Services/User/Authentication/logoutUser";
import { AppRoutes } from "../Constants/RouteConstants";
import { AppContext } from "../Context/AppContext";
import { setLoggedInUser } from "../Context/Action";
import { useTranslation } from "react-i18next";

const Sidebar = ({ minimize, history, showModalWithYesAndNo }) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();

  const logout = () => {
    showModalWithYesAndNo(
      t("common.logOut"),
      t("common.logoutConfirmation"),
      async () => {
        dispatch(setLoggedInUser({}));
        await logoutUser();
        history.replace(AppRoutes.LOGIN);
      }
    );
  };

  return (
    <CSidebar className="left-nav-panel" show={minimize}>
      <div className="dashboard-logo-box">
        <div className="logo">
          <img src={AssetsConstants.dashboardLogo} alt="" />
        </div>
      </div>
      <CSidebarNav>
        {navigation.map((item) => (
          <CSidebarNavItem
            activeclassName="active"
            name={t(item.name)}
            icon={item.icon}
            to={item.to}
          />
        ))}
      </CSidebarNav>
      <CSidebarFooter>
        <div className="logout-btn">
          <a className="link" onClick={logout}>
            {" "}
            <i className="icon-logout"></i>
            {t("common.logOut")}
          </a>
        </div>
      </CSidebarFooter>
    </CSidebar>
  );
};

export default Sidebar;
