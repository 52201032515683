import React, { useReducer } from "react";
import firebase from "firebase";
// import "firebase/messaging";
import { createBrowserHistory } from "history";
import App from "./App";
import { AppContext } from "./Context/AppContext";
import { initialAppState, appReducer } from "./Context/Reducer";
import { Detector } from "react-detect-offline";

const history = createBrowserHistory();

// firebase config
const config = getConfigurations();

firebase.initializeApp(config);
// let messaging;
// if (firebase.messaging.isSupported()){
//   messaging = firebase.messaging();
// }

// Request Permissions

export const requestNotificationPermission = () =>
  new Promise((resolve, reject) => {
    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        try {
          // const firebaseToken = await messaging.getToken();
          resolve();
        } catch (e) {
          reject(e);
        }
      }
    });
  });

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

function InitializeApp() {
  let [state, dispatch] = useReducer(appReducer, initialAppState);
  let store = { state, dispatch };
  return (
    // <Detector
    //   render={({ online }) =>
    //     online ? (
    //       <AppContext.Provider value={store}>
    //         <App history={history} />
    //       </AppContext.Provider>
    //     ) : (
    //       <h1> No internet connection</h1>
    //     )
    //   }
    // />

    <AppContext.Provider value={store}>
      <App history={history} />
    </AppContext.Provider>
  );
}

export default InitializeApp;

export const AppConfig = () => process.env;

function getConfigurations() {
  return {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
}
