import React from "react";
import { Field } from "formik";
import ButtonGroupField from "../ButtonGroup";

const ButtonGroup = (props) => {
  const { name } = props;
  return (
    <>
      <Field name={name} component={ButtonGroupField} {...props} />
    </>
  );
};
export default ButtonGroup;
