import {
  fetchKhitmahReferences,
  Status,
} from "@khitmah/shared/src/Models/khitmah";
import { getKhitmahsOfUser } from "@khitmah/shared/src/Services/Khitmah/getAllKhitmahs";
import { useContext, useEffect, useState } from "react";
import { AppRoutes } from "../Constants/RouteConstants";
import { AppContext } from "../Context/AppContext";
import { KhitmahListModel } from "../Helper/KhitmahList";
import { useHistory } from "react-router-dom";
import { convertKhitmahtoImplicitTypes } from "../Helper/Common";

function useMyKhitmahs() {
  const {
    state: { loggedInUser, fetchingUserDetails },
  } = useContext(AppContext);

  const [isMyKhitmahsLoading, setIsMyKhitmahsLoading] = useState(
    fetchingUserDetails
  );
  const [myKhitmahs, setMyKhitmahs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!!loggedInUser.id) {
      setIsMyKhitmahsLoading(true);

      getKhitmahsOfUser(loggedInUser.id).onSnapshot(
        (snapshot) => {
          const data = snapshot.docs.map(
            (doc) => doc.exists && { ...doc.data(), id: doc.id }
          );

          setMyKhitmahs(KhitmahListModel(data).khitmahs);
          setIsMyKhitmahsLoading(false);
        },
        (error) => {
          setIsMyKhitmahsLoading(false);

          console.log("Error in fetching my khitmahs", error);
        }
      );
    }
  }, [loggedInUser]);

  const moveToDetails = async (item) => {
    if (item.status !== Status.REMOVED)
      history.push(AppRoutes.KHITMAH_DETAILS, {
        khitmahId: item.id,
      });
  };

  return {
    isMyKhitmahsLoading,
    myKhitmahs,
    moveToDetails,
    aciveKhitmahs: myKhitmahs.filter(
      (khitmah) => khitmah.status === Status.CREATED
    ),
  };
}

export default useMyKhitmahs;
