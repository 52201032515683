import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const NumericField = (props) => {
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    hideLabel,
    min,
    max,
    maxlength,
    onChange,
  } = props;
  let validatyClass = "";
  if (!!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-invalid";
  }
  if (!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-valid";
  }

  const { t } = useTranslation();

  return (
    <>
      {!hideLabel && <label htmlFor={name}>{label}</label>}
      <input
        {...field}
        id={name}
        type={"number"}
        min={min}
        max={max}
        onChange={onChange}
        className={`form-control ${validatyClass}`}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback">{t(errors[field.name])}</div>
      )}
    </>
  );
};

function FormInput(props) {
  const { name, onChange } = props;
  return (
    <>
      <div className="field-group">
        <Field
          name={name}
          component={NumericField}
          onChange={onChange}
          {...props}
        />
      </div>
    </>
  );
}
export default FormInput;
