import React from "react";
import { Field } from "formik";
import TextField from "../TextField";

function FormInput(props) {
  const { name } = props;
  return (
    <>
      <div className="field-group">
        <Field name={name} component={TextField} {...props} />
      </div>
    </>
  );
}
export default FormInput;
