import {documentSnapshotToMessage} from '../../../Models/message';
import {MESSAGES} from '../../../Constants/database';
import firestore from '../../../Firebase/firestore';

const NotificationMessages: any = {
  WELCOME_USER: 'welcome_user_message',
  KHITMAH_CREATED: 'khitmah_created',
  KHITMAH_WITH_FUNDRAISER_CREATED: 'khitmah_fundraiser_created',
};

export const getMessageByType = async (type: string) => {
  const messageDocId: string = NotificationMessages[type];
  const messageDoc = firestore().collection(MESSAGES).doc(messageDocId);
  const doc = await messageDoc.get();
  if (!doc.exists) {
    throw new Error(
      'The message for the provided notification type does not exist.',
    );
  }
  return documentSnapshotToMessage(doc);
};
