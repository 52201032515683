import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";

const PhoneField = (props) => {
  const { field, errors = {}, name, touched = {}, label, onChange } = props;
  let validatyClass = "";
  if (!!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-invalid";
  }
  if (!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-valid";
  }

  const onValueChange = (phoneNumber) => {
    console.log("value change called", phoneNumber);

    if (onChange !== null) {
      onChange(phoneNumber);
    }
  };

  const country = "us";

  return (
    <>
      <PhoneInput
        country={country}
        placeholder={field.placeholder}
        value={field.value}
        onChange={(phone, c, e, fv) => {
          onValueChange(phone);
        }}
      />

      {/* <div className="invalid-feedback">Error</div> */}
    </>
  );
};

function FormInput(props) {
  const { name } = props;
  return (
    <>
      <div className="field-group">
        <Field name={name} component={PhoneField} {...props} />
      </div>
    </>
  );
}
export default FormInput;
