import React from "react";
import AssetsConstants from "../../Constants/AssetsConstants";

export default [
  {
    label: "common.english",
    code: "en",
    icon: <img src={AssetsConstants.countryEnglish} alt="" />,
  },
  {
    label: "common.arabic",
    code: "ar",
    icon: <img src={AssetsConstants.countryArabic} alt="" />,
  },
  {
    label: "common.french",
    code: "fr",
    icon: <img src={AssetsConstants.countryFrench} alt="" />,
  },
  // {
  //   label: "common.spanish",
  //   code: "es",
  //   icon: <img src={AssetsConstants.countrySpanish} alt="" />,
  // },
  {
    label: "common.urdu",
    code: "ur",
    icon: <img src={AssetsConstants.countryUrdu} alt="" />,
  },
  // {
  //   label: "common.swedish",
  //   code: "sv",
  //   icon: <img src={AssetsConstants.countrySwedish} alt="" />,
  // },
  // {
  //   label: "common.chinese",
  //   code: "zh",
  //   icon: <img src={AssetsConstants.countryChinese} alt="" />,
  // },
  {
    label: "common.german",
    code: "de",
    icon: <img src={AssetsConstants.countryGerman} alt="" />,
  },
  // {
  //   label: "common.indonesian",
  //   code: "id",
  //   icon: <img src={AssetsConstants.countryIndonesian} alt="" />,
  // },
  // {
  //   label: "common.malay",
  //   code: "ms",
  //   icon: <img src={AssetsConstants.countryMalay} alt="" />,
  // },
  {
    label: "common.turkish",
    code: "tr",
    icon: <img src={AssetsConstants.countryTurkish} alt="" />,
  },
  {
    label: "common.farsi",
    code: "fa",
    icon: <img src={AssetsConstants.countryFarsi} alt="" />,
  },
  {
    label: "common.italian",
    code: "it",
    icon: <img src={AssetsConstants.countryItalian} alt="" />,
  },
  // {
  //   label: "common.russian",
  //   code: "ru",
  //   icon: <img src={AssetsConstants.countryRussian} alt="" />,
  // },
];
