import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const isNumber = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const SimplePicker = ({
  disabled,
  onChange,
  placeholder,
  items,
  value,
  hideDropdownToggle,
  className,
}) => {
  const { t } = useTranslation();

  const selected = items?.filter((item) => {
    return typeof item === "string" ? item == value : item.value == value;
  })[0];

  return (
    <Dropdown
      className={`form-control-select ${disabled && "disabled"} ${className}`}
      onSelect={(eventKey) => {
        if (typeof onChange === "function") {
          if (isNumber(eventKey)) {
            onChange(Number(eventKey));
          } else onChange(eventKey);
        }
      }}
    >
      {!hideDropdownToggle && (
        <Dropdown.Toggle variant="secondary" className="text-left">
          {selected ? (
            <div>
              {typeof selected === "string"
                ? selected
                : t(selected.label) || selected.label}
            </div>
          ) : (
            <div>{t(placeholder) || placeholder}</div>
          )}
        </Dropdown.Toggle>
      )}

      <Dropdown.Menu>
        {items?.map((item, index) => {
          return typeof item === "string" ? (
            <Dropdown.Item
              active={item == value}
              key={item}
              eventKey={item.toString()}
            >
              {item}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              disabled={item.disable}
              key={item.value}
              active={item.value == value}
              eventKey={item.value.toString()}
            >
              {t(item.label) || item.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SimplePicker;
