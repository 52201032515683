import React from "react";

function Button(props) {
  const { className, disabled, loading, btnText, type } = props;
  return (
    <button
      {...props}
      className={`${className} ${disabled && "btn-disabled"} ${
        loading && "btn-disabled btn-loading"
      }`}
      type={type}
      disabled={disabled}
    >
      {btnText}
    </button>
  );
}

export default Button;
