import { createUser } from "@khitmah/shared/src/Services/User/createUser";

import { auth } from "firebase";
import { getErrorMessageFromFirestoreError } from "@khitmah/shared/src/Utils/firestoreErrorMap";
import { getBrowserDefaultLanguage } from "../../Helper/Common";
import { setMixpanelUser } from "../Mixpanel";
import { trackUserSignedup } from "../Mixpanel/trackEvents";

export const facebookLoginUser = async (response) => {
  try {
    // Create a Facebook credential with the token
    const facebookCredential = auth.FacebookAuthProvider.credential(
      response.credential.accessToken
    );

    //console.log("facebook credentials", facebookCredential);

    // Sign-in the user with the credential
    const credential = await auth().signInWithCredential(facebookCredential);
    const additionalUserInfo = credential.additionalUserInfo;
    //console.log("facebook" + JSON.stringify(credential));

    const userProvidedData = credential.user.providerData[0];
    if (!userProvidedData.email) {
      throw new Error(
        getErrorMessageFromFirestoreError({
          message:
            "Unable to find your email. Please login to Facebook with your valid email address.",
        })
      );
    }

    if (!credential.user.emailVerified) {
      credential.user.sendEmailVerification().then(() => {
        const fullName = userProvidedData.displayName
          ? userProvidedData.displayName
          : "";
        const email = userProvidedData.email ? userProvidedData.email : "";
        const profileImageUrl = userProvidedData.photoURL
          ? userProvidedData.photoURL
          : "";

        if (additionalUserInfo?.isNewUser) {
          setMixpanelUser(credential.user.uid);
          trackUserSignedup(userProvidedData.country, userProvidedData.city);
        }

        createUser(
          fullName,
          email,
          profileImageUrl,
          credential.user.uid,
          getBrowserDefaultLanguage(),
          additionalUserInfo?.isNewUser
        );
      });
    }
    return credential.user;

    // getUserByEmail(email)
    //   .get()
    //   .then(async (snapshot) => {
    //     let firstTimeUser = false;
    //     if (snapshot.docs.length) {
    //       snapshot.docs.map(async (doc) => {
    //         if (doc.exists) {
    //           await createUser(
    //             fullName,
    //             email,
    //             profileImageUrl,
    //             credential.user.uid,
    //             "",
    //             "",
    //             "",
    //             firstTimeUser
    //           );
    //         }
    //       });
    //     } else {
    //       firstTimeUser = true;
    //       await createUser(
    //         fullName,
    //         email,
    //         profileImageUrl,
    //         credential.user.uid,
    //         "",
    //         "",
    //         "",
    //         firstTimeUser
    //       );
    //     }
    //   });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
