import {
  KhitmahFundRaiserInfoCauseType,
  KhitmahMemorialInfoLocationType,
  KhitmahPrivacyType,
  KhitmahType,
} from '../Models/khitmah';
import {Item} from '../Utils/types';
import {QURAN_TRANSLATION, LANGUAGES} from './appConstants';

export const AGE: Item[] = [
  {label: 'number.0', value: 0},
  {label: 'number.1', value: 1},
  {label: 'number.2', value: 2},
  {label: 'number.3', value: 3},
  {label: 'number.4', value: 4},
  {label: 'number.5', value: 5},
  {label: 'number.6', value: 6},
  {label: 'number.7', value: 7},
  {label: 'number.8', value: 8},
  {label: 'number.9', value: 9},
  {label: 'number.10', value: 10},
  {label: 'number.11', value: 11},
  {label: 'number.12', value: 12},
  {label: 'number.13', value: 13},
  {label: 'number.14', value: 14},
  {label: 'number.15', value: 15},
  {label: 'number.16', value: 16},
  {label: 'number.17', value: 17},
  {label: 'number.18', value: 18},
  {label: 'number.19', value: 19},
  {label: 'number.20', value: 20},
  {label: 'number.21', value: 21},
  {label: 'number.22', value: 22},
  {label: 'number.23', value: 23},
  {label: 'number.24', value: 24},
  {label: 'number.25', value: 25},
  {label: 'number.26', value: 26},
  {label: 'number.27', value: 27},
  {label: 'number.28', value: 28},
  {label: 'number.29', value: 29},
  {label: 'number.30', value: 30},
  {label: 'number.31', value: 31},
  {label: 'number.32', value: 32},
  {label: 'number.33', value: 33},
  {label: 'number.34', value: 34},
  {label: 'number.35', value: 35},
  {label: 'number.36', value: 36},
  {label: 'number.37', value: 37},
  {label: 'number.38', value: 38},
  {label: 'number.39', value: 39},
  {label: 'number.40', value: 40},
  {label: 'number.41', value: 41},
  {label: 'number.42', value: 42},
  {label: 'number.43', value: 43},
  {label: 'number.44', value: 44},
  {label: 'number.45', value: 45},
  {label: 'number.46', value: 46},
  {label: 'number.47', value: 47},
  {label: 'number.48', value: 48},
  {label: 'number.49', value: 49},
  {label: 'number.50', value: 50},
  {label: 'number.51', value: 51},
  {label: 'number.52', value: 52},
  {label: 'number.53', value: 53},
  {label: 'number.54', value: 54},
  {label: 'number.55', value: 55},
  {label: 'number.56', value: 56},
  {label: 'number.57', value: 57},
  {label: 'number.58', value: 58},
  {label: 'number.59', value: 59},
  {label: 'number.60', value: 60},
  {label: 'number.61', value: 61},
  {label: 'number.62', value: 62},
  {label: 'number.63', value: 63},
  {label: 'number.64', value: 64},
  {label: 'number.65', value: 65},
  {label: 'number.66', value: 66},
  {label: 'number.67', value: 67},
  {label: 'number.68', value: 68},
  {label: 'number.69', value: 69},
  {label: 'number.70', value: 70},
  {label: 'number.71', value: 71},
  {label: 'number.72', value: 72},
  {label: 'number.73', value: 73},
  {label: 'number.74', value: 74},
  {label: 'number.75', value: 75},
  {label: 'number.76', value: 76},
  {label: 'number.77', value: 77},
  {label: 'number.78', value: 78},
  {label: 'number.79', value: 79},
  {label: 'number.80', value: 80},
  {label: 'number.81', value: 81},
  {label: 'number.82', value: 82},
  {label: 'number.83', value: 83},
  {label: 'number.84', value: 84},
  {label: 'number.85', value: 85},
  {label: 'number.86', value: 86},
  {label: 'number.87', value: 87},
  {label: 'number.88', value: 88},
  {label: 'number.89', value: 89},
  {label: 'number.90', value: 90},
  {label: 'number.91', value: 91},
  {label: 'number.92', value: 92},
  {label: 'number.93', value: 93},
  {label: 'number.94', value: 94},
  {label: 'number.95', value: 95},
  {label: 'number.96', value: 96},
  {label: 'number.97', value: 97},
  {label: 'number.98', value: 98},
  {label: 'number.99', value: 99},
  {label: 'number.100', value: 100},
  {label: 'number.101', value: 101},
  {label: 'number.102', value: 102},
  {label: 'number.103', value: 103},
  {label: 'number.104', value: 104},
  {label: 'number.105', value: 105},
  {label: 'number.106', value: 106},
  {label: 'number.107', value: 107},
  {label: 'number.108', value: 108},
  {label: 'number.109', value: 109},
  {label: 'number.110', value: 110},
  {label: 'number.111', value: 111},
  {label: 'number.112', value: 112},
  {label: 'number.113', value: 113},
  {label: 'number.114', value: 114},
  {label: 'number.115', value: 115},
  {label: 'number.116', value: 116},
  {label: 'number.117', value: 117},
  {label: 'number.118', value: 118},
  {label: 'number.119', value: 119},
  {label: 'number.120', value: 120},
];

export const LOCATION_TYPE: Item[] = [
  {label: 'khitmah.home', value: KhitmahMemorialInfoLocationType.HOME},
  {label: 'khitmah.masjid', value: KhitmahMemorialInfoLocationType.MASJID},
  {
    label: 'khitmah.publicArea',
    value: KhitmahMemorialInfoLocationType.PUBLIC_AREA,
  },
];

export const KHITMAH_TYPE: Item[] = [
  {label: 'khitmah.forTheSick', value: KhitmahType.SICK},
  {label: 'khitmah.forTheDeceased', value: KhitmahType.DECEASED},
  {label: 'khitmah.forTheMissing', value: KhitmahType.MISSING},
];

export const KHITMAH_PRIVACY_TYPE: Item[] = [
  {label: 'khitmah.public', value: KhitmahPrivacyType.PUBLIC},
  {label: 'khitmah.private', value: KhitmahPrivacyType.PRIVATE},
];

export const KHITMAH_SORT_TYPE: Item[] = [
  {label: 'khitmah.relevance', value: 'relevance'},
  {label: 'khitmah.newToOld', value: 'newToOld'},
  {label: 'khitmah.oldToNew', value: 'oldToNew'},
  {label: 'khitmah.noOfParticipants', value: 'noOfParticipants'},
];

export const KHITMAH_FILTER_TYPE: Item[] = [
  {label: 'khitmah.khitmahsWithDonations', value: 'khitmahWithDonations'},
  {label: 'khitmah.khitmahsOnly', value: 'khitmahOnly'},
];

export const FUNDRAISER_TYPE: Item[] = [
  {
    label: 'khitmah.funeralArrangements',
    value: KhitmahFundRaiserInfoCauseType.FUNERAL_ARRANGEMENTS,
  },
  {
    label: 'khitmah.treatmentCost',
    value: KhitmahFundRaiserInfoCauseType.TREATMENT_COST_FOR_SICK,
  },
  {
    label: 'khitmah.supportForDeceasedKin',
    value: KhitmahFundRaiserInfoCauseType.SUPPORT_FOR_DECEASED_KIN,
  },
];

export const QURAN_LANGUAGES: Item[] = [
  {label: 'common.noTranslation', value: 0, key: QURAN_TRANSLATION.ENGLISH},
  {label: 'common.english', value: 1, key: QURAN_TRANSLATION.ENGLISH},
  {label: 'common.arabic', value: 2, key: QURAN_TRANSLATION.ARABIC},
  {label: 'common.farsi', value: 3, key: QURAN_TRANSLATION.FARSI},
];

export const LANGUAGE_LIST: Item[] = [
  {label: 'common.arabic', value: 1, key: LANGUAGES.ARABIC},
  // {label: 'common.chinese', value: 2, key: LANGUAGES.CHINESE},
  {label: 'common.english', value: 3, key: LANGUAGES.ENGLISH},
  {label: 'common.farsi', value: 4, key: LANGUAGES.FARSI},
  {label: 'common.french', value: 5, key: LANGUAGES.FRENCH},
  {label: 'common.german', value: 6, key: LANGUAGES.GERMAN},
  // {label: 'common.indonesian', value: 7, key: LANGUAGES.INDONESIAN},
  {label: 'common.italian', value: 8, key: LANGUAGES.ITALIAN},
  // {label: 'common.malay', value: 9, key: LANGUAGES.MALAY},
  // {label: 'common.portuguese', value: 10, key: LANGUAGES.PORTUGUESE},
  // {label: 'common.russian', value: 11, key: LANGUAGES.RUSSIAN},
  // {label: 'common.spanish', value: 12, key: LANGUAGES.SPANISH},
  // {label: 'common.swedish', value: 13, key: LANGUAGES.SWEDISH},
  {label: 'common.turkish', value: 14, key: LANGUAGES.TURKISH},
  {label: 'common.urdu', value: 15, key: LANGUAGES.URDU},
];
