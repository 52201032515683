import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// components
import MyKhitmahsCard from "../../Components/Dashboard/MyKhitmahsCard/";
import { withHeader } from "../../Components/Header";
import MultiCarousel from "../../Components/MultiCarousel";
import MyPledgesCard from "../../Components/Dashboard/MyPledgesCard";
import PublicKhitmahsCard from "../../Components/Dashboard/PublicKhitmahsCard";
import Loader from "../../Components/Common/Loader";

// custom hooks
import { useMyActivePledges } from "../../Hooks/useMyPledges";
import usePublicKhitmahs from "../../Hooks/usePublicKhitmahs";
import useMyKhitmahs from "../../Hooks/useMyKhitmahs";

import { AppRoutes } from "../../Constants/RouteConstants";
import "./style.scss";
import KWHModal from "../../Components/Common/Modal";
import { updatePledgeStatus } from "@khitmah/shared/src/Services/KhitmahPledge/updatePledge";
import { Status } from "@khitmah/shared/src/Models/khitmahPledge";
import OnBoardingTutorial from "../../Components/OnBoardingTutorial";
import { AppContext } from "../../Context/AppContext";
import { getUser } from "@khitmah/shared/src/Services/User/getUser";
import { updateUserOnBoardingFlag } from "@khitmah/shared/src/Services/User/updateUser";

function Dashboard(props) {
  const { history, location } = props;
  const { t } = useTranslation();
  const { isMyKhitmahsLoading, myKhitmahs, aciveKhitmahs } = useMyKhitmahs();
  const {
    activePledgesLoading,
    activePledges,
    removePledgeFromList,
  } = useMyActivePledges();
  const {
    publicKhitmahs,
    isPublicKhitmahsLoading,
    moveToDetails,
  } = usePublicKhitmahs();

  const {
    state: { loggedInUser },
  } = useContext(AppContext);

  const moveToReadQuran = (item) => {
    history.push(AppRoutes.READ_JUZZ, {
      id: item.id,
    });
  };

  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedPledge, setSelectedPledge] = useState({});
  const [isOnBoardingVisisble, setIsOnboardingVisible] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has("khitmahId")) {
      history.push(AppRoutes.KHITMAH_DETAILS, {
        khitmahId: params.get("khitmahId"),
      });
    }

    if (loggedInUser?.id) {
      getUser(loggedInUser.id)
        .then((userDetails) => {
          if (!userDetails.onBoardingViewed) {
            setIsOnboardingVisible(true);
            updateUserOnBoardingFlag(true, loggedInUser.id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    return () => {
      setVisibleModal(false);
      setSelectedPledge({});
    };
  }, [loggedInUser]);

  const navigateToMyKhitmahs = () => {
    history.push(AppRoutes.MY_KHITMAHS);
  };

  const navigateToMyPledges = () => {
    history.push(AppRoutes.MY_PLEDGES);
  };

  const navigateToJoinKhitmahs = () => {
    history.push(AppRoutes.JOIN_A_KHITMAH);
  };

  const closeModal = () => {
    setVisibleModal(false);
    setSelectedPledge({});
  };

  const openModal = (item) => {
    setVisibleModal(true);
    setSelectedPledge(item);
  };

  const markPledgeAsComplete = async (pledge) => {
    try {
      await updatePledgeStatus(Status.COMPLETED, pledge.id);
      removePledgeFromList(pledge);
      setVisibleModal(false);
    } catch (e) {
      setVisibleModal(false);
      console.log("error", e);
    }
  };

  const myKhitmahsView = aciveKhitmahs.map((khitmah, index) => (
    <MyKhitmahsCard
      item={khitmah}
      key={index}
      onClick={() => moveToDetails(khitmah)}
    />
  ));

  const myPledgesView = activePledges.map((pledge, index) => (
    <MyPledgesCard
      item={pledge}
      key={index}
      openConfirmationModal={openModal}
      onClick={() => moveToReadQuran(pledge)}
    />
  ));

  const publicKhitmahsView = publicKhitmahs.map((khitmah, index) => (
    <PublicKhitmahsCard
      item={khitmah}
      key={index}
      onClick={() => moveToDetails(khitmah)}
    />
  ));

  // Show onboarding screens to first time user
  if (isOnBoardingVisisble)
    return (
      <OnBoardingTutorial
        closeOnBoarding={() => setIsOnboardingVisible(false)}
      />
    );

  return (
    <>
      <div className="main-dashboard-screen">
        <Loader
          showLoader={
            isMyKhitmahsLoading ||
            activePledgesLoading ||
            isPublicKhitmahsLoading
          }
        />

        <div className="dashboard-carousel-box">
          <KWHModal
            visible={visibleModal}
            modalTitle={t("pledges.markAsComplete")}
            modalBody={t("pledges.confirmMarkComplete")}
            onClose={closeModal}
            onOkClick={() => markPledgeAsComplete(selectedPledge)}
            btnSaveText={t("common.ok")}
          />

          <div className="head-box">
            <h4 className="title">{t("khitmah.myKhitmahs")}</h4>
            <button onClick={navigateToMyKhitmahs} className="view-all-btn">
              {t("common.viewAll")}
            </button>
          </div>
          {myKhitmahs.length > 0 ? (
            <MultiCarousel content={myKhitmahsView} />
          ) : (
            <div className="no-record-found">
              {isMyKhitmahsLoading
                ? t("common.loading")
                : t("common.noRecordFound")}
            </div>
          )}
        </div>

        <div className="dashboard-carousel-box">
          <div className="head-box">
            <h4 className="title">{t("khitmah.myPledges")}</h4>
            <button onClick={navigateToMyPledges} className="view-all-btn">
              {t("common.viewAll")}
            </button>
          </div>
          {activePledges.length > 0 ? (
            <MultiCarousel content={myPledgesView} />
          ) : (
            <div className="no-record-found">
              {activePledgesLoading
                ? t("common.loading")
                : t("common.noRecordFound")}
            </div>
          )}
        </div>

        <div className="dashboard-carousel-box">
          <div className="head-box">
            <h4 className="title">{t("khitmah.suggestedKhitmahs")}</h4>
            <button onClick={navigateToJoinKhitmahs} className="view-all-btn">
              {t("common.viewAll")}
            </button>
          </div>

          {publicKhitmahs.length > 0 ? (
            <MultiCarousel content={publicKhitmahsView} />
          ) : (
            <div className="no-record-found">
              {isPublicKhitmahsLoading
                ? t("common.loading")
                : t("common.noRecordFound")}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withHeader(Dashboard);
