import {TransactionMode} from './donationPledge';
import {Entity} from './entity';

export interface PayItForwardDonation extends Entity {
  payerId: string;
  payer: any;
  amount: number;
  khitmahIds: number[];
  transaction: any;
  transactionId: string;
  transactionMode: TransactionMode;
  sponsorKhitmaCount: number;
  remainingCount: number;
  sponsorMsg: string;
  isCompleted: boolean;
}

export const documentSnapshotToPayAsForward = (snap: any) => {
  const payAsForward = snap.data() as PayItForwardDonation;
  if (snap.id === null) {
    throw new Error('donation pledge snapshot document id not found');
  }
  payAsForward.id = snap.id;

  return payAsForward;
};
