import auth from '../../../Firebase/auth';
import { getErrorMessageFromFirestoreError } from '../../../Utils/firestoreErrorMap';

export const forgotPassword = async (email: string) => {
  try {
    await auth().sendPasswordResetEmail(email);
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
