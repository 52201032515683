import {documentSnapshotToUser} from '../../Models/user';
import {USERS} from '../../Constants/database';
import {getErrorMessageFromFirestoreError} from '../../Utils/firestoreErrorMap';
import firestore from '../../Firebase/firestore';

export const getUser = async (id: string) => {
  try {
    const users = firestore().collection(USERS).doc(id);
    const doc = await users.get();
    if (!doc.exists) {
      throw new Error('This user does not exist.');
    }
    return documentSnapshotToUser(doc);
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const getUserByEmail = async (email: string) => {
  try {
    return firestore().collection(USERS).where('email', '==', email);
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
