import React from "react";
import { useTranslation } from "react-i18next";

function FundRaiserStats(props) {
  const { amountRaised = 0, amountGoal = 0 } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="label-title">
        <span className="amount">${amountRaised}</span> {t("khitmah.raised")}
      </div>
      <div className="label-title">
        {t("khitmah.goal")} <span className="amount">${amountGoal}</span>{" "}
      </div>
    </>
  );
}

export default FundRaiserStats;
