import React, { useState } from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { withHeader } from "../../Components/Header";
import KhitmahDonations from "./myDonations";
import PayItForwardHistory from "./payItForward";

function MyDonations(props) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("khitmahDonations");

  return (
    <>
      <div className="generic-inner-sec">
        <CTabs
          activeTab={activeTab}
          onActiveTabChange={(tab) => {
            setActiveTab(tab);
          }}
        >
          <div className="d-flex justify-content-between">
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink data-tab="khitmahDonations">
                  {t("donation.donations")}
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="payItForward">
                  {t("donation.sponsoredDonations")}
                </CNavLink>
              </CNavItem>
            </CNav>
          </div>
          <CTabContent>
            <CTabPane data-tab="khitmahDonations">
              <div className="my-pledge-listing">
                <KhitmahDonations />
              </div>
            </CTabPane>
            <CTabPane data-tab="payItForward">
              <div className="my-pledge-listing">
                <PayItForwardHistory />
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </div>
    </>
  );
}

export default withHeader(MyDonations);
