export const SIGN_IN = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const SIGN_UP = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  FULL_NAME: 'fullName',
  CITY: 'city',
  COUNTRY: 'country',
  AGE: 'age',
  TOC: 'tocAccepted',
};

export const PROFILE = {...SIGN_UP, PROFILE_IMAGE_URL: 'profileImageUrl'};

export const FORGOT_PASSWORD = {
  EMAIL: 'email',
};

export const UPDATE_PASSWORD = {
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
};

export const CREATE_KHITMAH = {
  FRIEND_NAME: 'friendName',
  DESCRIPTION: 'description',
  KHITMAH_TYPE: 'khitmahType',
  DATE_OF_PASSING: 'dateOfPassing',
  AGE_OF_DECEASED: 'ageOfDeceased',
  MEMORIAL_LOCATION: 'memorialLocation',
  MEMORIAL_LOCATION_TYPE: 'memorialLocationType',
  MEMORIAL_DATE: 'memorialDate',
  MEMORIAL_TIME: 'memorialTime',
  MEMORIAL_END_TIME: 'memorialEndTime',
  KHITMAH_PRIVACY: 'khitmahPrivacy',
  FRIEND_PROFILE_IMAGE: 'friendImageUrl',
  THANK_YOU_MESSAGE: 'thankYouMessage',
  AMOUNT: 'amount',
  PAYMENT_ACCOUNT_NUMBER: 'paymentAccountNumber',
  CAUSE_OF_FUNDRAISER: 'causeOfFundRaiser',
  PHONE_NUMBER: 'phoneNumber',
  THANK_YOU_MESSAGE_FUNDRAISER: 'thankYouMessageFundRaiser',
};
