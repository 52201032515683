import firestore from '../../Firebase/firestore';
import {USERS} from '../../Constants/database';
import {getErrorMessageFromFirestoreError} from '../../Utils/firestoreErrorMap';
import {generateUserModelObj} from '../../Utils/Mapper/dataToModel';
import {generateUserWelcomeNotification} from './Notifications/createNotification';
import {setMixpanelUser} from '../Mixpanel';
import {trackUserSignedup} from '../Mixpanel/trackEvents';
import {LANGUAGES} from '../../Constants/appConstants';

export const createUser = async (
  fullName: string,
  email: string,
  profileImageUrl: string,
  userId: string,
  language: string = LANGUAGES.ENGLISH,
  isNewUser?: boolean,
  city?: string,
  country?: string,
  age?: string,
) => {
  const user = generateUserModelObj(
    fullName,
    email,
    profileImageUrl,
    language,
    city,
    country,
    age,
  );

  try {
    //had to check if user exist before creating one so that mixpanel dont track multiple events for social login
    if (isNewUser) {
      //track mixpanel event
      setMixpanelUser(userId);
      trackUserSignedup(country, city);
    }

    await firestore()
      .collection(USERS)
      .doc(userId)
      .set(
        {
          ...user,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
        },
        {merge: true},
      );

    await generateUserWelcomeNotification(userId, language, fullName);
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
