import { getKhitmahShareUrl } from "@khitmah/shared/src/Services/Khitmah/getKhitmahShareUrl";

const ShareKhitmah = () => {
  const createUrl = async (khitmah) => {
    try {
      return await getKhitmahShareUrl(
        khitmah,
        process.env.REACT_APP_ENV,
        process.env.REACT_APP_API_KEY
      );
    } catch (error) {
      throw error;
    }
  };

  return {
    createUrl,
  };
};

export default ShareKhitmah;
