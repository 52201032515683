import Countries from "../Components/LanguagePicker/locale";

const convertKhitmahtoImplicitTypes = (khitmah) => {
  const localKhitmahTyped = { ...khitmah };
  localKhitmahTyped.dateOfPassing = localKhitmahTyped.dateOfPassing?.toDate();
  if (localKhitmahTyped.memorial) {
    localKhitmahTyped.memorial.date = localKhitmahTyped.memorial?.date?.toDate();
    localKhitmahTyped.memorial.time = localKhitmahTyped.memorial?.time?.toDate();
    localKhitmahTyped.memorial.endTime = localKhitmahTyped.memorial?.endTime?.toDate();
  }
  localKhitmahTyped.endDate =
    typeof localKhitmahTyped.endDate.toDate === "function"
      ? localKhitmahTyped.endDate.toDate()
      : localKhitmahTyped.endDate;
  localKhitmahTyped.createdAt = localKhitmahTyped.createdAt?.toDate();
  localKhitmahTyped.updatedAt = localKhitmahTyped.updatedAt?.toDate();
  localKhitmahTyped.deletedAt = localKhitmahTyped.deletedAt?.toDate();

  return localKhitmahTyped;
};

const getBrowserDefaultLanguage = () => {
  const browserLanugage = "abc"; //navigator.language.split(/[-_]/)[0];
  const defaultSelected =
    Countries.filter((country) => country.code === browserLanugage)[0]?.code ??
    "en";
  return defaultSelected;
};

export { convertKhitmahtoImplicitTypes, getBrowserDefaultLanguage };
