import mixpanel, {track} from '../../Mixpanel/mixpanel';

//initialize mixpanel in platforms sepratly using mixpanel module as the functions for both platforms are diff

//register user to mixpanel using userid, new user will be registered and already registered usr will be identified
export const setMixpanelUser = (userId: any) => {
  try {
    mixpanel.identify(userId);
  } catch (e) {
    console.log('Error in seting mixpanel user', e);
  }
};

export const trackEvent = (eventName: string, properties: any) => {
  try {
    // console.log('tracktracktracktrack', eventName, properties);
    track(eventName, properties);
  } catch (e) {
    console.log('Error in tracking event', e);
  }
};
