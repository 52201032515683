import React from "react";
import { useTranslation } from "react-i18next";

function JuzzCompletionStats(props) {
  const { completedCount = 0, pledgedCount = 0 } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="label-title">
        <span className="count">{completedCount}</span>{" "}
        {`${t("khitmah.juzCompleted")} `}
      </div>
      <div className="label-title">
        <span className="count">{pledgedCount}</span>{" "}
        {`${t("khitmah.juzPledged")} `}
      </div>
    </>
  );
}

export default JuzzCompletionStats;
