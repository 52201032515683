import React from "react";
import AssetsConstants from "../../Constants/AssetsConstants";

function TermsAndCondition(props) {
  const content = (
    <>
      {
        <div className="cms-khitmah-screen generic-inner-sec">
          <div className="inner-bg-box">
            <div className="head-box">
              <img src={AssetsConstants.khitmahHeading} alt="khitmah" />
            </div>
            <div className="content-box">
              <h2 className="title-main">
                Date of Last Revision: January 2021
              </h2>

              <p>
                Please read these terms and conditions carefully before using
                Our Service.
              </p>

              <h3>Interpretation and Definitions</h3>

              <h3 className="title-sub">Interpretation</h3>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <p>
                We reserve the right, at our sole discretion, to change or
                modify portions of these Terms of Service at any time. When we
                do this, we will post the revised Terms of Service on this page
                and will indicate the date of such revision.
              </p>

              <h3 className="title-sub">Definitions</h3>

              <p>For the purposes of these Terms and Conditions:</p>

              <p>
                <strong>Application</strong> means the software program provided
                by the Company downloaded by You on any electronic device, named
                Khitmah App with Friends
              </p>

              <p>
                <strong>Application Store</strong> means the digital
                distribution service operated and developed by Apple Inc. (Apple
                App Store) or Google Inc. (Google Play Store) in which the
                Application has been downloaded.
              </p>

              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
              </p>

              <p>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </p>

              <p>
                <strong>Country</strong> refers to: Michigan, United States
              </p>

              <p>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to KeyFaya Labs,
                6425 WORLINGTON RD.
              </p>

              <p>
                <strong>Content</strong> refers to content such as text, images,
                or other information that can be posted, uploaded, linked to or
                otherwise made available by You, regardless of the form of that
                content.
              </p>

              <p>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </p>

              <p>
                <strong>Feedback</strong> means feedback, innovations or
                suggestions sent by You regarding the attributes, performance or
                features of our Service.
              </p>

              <p>
                <strong>Goods</strong> refer to the items offered for sale on
                the Service.
              </p>

              <p>
                <strong>In-app Purchase</strong> refers to the purchase of a
                product, item, service or Subscription made through the
                Application and subject to these Terms and Conditions and/or the
                Application Store's own terms and conditions.
              </p>

              <p>
                <strong>Orders</strong> mean a request by You to purchase Goods
                from Us.
              </p>

              <p>
                <strong>Service</strong> refers to the Application or the
                Website or both.
              </p>

              <p>
                <strong>Terms and Conditions</strong> (also referred as "Terms")
                mean these Terms and Conditions that form the entire agreement
                between You and the Company regarding the use of the Service.
              </p>

              <p>
                <strong>Third-party Social Media Service</strong> means any
                services or content (including data, information, products or
                services) provided by a third-party that may be displayed,
                included or made available by the Service.
              </p>

              <p>
                <strong>Website</strong> refers to KeyFaya Labs, accessible from
                www.keyfaya.org
              </p>

              <p>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </p>

              <h3 className="sub-heading">Acknowledgment</h3>
              <p>
                These are the Terms and Conditions governing the use of this
                Service and the agreement that operates between You and the
                Company. These Terms and Conditions set out the rights and
                obligations of all users regarding the use of the Service.
              </p>
              <p>
                Your access to and use of the Service is conditioned on Your
                acceptance of and compliance with these Terms and Conditions.
                These Terms and Conditions apply to all visitors, users and
                others who access or use the Service.
              </p>
              <p>
                By accessing or using the Service You agree to be bound by these
                Terms and Conditions. If You disagree with any part of these
                Terms and Conditions then You may not access the Service.
              </p>
              <p>
                You represent that you are over the age of 18. The Company does
                not permit those under 18 to use the Service.
              </p>
              <p>
                Your access to and use of the Service is also conditioned on
                Your acceptance of and compliance with the Privacy Policy of the
                Company. Our Privacy Policy describes Our policies and
                procedures on the collection, use and disclosure of Your
                personal information when You use the Application or the Website
                and tells You about Your privacy rights and how the law protects
                You. Please read Our Privacy Policy carefully before using Our
                Service.
              </p>

              <h3 className="sub-heading">User Accounts</h3>
              <p>
                When You create an account with Us, You must provide Us
                information that is accurate, complete, and current at all
                times. Failure to do so constitutes a breach of the Terms, which
                may result in immediate termination of Your account on Our
                Service.
              </p>
              <p>
                You are responsible for safeguarding the password that You use
                to access the Service and for any activities or actions under
                Your password, whether Your password is with Our Service or a
                Third-Party Social Media Service.
              </p>
              <p>
                You agree not to disclose Your password to any third party. You
                must notify Us immediately upon becoming aware of any breach of
                security or unauthorized use of Your account.
              </p>
              <p>
                You may not use as a username the name of another person or
                entity or that is not lawfully available for use, a name or
                trademark that is subject to any rights of another person or
                entity other than You without appropriate authorization, or a
                name that is otherwise offensive, vulgar or obscene.
              </p>

              <h3 className="sub-heading">Content</h3>

              <h4>Your Right to Post Content</h4>
              <p>
                Our Service allows You to post Content. You are responsible for
                the Content that You post to the Service, including its
                legality, reliability, and appropriateness.
              </p>
              <p>
                By posting Content to the Service, You grant Us the right and
                license to use, modify, publicly perform, publicly display,
                reproduce, and distribute such Content on and through the
                Service. You retain any and all of Your rights to any Content
                You submit, post or display on or through the Service and You
                are responsible for protecting those rights. You agree that this
                license includes the right for Us to make Your Content available
                to other users of the Service, who may also use Your Content
                subject to these Terms.
              </p>
              <p>
                <strong>
                  You represent and warrant that: (i) the Content is Yours (You
                  own it) or You have the right to use it and grant Us the
                  rights and license as provided in these Terms, and (ii) the
                  posting of Your Content on or through the Service does not
                  violate the privacy rights, publicity rights, copyrights,
                  contract rights or any other rights of any person.
                </strong>
              </p>

              <h4>Content Restrictions</h4>
              <p>
                The Company is not responsible for the content of the Service's
                users. You expressly understand and agree that You are solely
                responsible for the Content and for all activity that occurs
                under your account, whether done so by You or any third person
                using Your account.
              </p>
              <p>
                You may not transmit any Content that is unlawful, offensive,
                upsetting, intended to disgust, threatening, libelous,
                defamatory, obscene or otherwise objectionable. Examples of such
                objectionable Content include, but are not limited to, the
                following:
                <ul>
                  <li>Unlawful or promoting unlawful activity.</li>
                  <li>
                    Defamatory, discriminatory, or mean-spirited content,
                    including references or commentary about religion, race,
                    sexual orientation, gender, national/ethnic origin, or other
                    targeted groups.
                  </li>
                  <li>
                    Spam, machine – or randomly – generated, constituting
                    unauthorized or unsolicited advertising, chain letters, any
                    other form of unauthorized solicitation, or any form of
                    lottery or gambling.
                  </li>
                  <li>
                    Containing or installing any viruses, worms, malware, trojan
                    horses, or other content that is designed or intended to
                    disrupt, damage, or limit the functioning of any software,
                    hardware or telecommunications equipment or to damage or
                    obtain unauthorized access to any data or other information
                    of a third person.
                  </li>
                  <li>
                    Infringing on any proprietary rights of any party, including
                    patent, trademark, trade secret, copyright, right of
                    publicity or other rights.
                  </li>
                  <li>
                    Impersonating any person or entity including the Company and
                    its employees or representatives.
                  </li>
                  <li>Violating the privacy of any third person.</li>
                  <li>False information and features.</li>
                  The Company reserves the right, but not the obligation, to, in
                  its sole discretion, determine whether or not any Content is
                  appropriate and complies with this Terms, refuse or remove
                  this Content. The Company further reserves the right to make
                  formatting and edits and change the manner any Content. The
                  Company can also limit or revoke the use of the Service if You
                  post such objectionable Content. As the Company cannot control
                  all content posted by users and/or third parties on the
                  Service, you agree to use the Service at your own risk. You
                  understand that by using the Service You may be exposed to
                  content that You may find offensive, indecent, incorrect or
                  objectionable, and You agree that under no circumstances will
                  the Company be liable in any way for any content, including
                  any errors or omissions in any content, or any loss or damage
                  of any kind incurred as a result of your use of any content.
                </ul>
              </p>

              <h4>Content Backups</h4>
              <p>
                Although regular backups of Content are performed, the Company
                do not guarantee there will be no loss or corruption of data.
              </p>
              <p>
                Corrupt or invalid backup points may be caused by, without
                limitation, Content that is corrupted prior to being backed up
                or that changes during the time a backup is performed.
              </p>
              <p>
                The Company will provide support and attempt to troubleshoot any
                known or discovered issues that may affect the backups of
                Content. But You acknowledge that the Company has no liability
                related to the integrity of Content or the failure to
                successfully restore Content to a usable state.
              </p>
              <p>
                You agree to maintain a complete and accurate copy of any
                Content in a location independent of the Service.
              </p>

              <h3 className="sub-heading">Links to Other Websites</h3>
              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by the Company.
              </p>
              <p>
                The Company has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any third
                party web sites or services. You further acknowledge and agree
                that the Company shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                such content, goods or services available on or through any such
                web sites or services.
              </p>
              <p>
                We strongly advise You to read the terms and conditions and
                privacy policies of any third-party web sites or services that
                You visit.
              </p>

              <h3 className="sub-heading">Termination</h3>
              <p>
                We may terminate or suspend Your Account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if You breach these Terms and Conditions.
              </p>
              <p>
                Upon termination, Your right to use the Service will cease
                immediately. If You wish to terminate Your Account, You may
                simply discontinue using the Service.
              </p>

              <h3 className="sub-heading">Limitation of Liability</h3>
              <p>
                Notwithstanding any damages that You might incur, the entire
                liability of the Company and any of its suppliers under any
                provision of this Terms and Your exclusive remedy for all of the
                foregoing shall be limited to the amount actually paid by You
                through the Service or 100 USD if You haven't purchased anything
                through the Service.
              </p>
              <p>
                To the maximum extent permitted by applicable law, in no event
                shall the Company or its suppliers be liable for any special,
                incidental, indirect, or consequential damages whatsoever
                (including, but not limited to, damages for loss of profits,
                loss of data or other information, for business interruption,
                for personal injury, loss of privacy arising out of or in any
                way related to the use of or inability to use the Service,
                third-party software and/or third-party hardware used with the
                Service, or otherwise in connection with any provision of this
                Terms), even if the Company or any supplier has been advised of
                the possibility of such damages and even if the remedy fails of
                its essential purpose.
              </p>
              <p>
                Some states do not allow the exclusion of implied warranties or
                limitation of liability for incidental or consequential damages,
                which means that some of the above limitations may not apply. In
                these states, each party's liability will be limited to the
                greatest extent permitted by law.
              </p>

              <h3 className="sub-heading">
                "AS IS" and "AS AVAILABLE" Disclaimer
              </h3>
              <p>
                The Service is provided to You "AS IS" and "AS AVAILABLE" and
                with all faults and defects without warranty of any kind. To the
                maximum extent permitted under applicable law, the Company, on
                its own behalf and on behalf of its Affiliates and its and their
                respective licensors and service providers, expressly disclaims
                all warranties, whether express, implied, statutory or
                otherwise, with respect to the Service, including all implied
                warranties of merchantability, fitness for a particular purpose,
                title and non-infringement, and warranties that may arise out of
                course of dealing, course of performance, usage or trade
                practice. Without limitation to the foregoing, the Company
                provides no warranty or undertaking, and makes no representation
                of any kind that the Service will meet Your requirements,
                achieve any intended results, be compatible or work with any
                other software, applications, systems or services, operate
                without interruption, meet any performance or reliability
                standards or be error free or that any errors or defects can or
                will be corrected.
              </p>
              <p>
                Without limiting the foregoing, neither the Company nor any of
                the company's provider makes any representation or warranty of
                any kind, express or implied: (i) as to the operation or
                availability of the Service, or the information, content, and
                materials or products included thereon; (ii) that the Service
                will be uninterrupted or error-free; (iii) as to the accuracy,
                reliability, or currency of any information or content provided
                through the Service; or (iv) that the Service, its servers, the
                content, or e-mails sent from or on behalf of the Company are
                free of viruses, scripts, trojan horses, worms, malware,
                timebombs or other harmful components.
              </p>
              <p>
                Some jurisdictions do not allow the exclusion of certain types
                of warranties or limitations on applicable statutory rights of a
                consumer, so some or all of the above exclusions and limitations
                may not apply to You. But in such a case the exclusions and
                limitations set forth in this section shall be applied to the
                greatest extent enforceable under applicable law.
              </p>

              <p>
                Subject to these Terms of Service, as amended from time to time
                (“Terms of Service”), KeyFaya Labs, Inc. provides (a) the
                KeyFaya Labs platform to you through its website at www.KeyFaya
                Labs.com and attendant mobile applications (the "KeyFaya Labs
                Platform") and related services (collectively, the KeyFaya Labs
                Platform. If you are a campaign organizer (as defined below),
                beneficiary to a campaign, comment contributor, or donor
                (collectively referred to herein as a “User”) located in the
                United States, you are contracting with KeyFaya Labs. Unless
                specifically indicated otherwise, for purposes of the following
                Terms of Service, “KeyFaya Labs,” “we,” “us,” “our,” and other
                similar terms, shall refer to the party with whom you are
                contracting.
              </p>
              <p>
                ARBITRATION, JURY TRIAL, AND CLASS ACTION WAIVER: EXCEPT AS
                OTHERWISE DESCRIBED IN THE DISPUTES SECTION BELOW, BY USING THE
                KEYFAYA LABS SERVICES OR KEYFAYA LABS SERVICES (COLLECTIVELY
                “SERVICES”), YOU AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED
                BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO A
                JURY TRIAL OR TO PARTICIPATE IN A CLASS ACTION. PLEASE REVIEW
                THE DISPUTES SECTION CAREFULLY; BY ENTERING THIS AGREEMENT, YOU
                EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF
                THE TERMS OF THIS AGREEMENT.
              </p>
              <p>
                We reserve the right, at our sole discretion, to change or
                modify portions of these Terms of Service at any time. When we
                do this, we will post the revised Terms of Service on this page
                and will indicate the date of such revision.
              </p>
              <p>
                Your continued use of the Services after the date of any such
                changes constitutes your acceptance of the new Terms of Service.
                To the extent allowed by law, the English version of these Terms
                of Service is binding and other translations are for convenience
                only. If you do not wish to accept the new Terms of Service, you
                may discontinue your use of the Services.
              </p>
              <p>
                In addition, when using the Services, and unless you are in
                European Economic Area, the United Kingdom, and Switzerland
                (“Europe”), you will be subject to additional applicable
                policies including without limitation, the Privacy Policy. All
                such terms are hereby incorporated by reference into these Terms
                of Service (provided, however, these{" "}
                <a href="https://www.gofundme.com/privacy" target="_blank">
                  Terms of Service
                </a>{" "}
                will take precedence in the event of conflict). We shall resolve
                any such conflicts in our sole discretion, and all of our
                determinations are final.
              </p>

              <h4>ACCESS AND USE OF THE SERVICES</h4>

              <h5>KeyFaya Labs Services Description:</h5>
              <p>
                The KeyFaya Labs Services are offered as a platform to allow an
                individual, entity or non-profit organization (the "Campaign
                Organizer") to post a fundraising campaign ("Campaign") to the
                KeyFaya Labs Platform to accept monetary donations ("Donations")
                from donors ("Donors").
              </p>

              <h5>Payment Processor:</h5>
              <p>
                KeyFaya Labs is not a payment processor. Instead, KeyFaya Labs
                uses third-party payment processing partners to process
                Donations for a Campaign (“Payment Processor”). You acknowledge
                and agree that the use of Payment Processors are integral to the
                Services and that we exchange information with Payment
                Processors in order to facilitate the provision of Services.
              </p>

              <h5>Payment Processor Fees:</h5>
              <p>
                Although there are no fees to set up a Campaign,
                industry-standard payment processor fees apply (hereinafter and
                on the website referred to as "Payment Processor Fees"). To
                learn more about the KeyFaya Labs Platform, the KeyFaya Labs
                Platform and applicable Payment Processor Fees for each, visit
                KeyFaya Labs Campaign.
              </p>

              <h5>
                The Services are platforms; We are not a Broker, Financial
                Institution, Creditor or Charity:
              </h5>
              <p>
                The Services are administrative platforms only. KeyFaya Labs
                facilitates the Campaign of the Campaign Organizers and permits
                Donors to make donations to these Campaign Organizers. KeyFaya
                Labs is not a broker, agent, financial institution, creditor or
                501(c)(3) nonprofit corporation.
              </p>

              <p>
                All information and content provided by KeyFaya Labs relating to
                the Services is for informational purposes only, and KeyFaya
                Labs does not guarantee the accuracy, completeness, timeliness
                or reliability of any such information or content. No content is
                intended to provide financial, legal, tax or other professional
                advice. Before making any decisions regarding any Campaigns,
                Charities, Donations, Donors, or any information or content
                relating to the Services, you should consult your financial,
                legal, tax or other professional advisor as appropriate. You
                acknowledge that all information and content accessed by you
                using the Services is at your own risk.
              </p>

              <p>
                KeyFaya Labs has no control over the conduct of, or any
                information provided by, a User and hereby disclaims all
                liability in this regard to the fullest extent permitted by
                applicable law. We do not guarantee that a Campaign will obtain
                a certain amount of Donations or any Donations at all. We do not
                endorse any Campaign, User, or cause and we make no guarantee,
                express or implied, that any information provided through the
                Services is accurate. We expressly disclaim any liability or
                responsibility for the outcome or success of any Campaign. You,
                as a Donor, must make the final determination as to the value
                and appropriateness of contributing to any User, Campaign, or
                event.
              </p>

              <h5>No Solicitation:</h5>
              <p>
                The KeyFaya Labs (collectively “Platforms”) are offered to help
                Campaign Organizers raise money. KeyFaya Labs merely provides
                the technology to allow fundraisers to connect with Donors. The
                existence of the Services is not a solicitation of donations by
                KeyFaya Labs, and KeyFaya Labs does not engage in any
                solicitation activities, or consult on the solicitation of
                contributions from the public, on behalf of any individual,
                entity, or organization. By using the Services, you understand
                and agree that KeyFaya Labs shall not be responsible for the use
                of your donations or the amount of funds raised for the User,
                Campaign, or event.
              </p>

              <h5>Donors:</h5>
              <p>
                All Donations are at your own risk. When you make a Donation
                through either of the Platforms, it is your responsibility to
                understand how your money will be used. KeyFaya Labs is not
                responsible for any offers, promises, rewards or promotions made
                or offered by Users or Campaigns. We do not and cannot verify
                the information that Users or Campaigns supply, nor do we
                represent or guarantee that the Donations will be used in
                accordance with any fundraising purpose prescribed by a User or
                Campaign or in accordance with applicable laws. Notwithstanding
                the foregoing, we take possible fraudulent activity and the
                misuse of funds raised very seriously. If you have reason to
                believe that a User or Campaign is not raising or using the
                funds for their stated purpose, please use the "Report" button
                on the Campaign to alert our team of this potential issue and we
                will investigate.
                <ul className="list-alphabetic">
                  <li>
                    Donors are not permitted to impose restrictions on the use
                    of Donations by a Charity or Event Organizer. To the extent
                    that a Donation is made in response to an appeal for a
                    particular program of a Charity or Event Organizer, or to
                    the extent that a Donor purports to direct the use of
                    Donations by a Charity or Event Organizer, any such
                    directions shall constitute non-binding recommendations only
                    and the Charity or Event Organizer shall have full
                    discretion to determine how all Donations will be used.
                  </li>
                  <li>
                    KeyFaya Labs will have no liability for any claim by any
                    federal, state, provincial, territorial, local or any other
                    tax authority with respect to the characterization on any
                    applicable tax return of any Donation by you, any User or
                    any Charity. You should consult your tax advisor as to the
                    amount of your Donation that is tax deductible or eligible
                    for tax recognition, having regard to (among other things)
                    the tax status of the recipient of any Donation in any
                    relevant jurisdiction, and particularly if you are awarded a
                    gift or prize in connection with your Donation.
                  </li>
                  <li>
                    Donors shall provide KeyFaya Labs with such information as
                    is required to enable the issuing of an official Donation
                    receipt. Donor acknowledges and agrees that, in accordance
                    with the Privacy Policy, certain of Donor’s personal
                    information will be shared with the Charity to which such
                    Donor makes a donation (including without limitation as part
                    of a Donor List, as set forth above) and may be used by such
                    Charity in accordance with the Charity’s Privacy Policy.
                    KeyFaya Labs is not responsible, and shall not be liable,
                    for any Charity’s use of any Donor information.
                  </li>
                </ul>
                Certain states require written disclosures for Charities
                soliciting contributions. For donors making donations to
                Charities, please see each state’s non-profit disclosures for
                those charities.
              </p>

              <h5>Campaign Organizer:</h5>
              <p>
                You, as a Campaign Organizer, represent, warrant, and covenant
                that (i) all information you provide in connection with a
                Campaign or Beneficiary is accurate, complete, and not likely to
                deceive reasonable Users; (ii) all Donations contributed to your
                Campaign will be used solely as described in the materials that
                you post or otherwise provide; (iii) if you withdraw donations
                believed by reasonable Donors to be raised on behalf of someone
                other than you (i.e., the Beneficiary), all Donations will be
                given to and/or spent on behalf of the Beneficiary; (iv) if you
                add a Beneficiary through the Services, you relinquish control
                of the Donations, including the ability to issue refunds; (v)
                you will not infringe the rights of others; (vi) you will comply
                with all relevant and applicable law and financial reporting
                obligations, including but not limited to laws and regulations
                relating to registration, tax reporting, political
                contributions, and asset disclosures for your project; and (vii)
                to the extent you share with us any personal data of any third
                party for any purpose, including the names, email addresses and
                phone numbers of your personal contacts, you have the authority
                (including any necessary consents), as required under applicable
                law, to provide us with such personal data and allow us to use
                such personal data for the purposes for which you shared it with
                us. You authorize KeyFaya Labs, and KeyFaya Labs reserves the
                right to, provide information relating to your Campaign to
                Donors, beneficiaries of your Campaign or law enforcement, and
                to assist in any investigation thereof.
              </p>
              <p>
                If you use the Services as an agent of a Charity using the
                services to raise funds for such Charity, you represent and
                warrant that: (a) you are a representative of the Charity, which
                representative is authorized to raise funds or bind the Charity
                to these Terms of Service; (b) you are raising funds for a
                Charity, with a cause or activity that is legal under all
                applicable federal, state, provincial, territorial and local
                laws and regulations; (c) all donated funds will be used solely
                for the purpose you have stated on and in connection with your
                Campaign, and under no circumstances may you use the funds for
                any other purpose; (d) your Charity has and will maintain
                tax-exempt status under applicable law (example, the Internal
                Revenue Code in the United States or the Income Tax Act in
                Canada; or (e) if your Charity is in the United States, your
                Charity is registered with GuideStar or the IRS tax exempt
                organization database, or, in Canada, is listed in the Canada
                Revenue Agency's database of registered charities.
              </p>

              <h5>Your Registration Obligations:</h5>
              <p>
                You may be required to register with KeyFaya Labs in order to
                access and use certain features of the Services. If you choose
                to register for the Services, you agree to provide and maintain
                true, accurate, current and complete information about yourself
                or your Charity as prompted by the Services' registration form.
                Campaign Organizers must register using their true identities
                (or the identities of the Charities’ authorized
                representatives), including their name, address and any image or
                video purporting to depict the Campaign Organizer or the
                Beneficiary of such campaign. You agree to keep registration
                information current and up to date.
              </p>
              <p>
                Registration data and certain other information about you are
                governed by these Terms of Service and our Privacy Policy. If
                you are under 13 years of age (16 in Europe), you are not
                authorized to use the Services, with or without registering. In
                addition, if you are under the age of majority in your
                jurisdiction (typically 18 or 19 years of age), you may use the
                Services, with or without registering, only with the approval of
                your parent or guardian. Certain aspects of our Services may
                also require you to register with, and agree to the terms of,
                third-party service providers (e.g., Payment Processors), with
                whom KeyFaya Labs has entered into contracts, in order to be
                able to benefit from their services. If KeyFaya Labs or one of
                our payments processors at any time discovers that the
                information you provided about you or the purpose of your
                Campaign is incorrect or violates any of these Terms of Service
                or their terms of service, the Services may be suspended and/or
                terminated with immediate effect and fines may be applied by the
                relevant authorities which will in all such cases be payable by
                you. You acknowledge and agree that the use of third-party
                Payment Processors are integral to the Services and that we
                exchange information with such third parties in order to
                facilitate the provision of Services as set out in our Privacy
                Policy.
              </p>

              <h3 className="title-sub">Charities</h3>
              <p>
                The KeyFaya Labs Services include the features and services
                described here. All donations are subject to a Payment Processor
                Fee for each donation. Donors have the option to cover all
                Payment Processor Fees at checkout.
                <ul className="list-alphabetic">
                  <li>
                    Chargebacks and Refunds. Occasionally, a Donor may dispute a
                    credit card charge for a Donation through the Services.
                    <ul className="list-numeric">
                      <li>
                        If Donations are refunded to the Donor by PayPal Giving
                        Fund and PayPal Giving Fund already remitted payment to
                        the Charity, PayPal Giving Fund will deduct the
                        chargebacks or refunds from future payments to the
                        Charity, and if necessary, issue an invoice to the
                        applicable Charity. The applicable Charity expressly
                        agrees that it will be responsible for paying PayPal
                        Giving Fund the full amount of any Donation refund due
                        to a Donor. PayPal Giving Fund may elect to offset a
                        future Donation rather than requesting that the Charity
                        return the refunded Donation.
                      </li>
                      <li>
                        If such Donations were made through a payment gateway
                        (e.g. Braintree, Stripe, WePay, etc.), the Charity has
                        control over the Donations and Charity, rather than
                        KeyFaya Labs, is responsible for issuing refunds and
                        handling chargebacks directly with Donors.
                      </li>
                    </ul>
                  </li>
                  <li>
                    You acknowledge that certain data available or otherwise
                    accessible on or by means of the Services concerning the
                    list of United States-based 501(c)(3) charities to which you
                    may make a Donation is provided by GuideStar USA, Inc.
                    ("GuideStar"), a third party licensor of KeyFaya Labs. For
                    good and valuable consideration, the receipt and sufficiency
                    of which is hereby acknowledged by you and KeyFaya Labs, you
                    acknowledge and agree that GuideStar (and its successors and
                    assigns) is an intended third party beneficiary with full
                    power and authority to enforce the provisions of these Terms
                    only insofar as it relates to data provided by GuideStar
                    (the “GuideStar Data”). You agree that KeyFaya Labs may
                    share any information provided by you with GuideStar to the
                    extent that GuideStar reasonably needs such data to enforce
                    its rights related to your use of any GuideStar Data.
                  </li>

                  <li>
                    Receiving Funds. As a Charity, receipt of Donations, minus
                    any applicable Payment Processor Fees, is based upon and
                    subject to the applicable Payment Processor's procedures and
                    terms. Available Processors are described under "Payment
                    Processors for Charities on KeyFaya Labs Platform" below.
                    KeyFaya Labs is not a payment processor and does not hold
                    funds.
                  </li>

                  <li>
                    KeyFaya Labs does not withhold funds for tax purposes or
                    otherwise. Charities will be solely responsible for taxes
                    based on applicable international, federal, state, local or
                    any other taxes or levies, or for any applicable taxes based
                    on their net income or gross receipts (if any).
                  </li>

                  <li>
                    Canadian Charities.
                    <ul className="list-numeric">
                      <li>
                        Official Donation Receipts. Charities in Canada have the
                        option of appointing KeyFaya Labs to issue official
                        Donation receipts on the Charity's behalf for Donations
                        to the Charity through the KeyFaya Labs Services.
                        Charities that do so understand and acknowledge that the
                        Charity is responsible under the Income Tax Act to
                        ensure that all receipts are issued in compliance with
                        applicable laws. A Charity may at any time rescind its
                        appointment of KeyFaya Labs in this capacity, at which
                        time KeyFaya Labs will immediately cease issuing
                        receipts on behalf of the Charity.
                      </li>
                      <li>
                        Required Information. Charities in Canada that appoint
                        KeyFaya Labs to issue receipts on their behalf must
                        provide KeyFaya Labs with the following information, and
                        must notify KeyFaya Labs immediately of any changes to
                        the information below:
                        <ul className="list-alphabetic">
                          <li>
                            Current head office address as recorded with the
                            Canada Revenue Agency;
                          </li>
                          <li>
                            Name of representative duly authorized by the
                            Charity to sign official Donation receipts;
                          </li>
                          <li>
                            Reproducible .jpeg image of signature of authorized
                            representative that can be affixed to official
                            Donation receipts;
                          </li>
                          <li>
                            A description and the fair market value of any and
                            all prizes, rewards or other benefits provided to
                            Donors in respect of Donations to the Charity
                            through the KeyFaya Labs Services, which shall be
                            used to determine the eligible amount of the gift
                            for receipting purposes. For greater certainty, the
                            value of any such benefits shall be subtracted from
                            the fair market value of property gifted to the
                            Charity to determine the eligible amount of the
                            gift.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Representation of KeyFaya Labs. Subject to the
                        obligations of the Charity set out herein and in
                        particular paragraph 4 below, KeyFaya Labs represents
                        and warrants that, when applicable, it will issue tax
                        receipts in accordance with the requirements of the
                        applicable Canadian law.
                      </li>
                      <li>
                        Reliance on Information Provided. KeyFaya Labs will rely
                        wholly on the information provided by the Charity or its
                        representatives in order to prepare official Donation
                        receipts, as well as information supplied to it by
                        Donors when making Donations. KeyFaya Labs is not
                        responsible to verify any information provided to it by
                        the Charity, any representative of the Charity, or any
                        Donor. The Charity acknowledges this and agrees that
                        KeyFaya Labs shall not be responsible for any incorrect
                        information included on an official Donation receipt, or
                        for any tax or regulatory consequences resulting from
                        the provision of incorrect information by the Charity,
                        any representative of the Charity, or any Donor.
                      </li>
                      <li>
                        Records. All Canadian registered charities are subject
                        to record-keeping obligations under Canadian law. Each
                        Canadian Charity is responsible to ensure that it
                        maintains such records as are required under Applicable
                        Law. This includes a requirement to maintain copies in
                        Canada of all official Donation receipts issued and
                        records to verify Donations received. A Canadian Charity
                        can select to have KeyFaya Labs copy the Charity on all
                        emails sent to Donors on the Charity's behalf which
                        contain an official Donation receipt. Each Charity can
                        also access Donation reports and copies of all official
                        Donation receipts issued on that Charity's behalf by
                        logging on to KeyFaya Labs Services, and can print these
                        documents manually. Canadian Charities can also access
                        reconciliation reports from WePay pursuant to WePay's
                        Terms of Service.
                      </li>
                      <li>
                        Form of Receipt. A Charity on behalf of which KeyFaya
                        Labs issues official Donation receipts may at any time
                        request a copy of the form of receipt being issued and
                        may direct KeyFaya Labs to revise the form of receipt as
                        necessary to ensure compliance with Applicable Laws.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>

              <h3 className="title-sub">Ownership of Donor Lists and Data</h3>
              <p>
                <ul className="list-alphabetic">
                  <li>
                    As between KeyFaya Labs and Charity using the KeyFaya Labs
                    Platform, Charity may request Donor Lists (defined below)
                    and Donor Data (defined below) for compliance and
                    transactional purposes. If the Charity has a Charity Contact
                    account on the KeyFaya Labs Platform, KeyFaya Labs shall
                    transfer all Donor Lists and Donor Data to Charity’s
                    account.
                  </li>
                  <li>
                    As between KeyFaya Labs and Charity using the KeyFaya Labs
                    Platform, Charity may request Donor Lists (defined below)
                    and Donor Data (defined below) for compliance and
                    transactional purposes.
                  </li>
                  <li>
                    As between KeyFaya Labs and Charity using the KeyFaya Labs
                    Platform, with respect to Donor lists and Donor Data that
                    KeyFaya Labs has received exclusively by means of a donation
                    to such Charity and by no other means, and to the extent
                    permitted by the Privacy Policy and applicable law, Charity
                    shall own the Donor Data of Donors who have provided a
                    Donation to Charity, including contact information of such
                    Donors; provided, however, that Charity grants to KeyFaya
                    Labs a worldwide, non-exclusive, royalty-free, perpetual
                    license to use Donor Data for the following purposes: (a) to
                    provide the Services, including account administration and
                    processing donations; (b) to enforce our agreements; (c)
                    fraud prevention, safety, security, and compliance with law;
                    (d) to respond to law enforcement requests; (e) research and
                    analytics; (f) to customize, adjust, and improve the
                    Services; and (g) to develop new services. “Donor Data”
                    shall mean a Donor’s name, address, email address, donation
                    amount, date of transaction, transaction identification
                    number, name of the project, and event associated with the
                    Donation. THE LIST OF DONORS WHO CONTRIBUTE TO A CHARITY'S
                    CAMPAIGN OR EVENT CONDUCTED THROUGH THE SERVICES (“DONOR
                    LISTS”) ARE PROVIDED “AS IS”, AND KEYFAYA LABS MAKES NO
                    REPRESENTATIONS, WARRANTIES OR GUARANTEES ABOUT THE
                    ACCURACY, COMPLETENESS OR TIMELINESS OF ANY DONOR LIST OR
                    ANY INFORMATION CONTAINED THEREIN. By using these Services,
                    all Donors consent to the disclosure of such Donor Lists and
                    Donor Data described in this Section.
                  </li>
                </ul>
              </p>

              <h5>Taxes:</h5>
              <p>
                It is your responsibility to determine what, if any, taxes apply
                to the Donations you receive through your use of the Services.
                It is solely your responsibility to assess, collect, report or
                remit the correct tax, if any, to the appropriate tax authority.
              </p>

              <h5>Member Account, Password and Security:</h5>
              <p>
                You are responsible for maintaining the confidentiality of your
                password and account, if any, and are fully responsible for any
                and all activities that occur under your password or account.
                You agree to (a) immediately notify KeyFaya Labs of any
                unauthorized use of your password or account or any other breach
                of security, and (b) sign out from your account at the end of
                each session when accessing the Services. KeyFaya Labs will not
                be liable for any loss or damage arising from your failure to
                comply with this Section.
              </p>

              <h5>Modifications to Services:</h5>
              <p>
                KeyFaya Labs reserves the right to modify, suspend or
                discontinue, temporarily or permanently, the Services (or any
                part thereof) at any time and for any reason, with or without
                notice, and without any liability to you or to any third party
                for any claims, damages, costs, or losses resulting therefrom.
              </p>

              <h3 className="title-sub">
                Content Manifestly Made Public by the User.
              </h3>

              <h5>
                Public Content; Public Display of Information and Donations:
              </h5>
              <p>
                Some of your activity on and through the Services is public,
                such as content you post publicly on the Platforms (including
                descriptions, texts, music, sound, information, data, software,
                graphics, comments, photos, videos, images, trademarks, logos,
                brands or other materials you upload or post through the
                Services or share with other users or recipients) (“User
                Content”). Additionally, user profile information, including
                your first and last name, public email address, organization,
                personal biography, and other information you enter in
                connection with your user profile may be displayed to other
                users to facilitate user interaction within the Services. For
                example, as a Campaign Organizer, you might post your personal
                data - such as information about a recent hospital stay - which
                data might be considered sensitive data. In addition, as a
                Donor, you have the option to publicly display your Donation for
                all to see, including on search engines (like Google and Yahoo).
                To keep the details of your Donation private from the general
                public, simply click the "Private" checkbox during the Donation
                process. Please remember that if you choose to provide
                information using certain public features of the Services, then
                that information is governed by the privacy settings of those
                particular features and may be publicly available. Individuals
                reading such information may use or disclose it to other
                individuals or entities without our knowledge and without your
                knowledge, and search engines may index that information. We
                therefore urge you to think carefully about including any
                specific information you may deem private in content that you
                create or information that you submit through the Services.
                Please see our{" "}
                <a target="_blank" href="https://www.gofundme.com/privacy">
                  Privacy Policy
                </a>{" "}
                for information on the ways that we may collect, use, and store
                certain information about you and your use of the Services.
              </p>

              <h5>Unsolicited Information:</h5>
              <p>
                Please be advised that User Content and other unsolicited
                information you provide may be publicly accessible, such as
                information you post in forums or comment sections. We also
                collect information through customer support communications,
                your communications to us of ideas for new products or
                modifications to existing products, and other unsolicited
                submissions, or any questions, comments, suggestions, ideas,
                feedback or other information about the Services (collectively,
                with publicly-accessible information, “Unsolicited
                Information”). By sending us Unsolicited Information, (a) you
                agree that we are under no obligation of confidentiality,
                expressed or implied, with respect to the Unsolicited
                Information; (b) you acknowledge that we may have something
                similar to the Unsolicited Information already under
                consideration or in development; (c) you agree that KeyFaya Labs
                will be entitled to the unrestricted use and dissemination of
                the Unsolicited Information for any purpose, commercial or
                otherwise, without acknowledgment or compensation to you; (d)
                you represent and warrant that you have all rights necessary to
                submit the Unsolicited Information; (e) to the extent necessary,
                you hereby grant to KeyFaya Labs a fully paid, royalty-free,
                perpetual, irrevocable, worldwide, non-exclusive, and fully
                transferable and sublicensable right (through multiple tiers)
                and license to use, reproduce, perform, display, distribute,
                adapt, modify, re-format, create derivative works of, and
                otherwise commercially or non-commercially exploit in any
                manner, any and all Unsolicited Information, and to sublicense
                the foregoing rights; and (f) you irrevocably waive, and cause
                to be waived, against KeyFaya Labs and its users any claims and
                assertions of any moral rights contained in such Unsolicited
                Information. This Unsolicited Information section shall survive
                any termination of your account or the Services.
              </p>
              <p>
                You acknowledge and agree that KeyFaya Labs may preserve
                Unsolicited Information, as well as User Content, and may also
                disclose your Unsolicited Information or User Content if
                required to do so by law or in the good-faith belief that such
                preservation or disclosure is reasonably necessary to (a) comply
                with legal process, applicable laws or government requests; (b)
                enforce these Terms of Service; (c) respond to claims that any
                User Content violates the rights of third parties; or (d)
                protect the rights, property, or personal safety of KeyFaya
                Labs, its users or the public.
              </p>

              <h5>Third-Party Communications:</h5>
              <p>
                If you use any feature of the Services that allows you to
                communicate with third parties (such as to refer a third party
                to the Services or to communicate with them regarding a Campaign
                or a donation), either by submitting Third-Party Data to the
                Services or otherwise permitting the Services to automatically
                access Third-Party Data in your possession, you acknowledge and
                agree that you have the authority of the relevant third party
                for us to access and use the relevant Third-Party Data and that
                you have notified these third parties and informed them how
                their information is collected and used by KeyFaya Labs to
                provide the Services. We reserve the right to identify you as
                the person who has made the referral in any messages that are
                sent to them. We use Third-Party Data to (a) contact such third
                party using the Third-Party Data provided, and/or (b) provide
                you with an editable template message designed to facilitate
                communications between you and such third party through the
                Services. In addition to sending the foregoing communications we
                may also send reminders or related messages to you and to third
                parties on your behalf from time to time where permitted by
                applicable law. In each case, any such communication sent to
                third parties using Third-Party Data will provide a means to
                “opt out” of receiving further communication of the same nature.
              </p>

              <h5>Events on the KeyFaya Labs Platform:</h5>
              <p>
                KeyFaya Labs Services enables Charities to offer and users to
                register for events, such as funerals, memorial sessions, sick
                visits. (collectively, "Events") which may require a
                registration fee, as determined by the organizers of these
                events ("Event Organizers"). Event Organizers may establish a
                page on the KeyFaya Labs Platform that provides details about
                their Event and provides the ability to collect Event
                registration fees through KeyFaya Labs Services. Event
                Organizers shall be solely responsible for such Events,
                including but not limited to any participation requirements,
                obtaining any and all necessary permits and waivers, and
                ensuring such Event complies with applicable law. Event
                Organizers shall be solely responsible for determining the
                amount of Event registration fees and providing any associated
                discounts, promotions or refunds.
                <ul className="list-alphabetic">
                  <li>
                    Fees. Event Organizers that use the KeyFaya Labs Services to
                    process Event registrations agree to pay KeyFaya Labs a
                    Payment Processor Fee for each Event registration.
                  </li>
                  <li>
                    Receipt of Registration Fees. Receipt of registration fees,
                    minus any applicable Payment Processor Fees, is based upon
                    and subject to the Payment Processor's procedures and terms.
                  </li>
                  <li>
                    KeyFaya Labs does not withhold funds for tax purposes or
                    otherwise. Event Organizers will be solely responsible for
                    taxes based on applicable international, federal, state,
                    local or any other taxes or levies.
                  </li>
                  <li>
                    Chargebacks and Refunds. Occasionally, an Event Registrant
                    may dispute a credit card charge for a Donation through the
                    KeyFaya Labs Services. All refunds for event registrations
                    are handled by Event Organizers. KeyFaya Labs is not
                    responsible for handling or communicating an Event
                    Organizer's refund policy or processing refunds, including
                    without limitation for any errors processing a refund, the
                    failure to provide a refund, the failure of an Event
                    Organizer to communicate about a refund, or any chargebacks
                    related to a refund. Donors should contact an Event
                    Organizer concerning a refund. Event Organizers agree to
                    deal exclusively with Event registrants concerning refunds.
                  </li>
                </ul>
              </p>

              <h5>Data Retention:</h5>
              <p>
                You acknowledge that KeyFaya Labs has no obligation to you to
                retain data relating to any account or Campaign. You acknowledge
                that KeyFaya Labs reserves the right to delete data or to
                terminate accounts or Campaigns at any time and for any reason,
                with or without notice, and without any liability to you or to
                any third party for any claims, damages, costs or losses
                resulting therefrom. The foregoing does not apply to Campaigns
                or accounts started by Charities on either Platform, in which
                case KeyFaya Labs will provide reasonable notice where possible.
              </p>

              <h5>Mobile KeyFaya Labs Services:</h5>
              <p>
                The KeyFaya Labs Services include certain features that may be
                made available via a mobile device, including the ability to (i)
                upload User Content to the KeyFaya Labs Platform, (ii) browse
                the KeyFaya Labs Platform and (iii) access certain items through
                an application downloaded and installed on a mobile device
                (collectively, the "Mobile Services"). To the extent you access
                Mobile Services, your wireless service carrier's standard
                charges, data rates and other fees may apply. In addition,
                downloading, installing, or using certain Mobile Services may be
                prohibited or restricted by your carrier, and not all Mobile
                Services may work with all carriers or devices. By using the
                Mobile Services, you agree that we may communicate with you by
                SMS, MMS, text message or other electronic means to your mobile
                device and that certain information about your usage of the
                Mobile Services may be communicated to us. We shall comply with
                any additional requirements that may apply under local laws and
                regulations before communicating with you in this manner. In the
                event that you change or deactivate your mobile telephone
                number, you agree to promptly update your KeyFaya Labs account
                information to ensure that your messages are not sent to the
                person that acquires your old number.
              </p>

              <h3 className="title-sub">PROHIBITED CONDUCT</h3>

              <p>
                You are solely responsible for compliance with all applicable
                law in relation to your Campaign or use of the Services. You are
                further solely responsible for all User Content that you upload,
                post, publish, display, transmit or otherwise use (hereinafter,
                "Upload"). If you are not the beneficiary of the Campaign you
                organize, you agree to deliver funds to the ultimate beneficiary
                directly and as soon as possible. You agree to fully cooperate
                with any request we make for evidence we deem necessary to
                verify your compliance with these Terms of Service.
              </p>
              <p>
                The following are examples of User Content and/or use that is
                illegal or prohibited by KeyFaya Labs. This list is not
                exhaustive and we reserve the right to remove any Campaign
                and/or investigate any User who, in our sole discretion,
                violates any of the terms or spirit of these Terms of Service.
                As we investigate Your Campaign, a User, or User Content, we may
                consider all available material including but not limited to
                social media, related news, and any other information that we,
                in our sole discretion, deem relevant in our review. We further
                reserve the right, without limitation, to ban or disable your
                use of the Services, remove the offending User Content, suspend
                or terminate Your account, stop payments to any such Campaign,
                freeze or place a hold on Donations, and report you to law
                enforcement authorities or otherwise take appropriate legal
                action including seeking restitution on behalf of ourselves
                and/or our users.
              </p>
              <p>
                Without limiting the foregoing, you agree:
                <ul className="list-alphabetic">
                  <li>
                    Not to use the Services to raise funds or establish or
                    contribute to any Campaign with the implicit or explicit
                    purpose of or involving:
                    <ul className="list-numeric">
                      <li>
                        The violation of any law, regulation, industry
                        requirement, or third-party guidelines or agreements by
                        which you are bound, including those of payment card
                        providers and transaction processors that you utilize in
                        connection with the Services;
                      </li>

                      <li>
                        Any election campaigns that are not run by a registered
                        organization within the supported country;
                      </li>

                      <li>
                        User Content or campaigns that are fraudulent,
                        misleading, inaccurate, dishonest, or impossible;
                      </li>

                      <li>
                        Drugs, narcotics, steroids, controlled substances,
                        pharmaceuticals or similar products or therapies that
                        are either illegal, prohibited, or enjoined by an
                        applicable regulatory body; legal substances that
                        provide the same effect as an illegal drug; or other
                        products, medical practices, or any related equipment or
                        paraphernalia that have been found by an applicable
                        regulatory body to cause consumer harm;
                      </li>

                      <li>
                        Knives, explosives, ammunition, firearms, or other
                        weaponry or accessories;
                      </li>

                      <li>
                        Annuities, investments, loans, equity or lottery
                        contracts, lay-away systems, off-shore banking or
                        similar transactions, money service businesses
                        (including currency exchanges, check cashing or the
                        like), pyramid schemes, “get rich quick schemes” (i.e.,
                        Investment opportunities or other services that promise
                        high rewards), network marketing and referral marketing
                        programs, debt collection or crypto-currencies;
                      </li>

                      <li>
                        Gambling, gaming and/or any other activity with an entry
                        fee and a prize, including, but not limited to raffles,
                        casino games, sports betting, fantasy sports, horse or
                        greyhound racing, lottery tickets, raffle tickets,
                        auctions and other ventures that facilitate gambling,
                        games of skill or chance (whether or not it is legally
                        defined as a lottery), promotions involving monetary
                        rewards, including gift cards, or sweepstakes;
                      </li>

                      <li>
                        User Content or reflecting behavior that we deem, in our
                        sole discretion, to be an abuse of power or in support
                        of hate, violence, harassment, bullying, discrimination,
                        terrorism, or intolerance of any kind relating to race,
                        ethnicity, national origin, religious affiliation,
                        sexual orientation, sex, gender, gender identity, gender
                        expression, serious disabilities or diseases;
                      </li>

                      <li>
                        For the legal defense of alleged crimes associated with
                        hate, violence, harassment, bullying, discrimination,
                        terrorism, or intolerance of any kind relating to race,
                        ethnicity, national origin, religious affiliation,
                        sexual orientation, sex, gender, gender identity, gender
                        expression, serious disabilities or diseases, financial
                        crimes or crimes of deception;
                      </li>

                      <li>
                        Activities with, in, or involving countries, regions,
                        governments, persons, or entities that are subject to
                        U.S. and other economic sanctions under applicable law,
                        unless such activities are expressly authorized by the
                        appropriate governmental authority;
                      </li>

                      <li>
                        Funding a ransom, human trafficking or exploitation,
                        vigilantism, bribes or bounty;
                      </li>

                      <li>Pornography or other sexual content;</li>

                      <li>
                        Offensive, graphic, perverse or sensitive content;
                      </li>

                      <li>
                        The sale of items before the seller has control or
                        possession of the item;
                      </li>

                      <li>
                        Collecting payments on behalf of merchants by payment
                        processors or otherwise; including but not limited to
                        self-payments on campaigns or an attempt to bypass or
                        otherwise circumvent the designated method of payment as
                        provided by KeyFaya Labs;
                      </li>

                      <li>Credit repair or debt settlement services;</li>

                      <li>
                        The receipt or grant of cash advances or lines of credit
                        to yourself or to another person for purposes other than
                        those purposes clearly stated in the Campaign;
                      </li>

                      <li>
                        Publication or removal of User Content (such as mug
                        shots), where the primary purpose of posting such User
                        Content is to cause or raise concerns of reputational
                        harm;
                      </li>
                      <li>
                        Sale or resale of a service without added benefit to the
                        buyer; resale of government offerings without
                        authorization or added value;
                      </li>
                      <li>
                        Aggregation of funds owed to third parties, factoring,
                        or other activities intended to obfuscate the origin of
                        funds;
                      </li>
                      <li>
                        Counterfeit music, movies, software, or other licensed
                        materials without the appropriate authorization from the
                        rights holder;
                      </li>
                      <li>
                        Products or services that directly infringe or
                        facilitate infringement upon the trademark, patent,
                        copyright, trade secrets, or proprietary or privacy
                        rights of any third party;
                      </li>
                      <li>
                        Unauthorized sale or resale of brand name or designer
                        products or services;
                      </li>
                      <li>
                        Sale of goods or services that are illegally imported or
                        exported;
                      </li>
                      <li>
                        Processing where there is no bona fide donation
                        accepted; cash advance; card testing; evasion of card
                        network chargeback monitoring programs;
                      </li>
                      <li>
                        Collecting or providing funds for any purpose other than
                        as described in a Campaign description;
                      </li>
                      <li>
                        Any other activity that KeyFaya Labs may deem in its
                        sole discretion to be in support of individuals and/or
                        entities associated with alleged financial crimes
                        including but not limited to corruption, bribery, tax
                        evasion, fraud, and activities of a similar nature; or
                      </li>
                      <li>
                        Any other activity that KeyFaya Labs may deem in its
                        sole discretion to be unacceptable.
                      </li>
                    </ul>
                  </li>

                  <li>
                    Not to use the Services to transmit or otherwise upload any
                    User Content that (i) infringes any intellectual property or
                    other proprietary rights of any party; (ii) you do not have
                    a right to upload under any law or under contractual or
                    fiduciary relationships; (iii) contains software viruses or
                    any other computer code, files or programs designed to
                    interrupt, destroy or limit the functionality of any
                    computer software or hardware or telecommunications
                    equipment; (iv) poses or creates a privacy or security risk
                    to any person; (v) constitutes unsolicited or unauthorized
                    advertising, promotional materials, commercial activities
                    and/or sales, "junk mail," "spam," "chain letters," "pyramid
                    schemes," "contests," "sweepstakes," or any other form of
                    solicitation; or (vi) in the sole judgment of KeyFaya Labs,
                    is objectionable or which restricts or inhibits any other
                    person from using or enjoying the Services, or which may
                    expose KeyFaya Labs or its users to any harm or liability of
                    any type;
                  </li>
                  <li>
                    Not to interfere with or disrupt servers or networks
                    connected to the Services, or disobey any requirements,
                    procedures, policies or regulations of networks connected to
                    the Services;
                  </li>
                  <li>
                    Not to harvest, collect or publish personally identifiable
                    information of others;
                  </li>
                  <li>
                    Not to raise funds for a minor without the express
                    permission of the minor’s guardian unless the funds are
                    transferred into a trust account for the sole benefit of the
                    minor;
                  </li>
                  <li>
                    Not to use the Services on behalf of a third party or post
                    any personal data or other information about a third party,
                    without the express consent of that third party;
                  </li>
                  <li>
                    Not to use another User's account or URL without permission,
                    impersonate any person or entity, falsely state or otherwise
                    misrepresent your affiliation with a person or entity,
                    misrepresent a Charity or Campaign through the Services, or
                    post User Content in any inappropriate category or areas on
                    the Services;
                  </li>
                  <li>
                    Not create any liability for KeyFaya Labs or cause us to
                    lose (in whole or in part) the services of our Internet
                    Service Provider(s), web hosting company or any other
                    vendors or suppliers;
                  </li>
                  <li>
                    Not to engage in any conduct that, in KeyFaya Labs’s sole
                    judgment and discretion, restricts or inhibits any other
                    user from using or enjoying the Services;
                  </li>
                  <li>
                    Not to interfere with or disrupt any servers or networks
                    used to provide the Services or their respective features,
                    or disobey any requirements of the networks KeyFaya Labs
                    uses to provide the Services;
                  </li>
                  <li>
                    Not to gain unauthorized access to the Services, or any
                    account, computer system, or network connected to these
                    Services, by any unauthorized or illegal means;
                  </li>
                  <li>
                    Not to obtain or attempt to obtain any materials or
                    information not intentionally made available through the
                    Services;
                  </li>
                  <li>
                    Not to use the Services to post, transmit or in any way
                    exploit any information, software or other material for
                    commercial purposes, or that contains advertising, except
                    that using the Services for fundraising activities in
                    accordance with these Terms is expressly permitted;
                  </li>
                  <li>
                    Not to, on the KeyFaya Labs Platform, engage in advertising
                    or commercial solicitation of any product or service without
                    KeyFaya Labs’s written consent, except that using the
                    Services for fundraising activities in accordance with these
                    Terms is expressly permitted;
                  </li>
                  <li>
                    Transmit more request messages through the Services in a
                    given period of time than a human can reasonably produce in
                    the same period by using a conventional online web browser;
                  </li>
                  <li>
                    Undertake any activity or engage in any conduct that is
                    inconsistent with the business or purpose of the Services;
                    or
                  </li>
                  <li>Attempt to undertake indirectly any of the foregoing.</li>
                </ul>
              </p>

              <p>
                Additionally, with respect to all Donations you make or accept
                through the Services, you agree:
                <ul className="list-alphabetic">
                  <li>
                    Not to make or accept any Donations that you know or suspect
                    to be erroneous, suspicious or fraudulent;
                  </li>
                  <li>
                    Not to use the Services in or for the benefit of a country,
                    organization, entity, or person embargoed or blocked by any
                    government, including those on sanctions lists identified by
                    the United States Office of Foreign Asset Control (OFAC);
                  </li>
                  <li>
                    To maintain reasonable and standard security measures to
                    protect any information transmitted and received through the
                    Services, including without limitation by adhering to any
                    security procedures and controls required by KeyFaya Labs
                    from time to time;
                  </li>
                  <li>
                    To maintain a copy of all electronic and other records
                    related to Campaigns and Donations as necessary for KeyFaya
                    Labs to verify compliance with these Terms of Service and
                    make such records available to KeyFaya Labs upon our
                    request. For clarity, the foregoing does not affect or limit
                    your obligations to maintain documentation as required by
                    applicable laws, rules, regulations, or governmental
                    authority; and
                  </li>
                  <li>
                    At KeyFaya Labs’s request, including without limitation in
                    case of investigations by KeyFaya Labs, a payment processing
                    partner, or a regulatory or governmental authority, to fully
                    cooperate in the auditing of such records, investigation of
                    the relevant circumstances and remedy of any uncovered
                    violation or wrongdoing.
                  </li>
                </ul>
              </p>

              <p>
                KeyFaya Labs reserves the right to refuse, condition, or suspend
                any Donations or other transactions that we believe in our sole
                discretion may violate the Terms of Service or harm the
                interests of our users, business partners, the public, or
                KeyFaya Labs, or that expose you, KeyFaya Labs, or others to
                risks unacceptable to us. We may share any information related
                to your use of the Services with the appropriate financial
                institution, regulatory authority, or law enforcement agency
                consistent with our legal obligations. This information may
                include information about you, your account, your Donors, your
                Donations, and transactions made through or in connection with
                your use of the Services.
              </p>

              <h3 className="title-sub">Donor Conduct</h3>

              <h5>Donations:</h5>
              <p>
                In order to contribute to a Campaign or to a Charity, a Donor
                will be required to provide KeyFaya Labs information regarding
                its credit card or other payment instrument (“Payment
                Instrument”) that is linked to the Donor’s account on the
                Services (a “Billing Account”). You, as a Donor, represent and
                warrant to KeyFaya Labs that such information is true and that
                you are authorized to use the applicable Payment Instrument. You
                agree that a certain minimum Donation amount may apply, and that
                all Donations are final and will not be refunded unless KeyFaya
                Labs, in its sole discretion, agrees to issue a refund, for
                example in accordance with the KeyFaya Labs Guarantee. KeyFaya
                Labs uses third-party payment processing partners to bill you
                through your Payment Instrument and Billing Account for any
                Donations made, and Donors acknowledge that by contributing a
                Donation to a Campaign, the Donor agrees to the processing, use,
                transfer or disclosure of data by our Payment Processors
                pursuant to any and all applicable terms set forth by our
                payment partners (currently, Adyen LLC, Stripe, Inc., WePay,
                Inc., PayPal, Inc., and PayPal Giving Fund), in addition to
                these Terms of Service, including{" "}
                <a
                  target="_blank"
                  href="https://docs.adyen.com/legal/terms-conditions"
                >
                  Adyen’s terms of service,
                </a>{" "}
                <a target="_blank" href="https://stripe.com/us/legal">
                  Stripe’s terms of service,
                </a>{" "}
                <a
                  target="_blank"
                  href="https://go.wepay.com/terms-of-service#us"
                >
                  WePay’s terms of service,
                </a>{" "}
                <a
                  target="_blank"
                  href="https://www.paypal.com/ga/webapps/mpp/ua/legalhub-full"
                >
                  PayPal’s terms of service,
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.paypal.com/us/givingfund/policies"
                >
                  PayPal Giving Fund’s terms of service.
                </a>
              </p>

              <h5>Recurring Donations:</h5>
              <p>
                Donors may have the option to contribute recurring period
                Donations (your agreement to make the Donations on a recurring
                basis, a “Donation Subscription” and each individual Donation
                made in connection with a Donation Subscription, a “Donation
                Installment”), and in electing to contribute on a recurring
                basis, you, as a Donor hereby acknowledge that Donation
                Subscriptions automatically renew and have a recurring payment
                feature, and that unless and until you opt out of the
                auto-renewal of the Donation Subscription, which can be done
                through the Platforms, any Donation Subscriptions you have
                signed up for will be automatically extended for successive
                renewal periods of the same duration as the initial term
                originally selected. In connection with each of your Donation
                Subscriptions, you (i) hereby authorize KeyFaya Labs to bill
                your Payment Instrument in the amount of the applicable Donation
                Installments in advance on a periodic basis until you terminate
                such periodic payments by opting out of the Donation
                Subscription, (ii) accept responsibility for payment of all
                Donation Installments occurring prior to opt out and (iii) agree
                to promptly update your Billing Account with any changes (for
                example, any changes related to your Payment Instrument, such as
                a change in your billing address or credit card expiration
                date). Changes to or termination of Donation Subscriptions or
                Donation Installments will apply only to Donation Installments
                that take place after KeyFaya Labs receives notice of such
                change or termination. KeyFaya Labs does not provide refunds of
                any amounts received in connection with previously made Donation
                Installments. Additionally, by enrolling in any Donation
                Subscriptions, you acknowledge and agree for any and all such
                Donation Subscriptions, that (a) the ongoing maintenance and
                operation of Donation Subscriptions and each Donation
                Installment are the sole responsibility of, and subject to the
                sole discretion of, the individual or entity responsible for
                managing and receiving the Donation Subscription (e.g., the
                applicable Campaign Organizer or Charity), (b) individual
                Donation Installments may not be tax deductible, even if
                previous Donation Installments for the same Donation
                Subscription were, and the amount of each Donation Installment
                that is tax deductible may vary, (c) one or more specific
                Donation Installment may not be provided to or received by the
                applicable cause, charity or Campaign if such cause, charity or
                Campaign becomes unavailable, unable to accept Donations, or
                chooses to stop receiving Donations, which may occur for various
                reasons, such as if the cause, charity or Campaign becomes
                subject to an investigation or is suspended or removed from the
                Services by KeyFaya Labs or (d) the amounts actually received by
                the applicable Campaign, Charity or cause may differ from one
                Donation Installment to the next (for example, if the Payment
                Processer fees associated with the Donation Installment change).
              </p>

              <p>
                Your non-termination of a Donation Subscription reaffirms that
                KeyFaya Labs is authorized to charge your Payment Instrument for
                the Donation Subscription in accordance with these terms. This
                does not waive our right to seek payment directly from you.
              </p>

              <h3 className="title-sub">
                Campaign Organizers, Beneficiaries or Charities
              </h3>

              <h5>Account Holds:</h5>
              <p>
                From time to time, KeyFaya Labs may, in its sole discretion,
                place a hold on a Campaign account (a "Hold"), restricting
                Withdrawals (defined herein), initiate a reverse ACH transfer,
                secure reserves, or take similar actions to protect its
                interests and those of its Users. Some of the reasons that we
                may take such actions include, but aren’t necessarily limited
                to, the following: (i) if we have reason to believe (in our sole
                discretion) that information provided by a Campaign Organizer is
                false, misleading, or fraudulent, or that funds are being used
                in a prohibited manner, (ii) if the funds available (as
                determined by KeyFaya Labs in its sole discretion) should be
                provided directly to a person other than the Campaign Organizer
                (such as a legal beneficiary or person entitled by law to act on
                behalf of a Campaign Organizer), (iii) if we have reason to
                believe that a Campaign or Campaign Organizer has violated these
                Terms of Service, (iv) if KeyFaya Labs determines that the
                Campaign Organizer is colluding with donors to engage in
                fraudulent activity, (v) if we have reason to believe (in our
                sole discretion) that there may be suspicious or fraudulent
                donation activity, or (vi) if required in order to comply with a
                court order, subpoena, writ, injunction, or as otherwise
                required under applicable laws and regulations.
              </p>

              <h5>Withdrawing Donations from a Campaign:</h5>
              <p>
                While KeyFaya Labs strives to make Withdrawals available to you
                promptly, you acknowledge and agree that Withdrawals may not be
                available to you for use immediately, and KeyFaya Labs does not
                guarantee that Withdrawals will be available to you within any
                specific time frame, and KeyFaya Labs expressly disclaims any
                and all responsibility for any delay or inability to access and
                use Withdrawals at any specified time, and any consequences
                arising from such delay or inability. You, as a Campaign
                Organizer, are responsible for ensuring that the information you
                provide to KeyFaya Labs in order to process a Withdrawal,
                including your bank account information, is accurate and up to
                date. KeyFaya Labs may, at any time, for any reason, without
                notice, and in its sole discretion, offer or issue a refund of
                Donation(s) with or without consulting with you, which may
                comprise the entire amount donated to your campaign. KeyFaya
                Labs is not liable to you or to any third party for any claims,
                damages, costs, losses, or other consequences caused by KeyFaya
                Labs issuing refunds, including, but not limited to transaction
                or overdraft fees.
              </p>

              <h4>Payment Processors</h4>
              <p>
                KeyFaya Labs uses Payment Processors to process Donations for
                your Campaign and thereafter deliver it to you. In order to
                withdraw funds from a Campaign, a Campaign Organizer or, if not
                the same, Beneficiary (collectively “Withdrawing Entity”) will
                be required to provide the Payment Processor information
                regarding bank account information (“Withdrawing Account”). You,
                as Withdrawing Entity, represent and warrant to Payment
                Processor and KeyFaya Labs that such information is true and
                that you are authorized to use the applicable Withdrawing
                Account.
              </p>
              <p>
                By setting up a Campaign or accepting the role of Beneficiary to
                a Campaign, the Withdrawing Entity agrees to the processing,
                use, transfer or disclosure of data by the Payment Processors
                pursuant to any and all applicable terms set forth by the
                applicable Payment Processors (currently, Adyen LLC, Stripe,
                Inc., WePay, Inc., PayPal, Inc., and PayPal Giving Fund), in
                addition to these Terms of Service, including{" "}
                <a
                  target="_blank"
                  href="https://www.adyen.com/legal/terms-and-conditions"
                >
                  Adyen’s terms of service,
                </a>{" "}
                <a target="_blank" href="https://stripe.com/legal">
                  Stripe’s terms of service,
                </a>{" "}
                <a
                  target="_blank"
                  href="https://stripe.com/docs/connect/updating-accounts#adding-stripe-to-your-services-agreement"
                >
                  Stripe's Connected Account Agreement,
                </a>{" "}
                <a
                  target="_blank"
                  href="https://go.wepay.com/terms-of-service-us/#us"
                >
                  WePay’s terms of service,
                </a>{" "}
                <a
                  target="_blank"
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies"
                >
                  PayPal’s terms of service,
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="https://www.paypal.com/us/webapps/mpp/givingfund/policies"
                >
                  PayPal Giving Fund’s terms of service.
                </a>
              </p>

              <h3 className="title-sub">
                Special Notice for International Use; Export Controls
              </h3>
              <p>
                Software (defined below) available in connection with the
                Services and the transmission of applicable data, if any, may be
                subject to export controls and economic sanctions laws of the
                United States or other jurisdictions. No Software may be
                downloaded from the Services or otherwise exported or
                re-exported in violation of such export control and economic
                sanctions laws. Downloading or using the Software is at your
                sole risk. Recognizing the global nature of the Internet, you
                agree to comply with all local rules and laws regarding your use
                of the Services, including as it concerns online conduct and
                acceptable content.
              </p>

              <h3 className="title-sub">APPLE-ENABLED SOFTWARE APPLICATIONS</h3>
              <p>
                KeyFaya Labs offers Software applications that are intended to
                be operated in connection with products made commercially
                available by Apple Inc. ("Apple"), among other platforms. With
                respect to Software that is made available for your use in
                connection with an Apple-branded product (such Software,
                "Apple-Enabled Software"), in addition to the other terms and
                conditions set forth in these Terms of Service, the following
                terms and conditions apply:
                <ul>
                  <li>
                    KeyFaya Labs and you acknowledge that these Terms of Service
                    are concluded between KeyFaya Labs and you only, and not
                    with Apple, and that as between KeyFaya Labs and Apple,
                    KeyFaya Labs, not Apple, is solely responsible for the
                    Apple-Enabled Software and the content thereof.
                  </li>
                  <li>
                    You may not use the Apple-Enabled Software in any manner
                    that is in violation of or inconsistent with the Usage Rules
                    set forth for Apple-Enabled Software in, or otherwise be in
                    conflict with, the App Store Terms of Service.
                  </li>
                  <li>
                    Your license to use the Apple-Enabled Software is limited to
                    a non-transferable license to use the Apple-Enabled Software
                    on an iOS Product that you own or control, as permitted by
                    the Usage Rules set forth in the App Store Terms of Service.
                  </li>
                  <li>
                    Apple has no obligation whatsoever to provide any
                    maintenance or support services with respect to the
                    Apple-Enabled Software.
                  </li>
                  <li>
                    Apple is not responsible for any product warranties, whether
                    express or implied by law. In the event of any failure of
                    the Apple-Enabled Software to conform to any applicable
                    warranty, you may notify Apple, and Apple will refund the
                    purchase price for the Apple-Enabled Software to you, if
                    any; and, to the maximum extent permitted by applicable law,
                    Apple will have no other warranty obligation whatsoever with
                    respect to the Apple-Enabled Software, or any other claims,
                    losses, liabilities, damages, costs or expenses attributable
                    to any failure to conform to any warranty, which will be
                    KeyFaya Labs's sole responsibility, to the extent it cannot
                    be disclaimed under applicable law.
                  </li>
                  <li>
                    KeyFaya Labs and you acknowledge that KeyFaya Labs, not
                    Apple, is responsible for addressing any claims of you or
                    any third party relating to the Apple-Enabled Software or
                    your possession and/or use of that Apple-Enabled Software,
                    including, but not limited to: (i) product liability claims;
                    (ii) any claim that the Apple-Enabled Software fails to
                    conform to any applicable legal or regulatory requirement;
                    and (iii) claims arising under consumer protection or
                    similar legislation.
                  </li>
                  <li>
                    In the event of any third party claim that the Apple-Enabled
                    Software or the end-user's possession and use of that
                    Apple-Enabled Software infringes that third party's
                    intellectual property rights, as between KeyFaya Labs and
                    Apple, KeyFaya Labs, not Apple, will be solely responsible
                    for the investigation, defense, settlement and discharge of
                    any such intellectual property infringement claim.
                  </li>
                  <li>
                    You represent and warrant that (i) you are not located in a
                    country that is subject to a U.S. Government embargo, or
                    that has been designated by the U.S. Government as a
                    "terrorist supporting" country; (ii) you are not listed on
                    any U.S. Government list of prohibited or restricted parties
                    and (iii) you are not located in any other country or
                    jurisdiction from which you would be barred from using the
                    Services by applicable law.
                  </li>
                </ul>
              </p>

              <p>
                KeyFaya Labs and you acknowledge and agree that Apple, and
                Apple's subsidiaries, are third-party beneficiaries of these
                Terms of Service with respect to the Apple-Enabled Software, and
                that, upon your acceptance of the terms and conditions of these
                Terms of Service, Apple will have the right (and will be deemed
                to have accepted the right) to enforce these Terms of Service
                against you with respect to the Apple-Enabled Software as a
                third-party beneficiary thereof. Accordingly, the parties
                acknowledge and agree that KeyFaya Labs enters into this clause
                (“Apple-Enabled Software Applications”) for its own benefit and
                on its own behalf and also as an agent for the benefit and on
                behalf of Apple and its subsidiaries with respect to the
                exercise and enforcement of all rights, benefits and remedies of
                Apple and its subsidiaries (but not any obligation or burden) in
                this clause (“Apple-Enabled Software Applications”) which
                rights, benefits and remedies shall be enforceable by KeyFaya
                Labs in its own right and also as agent for and on behalf of
                each of Apple and its subsidiaries. KeyFaya Labs may amend,
                terminate or rescind these Terms of Service without the consent
                of Apple or any such subsidiary.
              </p>

              <h3 className="title-sub">INTELLECTUAL PROPERTY RIGHTS</h3>
              <h4>Services Content, Software and Trademarks:</h4>
              <p>
                You acknowledge and agree that the Services may contain content
                or features ("Services Content") that are protected by
                copyright, patent, trademark, trade secret or other proprietary
                rights and laws. Except as expressly authorized by KeyFaya Labs,
                you agree not to modify, copy, frame, scrape, rent, lease, loan,
                sell, distribute or create derivative works based on the
                Services, the Services Content, or Content, in whole or in part,
                except that the foregoing does not apply to your own User
                Content that you legally upload to the Services. In connection
                with your use of the Services you will not engage in or use any
                data mining, spiders, robots, scraping or similar data gathering
                or extraction methods. If you are blocked by KeyFaya Labs from
                accessing the Services (including by blocking your IP address),
                you agree not to implement any measures to circumvent such
                blocking (e.g., by masking your IP address or using a proxy IP
                address). Any use of the Services or the Services Content other
                than as specifically authorized herein is strictly prohibited.
                The technology and software underlying the Services or
                distributed in connection therewith are the property of KeyFaya
                Labs, our affiliates and our partners (the "Software"). You
                agree not to copy, modify, create a derivative work of, reverse
                engineer, reverse assemble or otherwise attempt to discover any
                source code, sell, assign, sublicense, or otherwise transfer any
                right in the Software. Any rights not expressly granted herein
                are reserved by KeyFaya Labs.
              </p>
              <p>
                The KeyFaya Labs name and logos are trademarks and service marks
                of KeyFaya Labs (collectively the "KeyFaya Labs Trademarks").
                Other company, product, and service names and logos used and
                displayed via the Services may be trademarks or service marks of
                their respective owners, who may or may not endorse or be
                affiliated with or connected to KeyFaya Labs. Nothing in these
                Terms of Service or the Services should be construed as
                granting, by implication, estoppel, or otherwise, any license or
                right to use any of KeyFaya Labs Trademarks displayed on the
                Services, without our prior written permission in each instance.
                All goodwill generated from the use of KeyFaya Labs Trademarks
                will inure to our exclusive benefit.
              </p>

              <h4>Third-Party Material:</h4>
              <p>
                Under no circumstances will KeyFaya Labs be liable in any way
                for any content or materials of any third parties (including
                Users) or any User Content (including, but not limited to, for
                any errors or omissions in any User Content), or for any loss or
                damage of any kind incurred as a result of the use of any such
                User Content. You acknowledge that KeyFaya Labs does not
                pre-screen User Content, but that KeyFaya Labs and its designees
                will have the right (but not the obligation) in their sole
                discretion to refuse, remove, or allow any User Content that is
                available via the Services at any time and for any reason, with
                or without notice, and without any liability to you or to any
                third party for any claims, damages, costs or losses resulting
                therefrom.
              </p>

              <h4>User Content Transmitted Through the Services:</h4>
              <p>
                With respect to the User Content, you represent and warrant that
                you own all right, title and interest in and to, or otherwise
                have all necessary rights and consents to (and to allow others
                to) fully exploit, such User Content, including, without
                limitation, as it concerns all copyrights, trademark rights and
                rights of publicity or privacy related thereto. By uploading,
                sharing, providing, or otherwise making available any User
                Content, or any portion thereof, in connection with the
                Services, you hereby grant and will grant KeyFaya Labs and its
                affiliated companies and users a nonexclusive, worldwide,
                royalty free, fully paid up, transferable, sublicensable,
                perpetual, irrevocable license to copy, display, upload,
                perform, distribute, store, modify and otherwise use your User
                Content in connection with the operation of the Services or the
                promotion, advertising or marketing thereof, in any form, medium
                or technology now known or later developed. Without limiting the
                foregoing, if any User Content contains your name, image or
                likeness, you hereby release and hold harmless KeyFaya Labs and
                its contractors and employees, from (i) all claims for invasion
                of privacy, publicity or libel, (ii) any liability or other
                claims by virtue of any blurring, distortion, alteration,
                optical illusion, or other use or exploitation of your name,
                image or likeness, and (iii) any liability for claims made by
                you (or any successor to any claim you might bring) in
                connection with your User Content, name, image or likeness. You
                waive any right to inspect or approve any intermediary
                version(s) or finished version(s) of the results of the use of
                your User Content (including your name, image or likeness).
                Further, if any person (other than you) appears in your User
                Content, you represent and warrant that you have secured all
                necessary licenses, waivers and releases from such person(s) for
                the benefit of KeyFaya Labs in a manner fully consistent with
                the licenses, waivers and releases set forth above. You further
                acknowledge that your participation in the Services and
                submission of User Content is voluntary and that you will not
                receive financial compensation of any type associated with the
                licenses, waivers, and releases set forth herein (or KeyFaya
                Labs's exploitation thereof), and that the sole consideration
                for subject matter of this agreement is the opportunity to use
                the Services.
              </p>
              <p>
                We do not guarantee that any Services Content will be made
                available through the Services. We reserve the right to, but do
                not have any obligation to, (i) remove, edit or modify any
                Services Content or User Content, in our sole discretion, at any
                time, without notice to you and for any reason (including, but
                not limited to, upon receipt of claims or allegations from third
                parties or authorities relating to such Services Content or User
                Content, or if we are concerned that you may have violated these
                Terms of Service), or for no reason at all and (ii) to remove or
                block any Services Content or User Content from the Services.
              </p>

              <h4>Payment Card Industry Data Security Standard:</h4>
              <p>
                The Payment Card Industry Data Security Standard (PCI DSS) is a
                set of industry-mandated requirements for any business that
                handles, processes, or stores credit cards. The primary purpose
                of the standards is to maintain controls around cardholder data
                to reduce credit card fraud. As a service provider, KeyFaya Labs
                is PCI DSS compliant and will maintain all applicable PCI DSS
                requirements to the extent that we possess or otherwise store,
                process, or transmit cardholder data on behalf of you, or to the
                extent that we can in any way impact the security of your
                cardholder data environment.
              </p>

              <h4>Counter-Notice:</h4>
              <p>
                If you believe that your User Content that was removed (or to
                which access was disabled) is not infringing, or that you have
                the authorization from the copyright owner, the copyright
                owner's agent, or pursuant to the law, to upload and use the
                content in your User Content, you may send a written
                counter-notice containing the following information to the
                above-listed Copyright Agent:
                <ul>
                  <li>your physical or electronic signature;</li>
                  <li>
                    identification of the content that has been removed or to
                    which access has been disabled and the location at which the
                    content appeared before it was removed or disabled;
                  </li>
                  <li>
                    a statement that you have a good-faith belief that the
                    content was removed or disabled as a result of mistake or a
                    misidentification of the content; and your name, address,
                    telephone number, and email address, and a statement that
                    you will accept service of process from the person who
                    provided notification of the alleged infringement.
                  </li>
                </ul>
              </p>

              <p>
                If a counter-notice is received by the Copyright Agent, KeyFaya
                Labs will send a copy of the counter-notice to the original
                complaining party, informing that person that it may replace the
                removed content or cease disabling it in 10 business days.
                Unless the copyright owner files an action seeking a court order
                against the content provider, member or user, the removed
                content may be replaced, or access to it restored, in 10 to 14
                business days or more after receipt of the counter-notice, at
                our sole discretion.
              </p>

              <h4>Repeat Infringer Policy:</h4>
              <p>
                In accordance with the DMCA and other applicable law, KeyFaya
                Labs has adopted a policy of terminating, in appropriate
                circumstances and at KeyFaya Labs's sole discretion, users who
                are deemed to be repeat infringers. KeyFaya Labs may also at its
                sole discretion limit access to or terminate the Services and/or
                terminate the memberships of any users who infringe any
                intellectual property rights of others, whether or not there is
                any repeat infringement.
              </p>

              <h3 className="title-sub">THIRD PARTY WEBSITES/SERVICES</h3>
              <p>
                The Services or third parties may provide or facilitate links,
                tools, widgets or other features that allow you to access other
                sites, services and resources provided by third parties
                (collectively, “Third Party Resources”). KeyFaya Labs has no
                control over such Third Party Resources or any products,
                services or content made available through or by such Third
                Party Resources, or the business practices of the third parties
                providing such Third Party Resources, and KeyFaya Labs is not
                responsible for and does not endorse such Third Party Resources
                or the products, services or content made available thereby. You
                acknowledge that KeyFaya Labs is not responsible or liable for
                the content, functions, accuracy, legality, appropriateness or
                any other aspect of such Third Party Resources. You further
                acknowledge and agree that KeyFaya Labs will not be responsible
                or liable, directly or indirectly, for any damage or loss caused
                or alleged to be caused by or in connection with the use of or
                reliance on any content, events, goods or services available on
                or through any such Third Party Resources. Any dealings you have
                with third parties found while using the Services are between
                you and the third party and may be subject to additional terms
                provided by the third party, which you agree to by using such
                Third Party Resources. As an example of this, if you use the
                KeyFaya Labs Platform through your mobile device, and you upload
                a video to a fundraiser, that video will be uploaded using
                YouTube, and subject to the YouTube{" "}
                <a
                  target="_blank"
                  href="https://www.youtube.com/static?gl=GB&template=terms"
                >
                  Terms of Service.
                </a>{" "}
                And you agree that KeyFaya Labs is not liable for any loss or
                claim that you may have against any such third party.
              </p>

              <h3 className="title-sub">INDEMNITY AND RELEASE</h3>
              <p>
                You agree to release, indemnify on demand and hold KeyFaya Labs
                and its affiliates and their officers, employees, directors and
                agents harmless from any and all losses, damages, expenses,
                including reasonable attorneys' fees, costs, awards, fines,
                damages, rights, claims, actions of any kind and injury
                (including death) arising out of or relating to your use of the
                Services, any Donation or Campaign, any User Content, your
                connection to the Services, your violation of these Terms of
                Service or your violation of any rights of another. You agree
                that KeyFaya Labs has the right to conduct its own defense of
                any claims at its own discretion, and that you will indemnify
                KeyFaya Labs for the costs of its defense (including, but not
                limited to attorney’s fees.) If you are a California resident,
                you waive California Civil Code Section 1542, which says: "A
                general release does not extend to claims that the creditor or
                releasing party does not know or suspect to exist in his or her
                favor at the time of executing the release, and that if known by
                him or her would have materially affected his or her settlement
                with the debtor or released party." If you are a resident of
                another jurisdiction—in or outside of the United States—you
                waive any comparable statute or doctrine.
              </p>

              <h3 className="title-sub">DISCLAIMER OF WARRANTIES</h3>
              <p>
                YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
                PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. KEYFAYA LABS
                AND ITS AFFILIATES EXPRESSLY DISCLAIM AND EXCLUDE, TO THE
                FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL WARRANTIES,
                CONDITIONS AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS,
                IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE AND NON-INFRINGEMENT.
              </p>

              <p>
                KEYFAYA LABS AND ITS AFFILIATES MAKE NO WARRANTY OR CONDITION
                THAT (I) THE SERVICES WILL MEET YOUR REQUIREMENTS, (II) THE
                SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
                (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                SERVICES WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF
                ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED
                OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR
                EXPECTATIONS.
              </p>

              <h3 className="title-sub">LIMITATION OF LIABILITY</h3>
              <p>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT
                PERMITTED BY APPLICABLE LAW, NEITHER KEYFAYA LABS NOR ITS
                AFFILIATES WILL BE LIABLE FOR ANY (A) INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, (B)
                DAMAGES FOR LOSS OF PROFITS, (C) DAMAGES FOR LOSS OF GOODWILL,
                (D) DAMAGES FOR LOSS OF USE, (E) LOSS OR CORRUPTION OF DATA, OR
                (F) OTHER INTANGIBLE LOSSES (EVEN IF KEYFAYA LABS HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON
                CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
                RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE SERVICES;
                (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
                PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
                ENTERED INTO THROUGH OR FROM THE SERVICES; (III) ANY PROMOTIONS
                AND RELATED PRIZES OR REWARDS MADE AVAILABLE THROUGH THE
                SERVICES; (IV) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS OR DATA; (V) STATEMENTS OR CONDUCT OF ANY THIRD
                PARTY ON THE SERVICES; OR (VI) ANY OTHER MATTER RELATING TO THE
                SERVICES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN
                NO EVENT WILL KEYFAYA LABS'S TOTAL LIABILITY TO YOU FOR ALL
                DAMAGES, LOSSES (INCLUDING CONTRACT, NEGLIGENCE, STATUTORY
                LIABILITY OR OTHERWISE) OR CAUSES OF ACTION EXCEED THE AMOUNT
                YOU HAVE PAID KEYFAYA LABS IN THE LAST SIX (6) MONTHS, OR, IF
                GREATER, ONE HUNDRED DOLLARS ($100).
              </p>
              <p>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE
                DISSATISFIED WITH ANY PORTION OF THE SERVICES OR WITH THESE
                TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                DISCONTINUE USE OF THE SERVICES.
              </p>

              <h3 className="title-sub">DISPUTES</h3>
              <p>
                ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE
                REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS
              </p>

              <h4>Arbitration; Class Action Waiver.</h4>
              <p>
                YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US OR ANY OF OUR
                OFFICERS, DIRECTORS OR EMPLOYEES ACTING IN THEIR CAPACITY AS
                SUCH (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH
                REGARD TO YOUR RELATIONSHIP WITH US, INCLUDING WITHOUT
                LIMITATION DISPUTES RELATED TO THESE TERMS OF SERVICE, YOUR USE
                OF THE SERVICES, AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL
                BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU AND WE
                HEREBY EXPRESSLY WAIVE TRIAL BY JURY. DISCOVERY AND RIGHTS TO
                APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A
                LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD HAVE IN COURT
                MAY NOT BE AVAILABLE IN ARBITRATION. YOU UNDERSTAND AND AGREE
                THAT, BY ENTERING INTO THESE TERMS, YOU AND WE ARE EACH WAIVING
                OUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
                ACTION.
              </p>
              <p>
                Notwithstanding the foregoing, nothing in these Terms of Service
                will be deemed to waive, preclude, or otherwise limit the right
                of either of us to (a) bring an individual action in small
                claims court; (b) pursue an enforcement action through the
                applicable federal, state, or local agency if that action is
                available; (c) seek injunctive relief in a court of law; or (d)
                file suit in a court of law to address an intellectual property
                infringement claim.
              </p>

              <h4>The Process.</h4>
              <p>
                Any arbitration between you and us will be settled under the
                Federal Arbitration Act and administered by the American
                Arbitration Association (“AAA”) under its Consumer Arbitration
                Rules (collectively, “AAA Rules”) as modified by these Terms of
                Service.
              </p>

              <p>
                The arbitrator may make rulings and resolve disputes as to the
                payment and reimbursement of fees or expenses at any time during
                the proceeding and upon request from either party made within 14
                days of the arbitrator’s ruling on the merits.
                <ul>
                  <li>
                    <h5>No Class Actions.</h5> YOU AND WE AGREE THAT EACH MAY
                    BRING CLAIMS TO THE FULLEST EXTENT LEGALLY PERMISSIBLE
                    AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                    CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you
                    and we agree otherwise, the arbitrator may not consolidate
                    more than one person’s claims and may not otherwise preside
                    over any form of a representative or class proceeding.
                  </li>
                  <li>
                    <h5>Modifications to this Arbitration Provision.</h5> If we
                    make any future change to this arbitration provision, other
                    than a change to our address for Notice of Arbitration, you
                    may reject the change by sending us written notice within 30
                    days of the change to our address for Notice of Arbitration,
                    in which case your account with us will be immediately
                    terminated and this arbitration provision, as in effect
                    immediately prior to the changes you rejected will survive.
                  </li>
                  <li>
                    <h5>Enforceability.</h5> If this Disputes section is found
                    to be unenforceable, then the entirety of this Disputes
                    section will be null and void and, in that case, the parties
                    agree that the exclusive jurisdiction and venue described in
                    the General section below will govern any action arising out
                    of or related to these Terms of Service.
                  </li>
                </ul>
              </p>

              <h4>Confidentiality.</h4>
              <p>
                We each agree to keep the arbitration proceedings, all
                information exchanged between us, and any settlement offers
                confidential, unless otherwise required by law or requested by
                law enforcement or any court or governmental body. However, we
                may each disclose these matters, in confidence, to our
                respective accountants, auditors, and insurance providers.
              </p>

              <h3 className="title-sub">TERMINATION</h3>
              <p>
                You agree that KeyFaya Labs, in its sole discretion, may suspend
                or terminate your account (or any part thereof) or your access
                to the Services and remove and discard any User Content or data
                at any time and for any reason, with or without notice, and
                without any liability to you or to any third party for any
                claims, damages, costs or losses resulting therefrom.
              </p>

              <h3 className="title-sub">USER DISPUTES</h3>
              <p>
                You agree that you are solely responsible for your interactions
                with any other user in connection with the Services and KeyFaya
                Labs will have no liability or responsibility with respect
                thereto. KeyFaya Labs reserves the right, but has no obligation,
                to become involved in any way with disputes between you and any
                other user of the Services.
              </p>

              <h3 className="title-sub">GENERAL</h3>
              <p>
                These Terms of Service constitute the entire agreement between
                you and KeyFaya Labs and govern your use of the Services,
                superseding any prior agreements between you and KeyFaya Labs
                with respect to the Services. You also may be subject to
                additional terms of service that may apply when you use
                affiliate or third-party services, third-party content or
                third-party software. These Terms of Service will be governed by
                the laws of the State of California without regard to its
                conflict of law provisions. With respect to any disputes or
                claims not subject to arbitration, as set forth above, you and
                KeyFaya Labs agree to submit to the personal and exclusive
                jurisdiction of the state and federal courts located within
                State of Michigan. The failure of KeyFaya Labs to exercise or
                enforce any right or provision of these Terms of Service will
                not constitute a waiver of such right or provision. If any
                provision of these Terms of Service is found by a court of
                competent jurisdiction to be (or are otherwise) invalid, the
                parties nevertheless agree that the court should endeavor to
                give effect to the parties’ intentions as reflected in the
                provision, and the other provisions of these Terms of Service
                remain in full force and effect. You agree that regardless of
                any statute or law to the contrary, any claim or cause of action
                arising out of or related to use of the Services or these Terms
                of Service must be filed within one (1) year after such claim or
                cause of action arose or be forever barred. A printed version of
                this agreement and of any notice given in electronic form will
                be admissible in judicial or administrative proceedings based
                upon or relating to this agreement to the same extent and
                subject to the same conditions as other business documents and
                records originally generated and maintained in printed form. You
                may not assign these Terms of Service without the prior written
                consent of KeyFaya Labs, but KeyFaya Labs may assign or transfer
                these Terms of Service, in whole or in part, without
                restriction. If we fail to enforce any of our rights, that does
                not result in a waiver of that right. The section titles in
                these Terms of Service are for convenience only and have no
                legal or contractual effect. Notices to you may be made via
                either email or regular mail. The Services may also provide
                notices to you of changes to these Terms of Service or other
                matters by displaying notices or links to notices generally on
                the Platforms. KeyFaya Labs may, at any time, assign our rights
                or delegate our obligations hereunder without notice to you in
                connection with a merger, acquisition, reorganization or sale of
                equity or assets, or by operation of law or otherwise. Nothing
                in these Terms shall prevent KeyFaya Labs from complying with
                the law. KeyFaya Labs shall not be liable for any delay or
                failure to perform resulting from causes outside its reasonable
                control, including, but not limited to, acts of God, war,
                terrorism, riots, embargos, acts of civil or military
                authorities, fire, floods, accidents, strikes or shortages of
                transportation facilities, fuel, energy, labor or materials.
              </p>

              <h3 className="title-sub">PRIVACY POLICY</h3>
              <p>
                At KeyFaya Labs, we respect the privacy of our users. For
                details please see our Privacy Policy. By using the Services,
                you consent to our collection and use of personal data as
                outlined therein. If you are in Europe, by using the Services,
                you acknowledge KeyFaya Labs's collection and use of personal
                information as described in the Privacy Policy.
              </p>
            </div>
            <div className="copyright-info-box">
              <span className="symbol">&#169;</span> All Rights Reserved, 2021
            </div>
          </div>
        </div>
      }
    </>
  );

  return content;
}

export default TermsAndCondition;
