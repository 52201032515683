import {Entity} from './entity';
import {documentSnapshotToUser} from './user';

export enum KhitmahType {
  SICK = 'SICK',
  DECEASED = 'DECEASED',
  MISSING = 'MISSING',
}

export enum KhitmahPrivacyType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum Status {
  CREATED = 'CREATED',
  REMOVED = 'REMOVED',
  COMPLETED = 'COMPLETED',
}

export enum KhitmahMemorialInfoLocationType {
  HOME = 'HOME',
  PUBLIC_AREA = 'PUBLIC_AREA',
  MASJID = 'MASJID',
}

export enum KhitmahFundRaiserInfoCauseType {
  SUPPORT_FOR_DECEASED_KIN = 'SUPPORT_FOR_DECEASED_KIN',
  FUNERAL_ARRANGEMENTS = 'FUNERAL_ARRANGEMENTS',
  TREATMENT_COST_FOR_SICK = 'TREATMENT_COST_FOR_SICK',
}

export interface KhitmahStats {
  juzzInfo: Map<string, JuzzInfo>;
  locationInfo: Map<string, Map<string, number>>;
  donationInfo: Map<string, Map<string, Map<string, number>>>;
  donorCount?: number;
  completedQuran?: number;
  completedJuzz?: number;
  claimedJuzz?: number;
}

export interface KhitmahFundRaiserInfo {
  receivedAmount: number;
  requestedAmount: number;
  cause: KhitmahFundRaiserInfoCauseType[];
  thankYouMessage: string;
}

export interface KhitmahMemorialInfo {
  locationType?: KhitmahMemorialInfoLocationType | null;
  location?: string | null;
  date?: Date | null;
  time?: Date | null;
  endTime?: Date | null;
}

export interface JuzzInfo {
  claimedCount: number;
  completedCount: number;
}

export interface Khitmah extends Entity {
  description: string;
  userId: string;
  user: any;
  joiningCode: string;
  friendImageUrl: string | null;
  type: KhitmahType;
  privacyType: KhitmahPrivacyType;
  friendName: string;
  endDate: Date;
  dateOfPassing: Date | null;
  ageOfDeceased: number | null;
  stats: KhitmahStats;
  fundRaiser?: KhitmahFundRaiserInfo | null;
  memorial?: KhitmahMemorialInfo | null;
  thankYouMessage: string;
  isEnded: boolean;
  isFundRaiserEnded: boolean;
  hasFundRaiser: boolean;
  status: Status;
  city?: string;
  country?: string;
  sponsorMsg?: string;
  phoneNumber: string;
}

export const documentSnapshotToKhitmah = (snap: any) => {
  const khitmah = snap.data() as Khitmah;

  if (snap.id === null) {
    throw new Error('khitmah snapshot document id not found');
  }
  khitmah.id = snap.id;

  return khitmah;
};

export const fetchKhitmahReferences = async (khitmah: Khitmah) => {
  const userSnap = await khitmah.user.get();
  const khitmahUpdated = {...khitmah};
  khitmahUpdated.user = documentSnapshotToUser(userSnap);

  return khitmahUpdated;
};
