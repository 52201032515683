import {setMixpanelUser} from '../../../Services/Mixpanel';
import auth from '../../../Firebase/auth';
import {getErrorMessageFromFirestoreError} from '../../../Utils/firestoreErrorMap';

export const loginUser = async (email: string, password: string) => {
  try {
    const credential = await auth().signInWithEmailAndPassword(email, password);
    const user = await auth().currentUser;
    // console.log(JSON.stringify(credential));
    setMixpanelUser(user.uid);
    return user;
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
