import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

/**
 *
 * @param {items} items to render
 * @param {component} component to render in each carousel
 *
 */
function MultiCarousel({ content }) {
  return (
    <Carousel
      responsive={responsive}
      // customButtonGroup={<ButtonGroup />}
      arrows={false}
      swipeable={false}
      draggable={false}
    >
      {content}
    </Carousel>
  );
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  //console.log("carousal props", rest);
  return (
    <div className="carousel-button-group">
      {" "}
      <button
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => previous()}
      >
        left btn
      </button>
      <button onClick={() => next()}>right btn</button>
    </div>
  );
};

export default MultiCarousel;
