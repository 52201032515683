import React from "react";
import { useTranslation } from "react-i18next";

import { AppRoutes } from "../../Constants/RouteConstants";
import LoginForm from "../../Components/LoginForm";
import LoginWithFacebook from "../../Components/FacebookLogin";
import GmailLogin from "../../Components/GmailLogin";
import ImageContentContainer from "../../Components/ImageContentContainer";
import AssetsConstants from "../../Constants/AssetsConstants";
import "./style.scss";

function Login(props) {
  const { t } = useTranslation();

  const redirectToDashboard = () => {
    props.history.replace(AppRoutes.DASHBOARD);
  };

  const redirectToForgotPassword = () => {
    props.history.push(AppRoutes.FORGOT_PASSWORD);
  };

  const redirectToSignUp = () => {
    props.history.push(AppRoutes.SIGNUP);
  };

  // const redirect = () => {
  //   redirectToDashboard();
  // };

  const content = (
    <>
      <div className="login-form-box">
        <div className="inner-box">
          <div className="logo-box">
            <img src={AssetsConstants.logo} alt="" />
          </div>

          <LoginForm redirect={redirectToDashboard} />
          <div className="forgot-pass-box">
            <a className="btn" onClick={redirectToForgotPassword}>
              {t("common.forgotPasswordOnly")}
            </a>
          </div>

          <div className="fb-gmail-login-box">
            <div className="title">{t("common.loginUsingYourAccount")}</div>
            <div className="btn-box">
              <LoginWithFacebook redirect={redirectToDashboard} />
              <GmailLogin redirect={redirectToDashboard} />
            </div>
          </div>

          <div className="do-have-account-box">
            {t("common.dontHaveAnAccount")}{" "}
            <a onClick={redirectToSignUp}>{t("common.signUp")}</a>
          </div>
        </div>
      </div>
    </>
  );

  const textBoxContent = (
    <div className="welcome-txt">
      <div className="inner-box">
        <h1 className="heading">
          {t("loginScreen.welcomeAndThanksForJoining")}{" "}
          <span>{t("loginScreen.khitmah")}</span> {t("loginScreen.with")}{" "}
          <span>{t("loginScreen.friends")}</span>
        </h1>
      </div>
    </div>
  );

  return (
    <ImageContentContainer
      imageSrc={AssetsConstants.loginImage}
      showTextBoxImage={true}
      content={content}
      textBoxContent={textBoxContent}
    />
  );
}

export default Login;
