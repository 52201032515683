import {INBOX_NOTIFICATIONS, MESSAGES} from '../../../Constants/database';
import firestore from '../../../Firebase/firestore';
import {getErrorMessageFromFirestoreError} from '../../../Utils/firestoreErrorMap';
import {documentSnapshotToInbox} from '../../../Models/inbox';

export const resetNotificationsReadCount = async (userId: string) => {
  try {
    const userInboxCollection = firestore().collection(INBOX_NOTIFICATIONS);
    await userInboxCollection.doc(userId).update({
      unread: 0,
      unseen: 0,
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const updateNotificationReadStatus = async (
  userId: string,
  messageId: string,
) => {
  try {
    const userInboxCollection = firestore()
      .collection(INBOX_NOTIFICATIONS)
      .doc(userId);
    await userInboxCollection.collection(MESSAGES).doc(messageId).update({
      isRead: true,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const markBulkNotificationsAsRead = async (
  userId: string,
  messageIds: string[],
) => {
  // Returning if there are no message ids to update
  if (messageIds.length === 0) return;
  try {
    const userInboxMessages = firestore()
      .collection(INBOX_NOTIFICATIONS)
      .doc(userId)
      .collection(MESSAGES);

    // Create a new batch instance
    const batch = firestore().batch();

    messageIds.map((item) => {
      const message = userInboxMessages.doc(item);
      batch.update(message, {
        isRead: true,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      });
    });

    await batch.commit();
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const updateNotificationReadCount = async (userId: string) => {
  let unread = 1;
  let unseen = 1;
  const userInboxCollection = firestore().collection(INBOX_NOTIFICATIONS);
  const userInboxDoc = userInboxCollection.doc(userId);
  const doc = await userInboxDoc.get();
  let inbox;
  try {
    if (!!doc.exists) {
      // If document exists, update unread,unseen count else set count to 1.
      inbox = documentSnapshotToInbox(doc);
      unread = inbox.unread + 1;
      unseen = inbox.unseen + 1;
    }
    await userInboxDoc
      .set(
        {
          unread,
          unseen,
        },
        {
          merge: true,
        },
      )
      .catch((error: any) => {
        throw new Error(getErrorMessageFromFirestoreError(error));
      });
  } catch (error) {
    throw new Error(error);
  }
};
