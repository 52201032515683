import React from "react";

function ButtonSecondary(props) {
  const { className, disabled, name, loading, buttonType } = props;
  return (
    <button
      type="button"
      {...props}
      className={`action-btn secondary-btn ${className} ${
        disabled && "btn-disabled"
      } ${loading && "btn-disabled btn-loading"}`}
      disabled={disabled}
    >
      {props.name}
    </button>
  );
}

export default ButtonSecondary;
