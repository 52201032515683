import {User} from './../../Models/user';
import {Entity} from '../../Models/entity';
import {KHITMAHS} from '../../Constants/database';
import firestore from '../../Firebase/firestore';
import {Khitmah, Status, KhitmahPrivacyType} from '../../Models/khitmah';

export const getKhitmahsWithFundRaiser = () => {
  return firestore()
    .collection(KHITMAHS)
    .where('status', '==', Status.CREATED)
    .where('privacyType', '==', KhitmahPrivacyType.PUBLIC)
    .where('hasFundRaiser', '==', true);
};

export const getKhitmahsWithFundRaiserOldToNew = () => {
  return getKhitmahsWithFundRaiser().orderBy('createdAt', 'asc');
};

export const getKhitmahsWithFundRaiserNewToOld = () => {
  return getKhitmahsWithFundRaiser().orderBy('createdAt', 'desc');
};

export const getKhitmahsWithFundRaiserNoOfParticipants = () => {
  return getKhitmahsWithFundRaiser().orderBy('stats.claimedJuzz', 'asc');
};

export const getKhitmahsWithFundRaiserWithCity = (city: string) => {
  return getKhitmahsWithFundRaiser()
    .where('city', '==', city)
    .orderBy('createdAt', 'desc');
};

export const getKhitmahsWithFundRaiserWithNotCity = (city: string) => {
  return getKhitmahsWithFundRaiser()
    .where('city', '!=', city)
    .orderBy('city', 'asc');
};

export const getKhitmahsOnly = () => {
  return firestore()
    .collection(KHITMAHS)
    .where('status', '==', Status.CREATED)
    .where('privacyType', '==', KhitmahPrivacyType.PUBLIC)
    .where('hasFundRaiser', '==', false);
};

export const getKhitmahsOnlyOldToNew = () => {
  return getKhitmahsOnly().orderBy('createdAt', 'asc');
};

export const getKhitmahsOnlyNewToOld = () => {
  return getKhitmahsOnly().orderBy('createdAt', 'desc');
};

export const getKhitmahsOnlyNoOfParticipants = () => {
  return getKhitmahsOnly().orderBy('stats.claimedJuzz', 'asc');
};

export const getKhitmahsOnlyWithCity = (city: string) => {
  return getKhitmahsOnly()
    .where('city', '==', city)
    .orderBy('createdAt', 'desc');
};

export const getKhitmahsOnlyWithNotCity = (city: string) => {
  return getKhitmahsOnly().where('city', '!=', city).orderBy('city', 'asc');
};

export const getPublicKhitmahs = () => {
  return firestore()
    .collection(KHITMAHS)
    .where('status', '==', Status.CREATED)
    .where('privacyType', '==', KhitmahPrivacyType.PUBLIC);
};

export const getPublicKhitmahsOldToNew = () => {
  return getPublicKhitmahs().orderBy('createdAt', 'asc');
};

export const getPublicKhitmahsNewToOld = () => {
  return getPublicKhitmahs().orderBy('createdAt', 'desc');
};

export const getPublicKhitmahsNoOfParticipants = () => {
  return getPublicKhitmahs().orderBy('stats.claimedJuzz', 'asc');
};

export const getPublicKhitmahsWithCity = (city: string) => {
  return getPublicKhitmahs()
    .where('city', '==', city)
    .orderBy('createdAt', 'desc');
};

export const getPublicKhitmahsWithNotCity = (city: string) => {
  return getPublicKhitmahs().where('city', '!=', city).orderBy('city', 'asc');
};

export const getSuggestedKhitmahs = (userId: string, limit: number = 5) => {
  return firestore()
    .collection(KHITMAHS)
    .where('status', '==', Status.CREATED)
    .where('privacyType', '==', KhitmahPrivacyType.PUBLIC)
    .where('userId', '!=', userId)
    .limit(limit);
};

export const getAllKhitmahs = () => {
  return firestore().collection(KHITMAHS).orderBy('createdAt');
};

export const getKhitmahsOfUser = (userId: string) => {
  return firestore()
    .collection(KHITMAHS)
    .where('userId', '==', userId)
    .orderBy('createdAt', 'desc');
};

export const getActiveKhitmahsOfUser = (userId: string, limit: number = 5) => {
  return firestore()
    .collection(KHITMAHS)
    .where('userId', '==', userId)
    .where('status', '==', Status.CREATED)
    .orderBy('createdAt', 'desc')
    .limit(limit);
};

// Comparators for sorting data
export const compareCreatedAtAscending = (e1: Entity, e2: Entity) => {
  if (e2.createdAt == null || e1.createdAt == null) {
    return 0;
  }
  return e1.createdAt.toMillis() - e2.createdAt.toMillis();
};

export const compareCreatedAtDescending = (e1: Entity, e2: Entity) => {
  if (e2.createdAt == null || e1.createdAt == null) {
    return 0;
  }
  return e2.createdAt.toMillis() - e1.createdAt.toMillis();
};

export const compareNoOfParticipants = (e1: Khitmah, e2: Khitmah) => {
  if (e2.stats?.claimedJuzz == null || e1.stats?.claimedJuzz == null) {
    return 0;
  }
  return e1.stats?.claimedJuzz - e2.stats?.claimedJuzz;
};

// This comparator matches the logged in user's country and then
// shows the results on top
export const compareRelevance = (user: User) => {
  return (e1: Khitmah, e2: Khitmah) => {
    if (e2.country == null || e1.country == null) {
      return 0;
    }
    if (e1.country === e2.country) {
      // Created At is only important when countries are the same
      return compareCreatedAtDescending(e1, e2);
    } else {
      const country = user?.country ? user.country : '';
      if (e1.country.startsWith(country) && e2.country.startsWith(country)) {
        return e1.country.localeCompare(e2.country);
      } else if (e1.country.startsWith(country)) {
        return -1;
      } else if (e2.country.startsWith(country)) {
        return 1;
      }

      return compareCreatedAtDescending(e1, e2);
    }
  };
};
