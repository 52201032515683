import React from "react";
import { FIELD_TYPES } from "../../../Constants/FormConstants";
import DatePicker from "./datePicker";
import TimePicker from "./timePicker";
import Picker from "./picker";
import Multipicker from "./multiSelectPicker";
import TextBox from "./textBox";
import TextInput from "./textInput";
import MultiSelectPicker from "./multiSelectPicker";
import RadioPicker from "./singleSelect";
import CheckBox from "./checkbox";
import NumericField from "./numeric";
import ButtonGroup from "./buttonGroup";
import AutoCompleteSearch from "./autoCompleteSearch";
import PhoneNumber from "./phoneNumber";

function FormInput(props) {
  const { type } = props;
  switch (type) {
    case FIELD_TYPES.PICKER:
      return <Picker {...props} />;
    case FIELD_TYPES.MULTIPICKER:
      return <Multipicker {...props} />;
    case FIELD_TYPES.DATE:
      return <DatePicker {...props} />;
    case FIELD_TYPES.TIME:
      return <TimePicker {...props} />;
    case FIELD_TYPES.TEXT_BOX:
      return <TextBox {...props} />;
    case FIELD_TYPES.SHEET_PICKER:
      return <MultiSelectPicker {...props} />;
    case FIELD_TYPES.RADIO_PICKER:
      return <RadioPicker {...props} />;
    case FIELD_TYPES.CHECKBOX:
      return <CheckBox {...props} />;
    case FIELD_TYPES.NUMBER:
      return <NumericField {...props} />;
    case FIELD_TYPES.BUTTON_GROUP:
      return <ButtonGroup {...props} />;
    case FIELD_TYPES.AUTO_COMPLETE_SEARCH:
      return <AutoCompleteSearch {...props} />;
    case FIELD_TYPES.PHONE_NUMBER:
      return <PhoneNumber {...props} />;
    default:
      return <TextInput {...props} />;
  }
}
export default FormInput;
