import firestore from '../../Firebase/firestore';
import {trackPledgeCompleted} from '../Mixpanel/trackEvents';
import {KHITMAH_PLEDGES} from '../../Constants/database';
import {getErrorMessageFromFirestoreError} from '../../Utils/firestoreErrorMap';
import {Status} from '../../Models/khitmahPledge';

export const updatePledgeStatus = async (pledgeStatus: Status, id: string) => {
  try {
    const khitmahPledges = firestore().collection(KHITMAH_PLEDGES);
    await khitmahPledges.doc(id).update({
      status: pledgeStatus,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    if (pledgeStatus == Status.COMPLETED) {
      //get pledge if status is completed for city and country and track event
      await khitmahPledges
        .doc(id)
        .get()
        .then((doc: any) => {
          if (doc.exists) {
            const khitmahPledge = doc.data();
            trackPledgeCompleted(
              khitmahPledge.userCountry,
              khitmahPledge.userCity,
            );
          }
        });
    }
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const bookmarkJuzzPage = async (
  pageNumber: number | null,
  id: string,
) => {
  try {
    const khitmahPledges = firestore().collection(KHITMAH_PLEDGES);
    await khitmahPledges.doc(id).update({
      bookmarkPage: pageNumber,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const removePledgeAndRemoveBookmark = async (id: string) => {
  try {
    const khitmahPledges = firestore().collection(KHITMAH_PLEDGES);
    await khitmahPledges.doc(id).update({
      status: Status.LEFT,
      bookmarkPage: null,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
