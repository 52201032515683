import React from "react";
import { AppRoutes } from "../Constants/RouteConstants";

export default [
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.home",
    to: "/",
    icon: <i className="icon-home"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.createKhitmah",
    to: "/createKhitmah",
    icon: <i className="icon-create-khitmah"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.joinKhitmah",
    to: "/join-a-khitmah",
    icon: <i className="icon-join-khitma"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.pledges",
    to: "/my-pledges",
    icon: <i className="icon-pledges"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.donation",
    to: "/donations",
    icon: <i className="icon-donation"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.myKhitmahs",
    to: "/my-khitmahs",
    icon: <i className="icon-mykhitma"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.profile",
    to: "/edit-profile",
    icon: <i className="icon-profile"></i>,
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Payment Info",
  //   to: "/",
  //   icon: <i className="icon-payment-info"></i>,
  // },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.aboutKhitmah",
    to: "/about-khitmah",
    icon: <i className="icon-about-khitmah"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.tutorial",
    to: "/tutorials",
    icon: <i className="icon-tutorial"></i>,
  },
  {
    _tag: "CSidebarNavItem",
    name: "navigationItem.payItForward",
    to: "/pay-it-forward",
    icon: <i className="icon-donate"></i>,
  },
];
