export const USER_ACTIONS = {
  SET_LOGGEDIN_USER: "SET_LOGGEDIN_USER",
  SET_FETCHING_USER: "SET_FETCHING_USER",
  SET_USER_LOCATION: "SET_USER_LOCATION",
  SET_USER_AUTH_TYPE: "SET_USER_AUTH_TYPE",
};

export const LANGUAGE_ACTIONS = {
  SET_CURRENT_LANGUAGE: "SET_CURRENT_LANGUAGE",
  SET_RTL_MODE: "SET_RTL_MODE",
};
