import React from "react";
import FundRaiserStats from "../../Components/Common/FundRaiserStats";
import JuzzCompletionStats from "../../Components/Common/JuzzCompletionStats";
import KWHProgressBar from "../../Components/Common/KWHProgressBar";
import KWHAvatarCell from "../../Components/Common/Table/Avatar";
import TableTextCell from "../../Components/Common/Table/Text";
import "./style.scss";
import {
  FundraiserProgressBarConfig,
  getStatusOfKhitmah,
  KhitmahProgressBarConfig,
} from "../../Helper/KhitmahList";
import { dateFormatted2 } from "@khitmah/shared/src/Utils/utils";
import RenderText from "../../Components/Common/RenderText";
import { getStatusColor, getStatusText } from "../../Helper/PledgesList";

export const MyActivePledgesListColumns = [
  {
    name: () => RenderText("khitmah.khitmahName"),
    type: "visible", // hidden
    id: "friendName",
    columnWidth: "25%",
    component: ({ data }) => (
      <>
        <KWHAvatarCell
          name={data["friendName"]}
          imageUrl={data["friendImageUrl"]}
        />
      </>
    ),
  },
  {
    name: () => RenderText("khitmah.juzzNumber"),
    type: "visible",
    id: "juzzNumber",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className="khitmah-count-box">
        <TableTextCell text={data["juzzNumber"]} />
      </div>
    ),
  },
  {
    name: () => RenderText("common.date"),
    type: "visible",
    id: "createdAt",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className="khitmah-count-box">
        <TableTextCell text={dateFormatted2(data["updatedAt"].toDate())} />
      </div>
    ),
  },
  {
    name: () => null,
    type: "visible",
    id: "status",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className={`status-box ${getStatusColor(data["status"])}`}>
        <TableTextCell text={getStatusText(data["status"])} />
      </div>
    ),
  },
];

export const MyPastPledgesListColumns = [
  {
    name: () => RenderText("khitmah.khitmahName"),
    type: "visible", // hidden
    id: "friendName",
    columnWidth: "25%",
    component: ({ data }) => (
      <>
        <KWHAvatarCell
          name={data["friendName"]}
          imageUrl={data["friendImageUrl"]}
        />
      </>
    ),
  },
  {
    name: () => RenderText("khitmah.juzzNumber"),
    type: "visible",
    id: "juzzNumber",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className="khitmah-count-box">
        <TableTextCell text={data["juzzNumber"]} />
      </div>
    ),
  },
  {
    name: () => RenderText("common.date"),
    type: "visible",
    id: "createdAt",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className="khitmah-count-box">
        <TableTextCell text={dateFormatted2(data["updatedAt"].toDate())} />
      </div>
    ),
  },
  {
    name: () => RenderText("khitmah.khitmahStatus"),
    type: "visible",
    id: "status",
    columnWidth: "25%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className={`status-box ${getStatusColor(data["status"])}`}>
        <TableTextCell text={getStatusText(data["status"])} />
      </div>
    ),
  },
];
