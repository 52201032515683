import React, { useState } from "react";
import ImageContentContainer from "../../Components/ImageContentContainer";
import AssetsConstants from "../../Constants/AssetsConstants";
import SignUpForm from "../../Components/SignUpForm";
import { AppRoutes } from "../../Constants/RouteConstants";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Button from "../../Components/Common/Button";
import TermsAndCondition from "../TermsAndCondition";
import { withModal } from "../../Components/Common/Modal";

function SignUp(props) {
  const { t } = useTranslation();
  const { history } = props;

  const goBack = () => {
    props.showModalWithOk(
      t("signUpScreen.signupSuccessful"),
      t("loginScreen.verifyYourEmailText"),
      () => {
        history.goBack();
      }
    );
  };

  const SignUpContent = () => (
    <>
      <div className="top-head">
        <a className="back-btn" onClick={history.goBack}>
          <i className="icon-left-arrow"></i>
        </a>
        <h4 className="heading">{t("common.signUp")}</h4>
      </div>
      <SignUpForm navigateToLogin={goBack} />
    </>
  );

  const content = (
    <>
      <div className="signup-form-box">
        <div className="inner-box">
          <SignUpContent />
        </div>
      </div>
    </>
  );

  return (
    <ImageContentContainer
      imageSrc={AssetsConstants.signupImg}
      content={content}
    />
  );
}

export default withModal(SignUp);
