import firestore from '../../Firebase/firestore';
import messaging from '../../Firebase/messaging';
import {USERS} from '../../Constants/database';
import {getErrorMessageFromFirestoreError} from '../../Utils/firestoreErrorMap';

export const updatePushTokenUser = async (
  userId: string,
  oldToken?: string,
) => {
  const fcmToken = await messaging().getToken();
  // If old and new token are same then no need to update it
  if (oldToken === fcmToken) return;
  // console.log(fcmToken);
  firestore()
    .collection(USERS)
    .doc(userId)
    .update({
      deviceToken: fcmToken,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    })
    .catch((error) => {
      throw new Error(getErrorMessageFromFirestoreError(error));
    });
};
