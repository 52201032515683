import { useTranslation } from "react-i18next";

export const PayPalButtonStyle = {
  color: "silver",
  shape: "pill",
  size: "medium",
  tagline: false,
  label: "paypal",
};

export const Currency = "USD";

export const BraintreeTranslations = () => {
  const { t } = useTranslation();

  return {
    // expirationDateLabel: t("common.home"),
    Card: "Credit/Debit card",
    chooseAWayToPay: t("donation.chooseAPaymentMethod"),
  };
};
