import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "./style.scss";

/**
 * REFERNCE: https://www.npmjs.com/package/react-circular-progressbar
 */

//BMProgressBar Default Config
const DEFAULT_CONFIG = {
  // size: 150,
  text: 0,
  percentage: 0,
  val: 0,
  // style: {
  //   path: { stroke: `blue` },
  //   text: { fill: "black", fontSize: "30px" },
  //   trail: {
  //     // Trail color
  //     stroke: "yellow",
  //   },
  // },
};

function KWHProgressBar(props) {
  const { percentage, val, size, style } = props.config || {};
  let value = val;

  // Progress bar text should not exceed 100%.
  if (value > 100) {
    value = 100;
  }
  return (
    <div className="circle-progress-chart">
      <CircularProgressbar
        value={percentage || DEFAULT_CONFIG.percentage}
        text={`${value || DEFAULT_CONFIG.val}%`}
        styles={style || DEFAULT_CONFIG.style}
        maxValue={100}
      />
    </div>
  );
}

export default KWHProgressBar;
