import {USERS, PAY_AS_FORWARD} from '../../../Constants/database';
import firestore from '../../../Firebase/firestore';

export const getAllPayments = (userId: string) => {
  return firestore()
    .collection(USERS)
    .doc(userId)
    .collection(PAY_AS_FORWARD)
    .orderBy('createdAt', 'desc');
};
