import auth from '../../../Firebase/auth';
import {getErrorMessageFromFirestoreError} from '../../../Utils/firestoreErrorMap';

export const logoutUser = async () => {
  try {
    await auth().signOut();
    // console.log('user signed out')
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
