import React, { useEffect, useState } from "react";
import { withHeader } from "../../Components/Header";
import { useLocation } from "react-router-dom";
import { readJuzz } from "@khitmah/shared/src/Services/ReadQuran/readJuzz";
import { QURAN_TRANSLATION } from "@khitmah/shared/src/Constants/appConstants";
import PageVerses from "../../Components/PageVerses";
import { bookmarkJuzzPage } from "@khitmah/shared/src/Services/KhitmahPledge/updatePledge";
import Loader from "../../Components/Common/Loader";
import "./style.scss";
import { useTranslation } from "react-i18next";
import JuzStatusDropDown from "./juzStatusDropdown";
import LanguageDropdown from "./LanguageDropdown";
import { ReadJuzContext } from "../../Hooks/useReadJuz";
import ButtonPrimary from "../../Components/ButtonPrimary";

const ReadJuzzWithContext = (props) => {
  const { t } = useTranslation();

  const {
    juzzPageDetails,
    juzzNumber,
    loading,
    currentPage,
    language,
    loadNextPage,
    loadPrevPage,
    toggleBookmark,
    isBookmarked,
    isCompleted,
    sponsorMsg,
  } = ReadJuzContext();

  const [showSponsorMsg, setShowSponsorMsg] = useState(false);

  useEffect(() => {
    sponsorMsg ? setShowSponsorMsg(true) : setShowSponsorMsg(false);
  }, [sponsorMsg]);

  return (
    <div className="juzz-reading-screen generic-inner-sec">
      <Loader showLoader={loading} />

      <div className="top-box">
        <div className="quran-status-box">
          <div className="name">
            {t(`juzzInfo.juzz${juzzNumber}NameArabic`)}
          </div>
          <span>{`${t(`juzzInfo.juzz${juzzNumber}Name`)} (${t(
            `juzzInfo.juzz${juzzNumber}Verses`
          )})`}</span>
        </div>

        <div className="book-mark-box">
          <LanguageDropdown />
          {!isCompleted && <JuzStatusDropDown />}
          <button
            className={`bookmark-btn ${isBookmarked ? "marked" : ""}`}
            onClick={toggleBookmark}
          >
            <i className="icon"></i>
            <span>{t("common.bookmark")}</span>
          </button>
        </div>
      </div>
      {showSponsorMsg && sponsorMsg ? (
        <>
          <div className="sponsor-msg-box">
            <div className="sponsor-msg">
              <p>{`"${sponsorMsg}"`}</p>
            </div>
            <div className="sponsor-continue-reading">
              <ButtonPrimary
                name={t("khitmah.continueReading")}
                onClick={() => setShowSponsorMsg(false)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="main-quran-box">
            <PageVerses
              pageVerses={juzzPageDetails?.pageVerses}
              language={language}
              page={currentPage}
            />
          </div>
          <div className="pagination-box">
            <button
              className="next-btn"
              disabled={currentPage === juzzPageDetails?.juzzLastPage}
              onClick={loadNextPage}
            >
              <i className="icon-arrow-left2"></i> {t("common.next")}
            </button>
            <button
              className="prev-btn"
              disabled={currentPage === juzzPageDetails?.juzzFirstPage}
              onClick={loadPrevPage}
            >
              {t("common.previous")} <i className="icon-arrow-right2"></i>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReadJuzzWithContext;
