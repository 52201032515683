import { Status } from "@khitmah/shared/src/Models/khitmah";
import { calculatePercentage } from "@khitmah/shared/src/Utils/utils";
import RenderText from "../Components/Common/RenderText";

export const KhitmahListModel = (khitmahs) => ({
  khitmahs: khitmahs.map((k) => formatKhitmahDetail(k)),
});

const formatKhitmahDetail = (khitmah) => ({
  ...khitmah,
  friendName: khitmah.friendName,
  completedCount: khitmah.stats.completedCount || 0,
  progress: khitmah.progress || "",
  fundRaiser: khitmah.fundRaiser || "",
  status: khitmah.status,
  claimedJuzz: khitmah.stats.claimedJuzz,
  completedJuzz: khitmah.stats.completedJuzz,
  hasFundRaiser: khitmah.hasFundRaiser,
  endDate: khitmah.endDate?.toDate(),
  joiningCode: khitmah.joiningCode,
});

export const KhitmahProgressBarConfig = (stats) => {
  let percentage = calculatePercentage(
    stats?.completedJuzz,
    stats?.claimedJuzz
  );

  return {
    percentage,
    val: percentage,
  };
};

export const FundraiserProgressBarConfig = (fundRaiser) => {
  let percentage = calculatePercentage(
    fundRaiser?.receivedAmount,
    fundRaiser?.requestedAmount
  );
  return {
    percentage,
    val: percentage,
  };
};

export const getStatusOfKhitmah = (status) => {
  let statusStr = "";
  if (status === Status.COMPLETED) {
    statusStr = RenderText("khitmah.completed");
  } else if (status === Status.CREATED) {
    statusStr = RenderText("khitmah.active");
  } else if (status === Status.REMOVED) {
    statusStr = RenderText("khitmah.removed");
  }

  return statusStr;
};
