import React from "react";
import { useTranslation } from "react-i18next";

const isNumber = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const RadioButtonGroup = (props) => {
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    options,
    value,
    onChange,
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <div className="form-control-radio-group">
        {options.map((option) => {
          return (
            <>
              <div className="form-control-radio">
                <input
                  {...props}
                  id={option.value.toString()}
                  value={option.value}
                  checked={value == option.value}
                  name="type"
                  type="radio"
                  onChange={({ target }) => {
                    if (isNumber(target.value)) {
                      onChange(Number(target.value));
                    } else onChange(target.value);
                  }}
                />
                <span class="checkmark"></span>
                <label htmlFor={option.value}>{t(option.label)}</label>
              </div>
            </>
          );
        })}
      </div>
      {errors[field.name] && touched[field.name] && (
        <div class="invalid-feedback">{t(errors[field.name])}</div>
      )}
    </>
  );
};

export default RadioButtonGroup;
