export const MIXPANEL_EVENTS = {
  USER_SIGNUP: 'user_signup',
  KHITMAH_CREATED: 'khitmah_created',
  PLEDGE_CLAIMED: 'pledge_claimed',
  PLEDGE_COMPLETED: 'pledge_completed',
  FUND_RAISED: 'fund_raised',
  PAY_IT_FORWARD: 'pay_it_forward',
};

export const MIXPANEL_PROPERTIES = {
  CREATED_DATE: 'created_date',
  TYPE: 'type',
  COUNTRY: 'country',
  CITY: 'city',
  KHITMAH_NAME: 'khitmah_name',
  FUND_RAISER_TYPE: 'fundraiser_type',
  AMOUNT_RAISED: 'amount_raised',
  GOAL: 'requested_amount',
  KHITMAH_OWNER_NAME: 'owner_name',
  PAY_IT_FORWARD_DONOR_NAME: 'donor_name',
  PAY_IT_FORWARD_AMOUNT: 'donation_amount',
};
