import {HTTP_FETCH_METHODS} from '../../Constants/urlConstants';

/**
 * This service is intended to fetch client token that will be
 * used for initialiazing payment gateway (BRAINTREE in our case)
 *
 * @param url -> Dynamic url based on the environment
 *
 */
export const getClientToken = async (url: string) => {
  try {
    console.log('Url', url);
    const response = await fetch(url, {
      method: HTTP_FETCH_METHODS.GET,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    if (result?.data) {
      const {token} = result.data;
      console.log('client token', token);
      return token;
    }
    return null;
  } catch (error) {
    throw new Error(error);
  }
};
