import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LANGUAGES } from "@khitmah/shared/src/Constants/appConstants";

import en from "@khitmah/shared/src/Meta/Languages/en.json";
import ar from "@khitmah/shared/src/Meta/Languages/ar.json";
import fr from "@khitmah/shared/src/Meta/Languages/fr.json";
import es from "@khitmah/shared/src/Meta/Languages/es.json";
import ur from "@khitmah/shared/src/Meta/Languages/ur.json";
import sv from "@khitmah/shared/src/Meta/Languages/sv.json";
import zh from "@khitmah/shared/src/Meta/Languages/zh.json";
import de from "@khitmah/shared/src/Meta/Languages/de.json";
import id from "@khitmah/shared/src/Meta/Languages/id.json";
import ms from "@khitmah/shared/src/Meta/Languages/ms.json";
import tr from "@khitmah/shared/src/Meta/Languages/tr.json";
import fa from "@khitmah/shared/src/Meta/Languages/fa.json";
import it from "@khitmah/shared/src/Meta/Languages/it.json";
import ru from "@khitmah/shared/src/Meta/Languages/ru.json";

const defaultLanguageType = navigator.language.split(/[-_]/)[0];

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en,
      },
      ar: {
        translation: ar,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      ur: {
        translation: ur,
      },
      sv: {
        translation: sv,
      },
      zh: {
        translation: zh,
      },
      de: {
        translation: de,
      },
      id: {
        translation: id,
      },
      ms: {
        translation: ms,
      },
      tr: {
        translation: tr,
      },
      fa: {
        translation: fa,
      },
      it: {
        translation: it,
      },
      ru: {
        translation: ru,
      },
    },
    fallbackLng: LANGUAGES.ENGLISH,
    lng: defaultLanguageType,
    debug: false,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
