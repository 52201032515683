export const KHITMAH_INVITE_CODE_LENGTH = 6;

export const QURAN_TRANSLATION = {
  NO_TRANSLATION: 'no-translation',
  ENGLISH: 'en',
  FARSI: 'fa',
  ARABIC: 'ar',
};

export const IMAGES_DIRECTORY = '/images';

// Arabic, German, English, Spanish, Farsi, French, Indonesian, Italian, Malay, Portuguese, Russian, Swedish, Turkish, Urdu, Chinese
export const LANGUAGES = {
  ARABIC: 'ar',
  GERMAN: 'de',
  ENGLISH: 'en',
  SPANISH: 'es',
  FARSI: 'fa',
  FRENCH: 'fr',
  INDONESIAN: 'id',
  ITALIAN: 'it',
  // MALAY: 'ms',
  // PORTUGUESE: 'pt',
  RUSSIAN: 'ru',
  SWEDISH: 'sv',
  TURKISH: 'tr',
  URDU: 'ur',
  CHINESE: 'zh',
};
// Arabic, Farsi, Urdu
export const RTL_LANGUAGES = [
  LANGUAGES.ARABIC,
  LANGUAGES.FARSI,
  LANGUAGES.URDU,
];

export const ARABIC_NUMBERS = [
  '٠',
  '١',
  '٢',
  '٣',
  '٤',
  '٥',
  '٦',
  '٧',
  '٨',
  '٩',
];

export const KHITMAH_NAME_PLACEHOLDER = '{KHITMAH_NAME}';
