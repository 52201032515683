import React, { useState } from "react";
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import KWHTable from "../../Components/Common/Table";
import Loader from "../../Components/Common/Loader";
import { withHeader } from "../../Components/Header";
import {
  MyActivePledgesListColumns,
  MyPastPledgesListColumns,
} from "./tableConfig.jsx";
import { useHistory } from "react-router-dom";

import { useMyActivePledges, useMyPastPledges } from "../../Hooks/useMyPledges";
import { AppRoutes } from "../../Constants/RouteConstants";

function MyPledges(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("activePledges");
  const { activePledgesLoading, activePledges } = useMyActivePledges();
  const { pastPledgesLoading, pastPledges } = useMyPastPledges();

  const moveToReadQuran = (item) => {
    history.push(AppRoutes.READ_JUZZ, {
      juzzNumber: item.juzzNumber,
      id: item.id,
      bookmarkPage: item.bookmarkPage,
    });
  };

  return (
    <>
      <Loader showLoader={activePledgesLoading || pastPledgesLoading} />
      <div className="generic-inner-sec">
        <CTabs
          activeTab={activeTab}
          onActiveTabChange={(tab) => {
            setActiveTab(tab);
          }}
        >
          <div className="d-flex justify-content-between">
            <CNav variant="tabs">
              <CNavItem>
                <CNavLink data-tab="activePledges">
                  {t("khitmah.activePledges")}
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink data-tab="pastPledges">
                  {t("khitmah.pastPledges")}
                </CNavLink>
              </CNavItem>
            </CNav>
          </div>
          <CTabContent>
            <CTabPane data-tab="activePledges">
              <div className="my-pledge-listing">
                <KWHTable
                  columns={MyActivePledgesListColumns}
                  tableData={activePledges}
                  handleRowClick={moveToReadQuran}
                />
              </div>
            </CTabPane>
            <CTabPane data-tab="pastPledges">
              <div className="my-pledge-listing">
                <KWHTable
                  columns={MyPastPledgesListColumns}
                  tableData={pastPledges}
                  handleRowClick={null}
                />
              </div>
            </CTabPane>
          </CTabContent>
        </CTabs>
      </div>
    </>
  );
}

export default withHeader(MyPledges);
