import React from "react";

function Loader(props) {
  const { showLoader = false } = props;
  return showLoader ? (
    <div className="page-loader">
      <div className="inner-box">
        <div className="contener_general">
          <div className="contener_mixte">
            <div className="ballcolor ball_1">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_2">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_3">&nbsp;</div>
          </div>
          <div className="contener_mixte">
            <div className="ballcolor ball_4">&nbsp;</div>
          </div>
        </div>
        <span className="text">Loading</span>
      </div>
    </div>
  ) : null;
}

export default Loader;
