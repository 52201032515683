import {createUser} from '../createUser';
import auth from '../../../Firebase/auth';
import {getErrorMessageFromFirestoreError} from '../../../Utils/firestoreErrorMap';

export const signUpUser = async (
  fullName: string,
  email: string,
  password: string,
  city: string,
  country: string,
  age: string,
  language: string,
) => {
  try {
    const credential = await auth().createUserWithEmailAndPassword(
      email,
      password,
    );
    // console.log('email signup', JSON.stringify(credential));

    credential.user.sendEmailVerification().then(() => {
      createUser(
        fullName,
        email,
        '',
        credential.user.uid,
        language,
        true,
        city,
        country,
        age,
      );
      return credential.user.uid;
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
