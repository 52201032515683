import "./style.scss";

import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import React, { useCallback, useState } from "react";
import RenderText from "../RenderText";
import { useTranslation } from "react-i18next";

function KWHModal(props) {
  const { t } = useTranslation();
  const {
    onClose,
    onOkClick,
    visible,
    modalBody,
    modalTitle,
    btnYesText,
    btnNoText,
  } = props;
  return (
    <CModal show={visible} closeOnBackdrop={false}>
      <CModalHeader>{modalTitle}</CModalHeader>
      <CModalBody>{modalBody}</CModalBody>
      <CModalFooter>
        <CButton className="primary-btn" color="primary" onClick={onOkClick}>
          {btnYesText || t("common.ok")}
        </CButton>
        {onClose && (
          <CButton
            className="secondary-btn"
            color="secondary"
            onClick={onClose}
          >
            {btnNoText || t("common.cancel")}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
}

export default KWHModal;

export const withModal = (child) => {
  return (props) => {
    const [state, setState] = useState({
      onClose: null,
      onOkClick: (x) => x,
      visible: false,
      modalBody: "",
      modalTitle: "",
      btnYesText: "",
      btnNoText: "",
      isYesNo: false,
    });

    const handleOnYes = useCallback(() => {
      setState({ ...state, visible: false });
      if (typeof state.onOkClick === "function") state.onOkClick();
    }, [state]);

    const handleOnNo = useCallback(() => {
      setState({ ...state, visible: false });
      if (typeof state.onClose === "function") state.onClose();
    }, [state]);

    const showModalWithYesAndNo = useCallback(
      (title, message, onYes, onNo, yesText = "", noText = "") => {
        setState({
          modalTitle: title,
          modalBody: message,
          visible: true,
          onOkClick: onYes,
          onClose: onNo,
          btnYesText: yesText,
          btnNoText: noText,
          isYesNo: true,
        });
      },
      []
    );

    const showModalWithOk = useCallback((title, message, onOk, okText = "") => {
      setState({
        modalTitle: title,
        modalBody: message,
        visible: true,
        onOkClick: onOk,
        btnYesText: okText,
        isYesNo: false,
      });
    }, []);

    return (
      <div>
        <KWHModal
          visible={state.visible}
          modalBody={state.modalBody}
          modalTitle={state.modalTitle}
          onClose={state.isYesNo && handleOnNo}
          onOkClick={handleOnYes}
          btnYesText={state.btnYesText}
          btnNoText={state.btnNoText}
        />
        {child({
          ...props,
          showModalWithYesAndNo: showModalWithYesAndNo,
          showModalWithOk: showModalWithOk,
        })}
      </div>
    );
  };
};
