import React from "react";
import ImageContentContainer from "../../Components/ImageContentContainer";
import AssetsConstants from "../../Constants/AssetsConstants";
import ForgotPasswordForm from "../../Components/ForgotPasswordForm";
import "./style.scss";
import { useTranslation } from "react-i18next";

function ForgotPassword(props) {
  const { t } = useTranslation();

  const goBack = () => {
    props.history.goBack();
  };

  const content = (
    <div className="forgot-form-box">
      <div className="inner-box">
        <div className="top-head">
          <a className="back-btn" onClick={goBack}>
            <i className="icon-left-arrow"></i>
          </a>
          <h4 className="heading">{t("common.forgotPasswordOnly")}</h4>
        </div>
        <div className="lock-img-box">
          <img src={AssetsConstants.lockImg} alt="" />
        </div>
        <p className="normal-txt">{t("common.forgotPasswordExplainer")}</p>
        <ForgotPasswordForm />
      </div>
    </div>
  );
  return (
    <ImageContentContainer
      imageSrc={AssetsConstants.forgotImage}
      content={content}
    />
  );
}

export default ForgotPassword;
