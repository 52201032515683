import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReadJuzContext } from "../../Hooks/useReadJuz";

const JuzStatusDropDown = () => {
  const { t } = useTranslation();
  const {
    openChangeStatus,
    onClickActionItem,
    markAsComplete,
    unClaimJuzz,
  } = ReadJuzContext();

  return (
    <button className="status-btn" onClick={() => onClickActionItem("status")}>
      <i className="icon"></i>
      <span>{t("pledges.status")}</span>
      <div className={`popup-box ${openChangeStatus ? "active" : ""}`}>
        <h2>{t("pledges.changeStatus")}</h2>
        <ul>
          <li onClick={markAsComplete} className="completed">
            <span>{t("pledges.markAsComplete")}</span>{" "}
            <i className="icon-check check-icon"></i>
          </li>
          <li onClick={unClaimJuzz} className="removed">
            <span>{t("pledges.removeClaim")}</span>{" "}
            <i className="icon-close-solid cross-icon"></i>
          </li>
        </ul>
      </div>
    </button>
  );
};

export default JuzStatusDropDown;
