import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import schema from "@khitmah/shared/src/Schemas/schemaLogin";
import { SIGN_IN } from "@khitmah/shared/src/Constants/form";
import { loginUser } from "@khitmah/shared/src/Services/User/Authentication/loginUser";
import { logoutUser } from "@khitmah/shared/src/Services/User/Authentication/logoutUser";
import FormInput from "../Common/FormInput";
import "./style.scss";
import KWHModal from "../Common/Modal";
import Button from "../Common/Button";
import { useTranslation } from "react-i18next";
import { getUser } from "@khitmah/shared/src/Services/User/getUser";

function LoginForm(props) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const login = async (values) => {
    setIsLoading(true);
    setErrorMessage("");
    setVisibleModal(false);
    const { email, password } = values;
    try {
      const user = await loginUser(email, password);
      await getUser(user.uid);
      setIsLoading(false);

      if (user.emailVerified) {
        props.redirect();
      } else {
        await logoutUser();
        setVisibleModal(true);
      }
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setErrorMessage("");
      setVisibleModal(false);
      setIsLoading(false);
    };
  }, []);

  const closeModal = () => {
    setVisibleModal(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <KWHModal
        visible={visibleModal}
        modalTitle={t("loginScreen.emailVerificationRequired")}
        modalBody={t("loginScreen.verifyYourEmailText")}
        //  onClose={closeModal}
        onOkClick={closeModal}
        btnSaveText={t("common.ok")}
      />
      <Formik
        noValidate={true}
        validationSchema={schema}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={login}
        validateOnMount={true}
      >
        {(props) => {
          const { isValid } = props;
          return (
            <>
              <Form>
                <FormInput
                  name={SIGN_IN.EMAIL}
                  label={t("common.email")}
                  fieldType="email"
                  {...props}
                />
                <div className="pass-field-box">
                  <FormInput
                    name={SIGN_IN.PASSWORD}
                    label={t("common.password")}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && isValid) {
                        props.submitForm();
                      }
                    }}
                    fieldType={showPassword ? "text" : "password"}
                    {...props}
                  />

                  <span
                    className={`eye-btn ${showPassword ? "open" : ""}`}
                    onClick={() => setShowPassword(!showPassword)}
                  ></span>
                </div>

                {errorMessage && (
                  <div className="alert alert-danger text-center">
                    {errorMessage}
                  </div>
                )}

                <Button
                  type="submit"
                  disabled={!isValid || isLoading}
                  loading={isLoading}
                  btnText={t("common.login")}
                  className="primary-btn login-btn"
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default LoginForm;
