export const URL_CONSTANTS = {
  GET_PAGE_OF_JUZZ: 'https://salamquran.com/{language}/api/v6/page',
  GET_AYAT_OF_JUZZ: 'https://salamquran.com/{language}/api/v6/aya',
};

export const HTTP_FETCH_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};
