import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makePayment } from "@khitmah/shared/src/Services/Payment/makePayment";
import AssetsConstants from "../../Constants/AssetsConstants";
import { withHeader } from "../../Components/Header";
import { DonationRequestModel } from "../../Helper/Donation";
import ButtonPrimary from "../../Components/ButtonPrimary";
import { BRAINTREE_PAYMENT_METHOD } from "../../Constants/PaymentConstants";
import Loader from "../../Components/Common/Loader";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { PAYMENT_TYPE } from "@khitmah/shared/src/Utils/types";
import {
  trackFundsRaised,
  trackPayItForward,
} from "../../Services/Mixpanel/trackEvents";
import { AppContext } from "../../Context/AppContext";

const ConfirmDonationView = (props) => {
  const { t } = useTranslation();
  const { details } = props;
  const { cardDetails, paymentMethodType, amount, type, sponsorMsg } = details;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="donation-summary-box page-view">
        <h2>{t("donation.donationSummary")}</h2>

        {type === PAYMENT_TYPE.PAY_AS_FORWARD &&
          !!cardDetails.firstName &&
          !!cardDetails.lastName && (
            <div className="row">
              <div className="col-12 col-md-6">
                <label>{t("payItForwardScreen.donorName")}</label>
              </div>
              <div className="col-12 col-md-6 text-right">{`${cardDetails.firstName} ${cardDetails.lastName}`}</div>
            </div>
          )}

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("donation.paymentMethod")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            {paymentMethodType === BRAINTREE_PAYMENT_METHOD.CREDIT_CARD
              ? "Credit Card"
              : "Paypal Account"}
          </div>
        </div>

        {paymentMethodType === BRAINTREE_PAYMENT_METHOD.CREDIT_CARD && (
          <>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>{t("donation.cardNumber")}</label>
              </div>
              <div className="col-12 col-md-6 text-right">
                <span>{`**** **** **** ${cardDetails.lastFour}`}</span>
              </div>
            </div>
          </>
        )}
        {paymentMethodType === BRAINTREE_PAYMENT_METHOD.PAYPAL && (
          <>
            <div className="row">
              <div className="col-12 col-md-6">
                <label>{t("common.account")}</label>
              </div>
              <div className="col-12 col-md-6 text-right">
                <span>{cardDetails.email}</span>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("common.amount")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span>$ {amount}</span>
          </div>
        </div>
        {type === PAYMENT_TYPE.PAY_AS_FORWARD && (
          <div className="row">
            <div className="col-12">
              <label>{t("common.message")}</label>
              <br />
              <div className="label-value">{sponsorMsg}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const ThankYouModal = ({
  onClick,
  amount,
  friendName,
  transactionId,
  type,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="thanks-confirmation-overllay"></div>
      <div className="thanks-confirmation-popup">
        <h2>{t("common.thankYou")}</h2>
        <img src={AssetsConstants.checkIcon} alt="" />
        <p className="transaction-id">
          {` ${t("donation.transactionId")}: ${transactionId}`}
        </p>
        <p>
          {`${t("donation.thankYouMessagePart1")} $${amount} ${t(
            "donation.thankYouMessagePart2"
          )} `}{" "}
          <span>
            {" "}
            {type === PAYMENT_TYPE.PAY_AS_FORWARD
              ? t("payItForwardScreen.sponsorAKhitmah")
              : friendName}
          </span>
          .{t("donation.thankYouMessagePart3")}
        </p>
        <ButtonPrimary name={t("donation.goBack")} onClick={() => onClick()} />
      </div>
    </>
  );
};

const ConfirmDonation = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const [isThankyouModaVisible, setIsThankyouModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const {
    state: { loggedInUser },
  } = useContext(AppContext);

  const { donation_details, khitmah } = location.state;
  const { amount, friendName, type } = donation_details;
  const onDonate = async () => {
    setLoading(true);
    const payload = DonationRequestModel(donation_details);
    try {
      let paymentResponse;
      if (payload.type === PAYMENT_TYPE.DONATION) {
        paymentResponse = await makePayment(
          process.env.REACT_APP_MAKE_PAYMENT_URL,
          payload,
          khitmah
        );
        trackFundsRaised(payload.amount, payload.type, khitmah);
      } else if (payload.type === PAYMENT_TYPE.PAY_AS_FORWARD) {
        paymentResponse = await makePayment(
          process.env.REACT_APP_MAKE_PAYMENT_URL,
          payload,
          loggedInUser
        );
        trackPayItForward(payload.amount, loggedInUser);
      }

      setLoading(false);

      if (!!paymentResponse.success) {
        setIsThankyouModalVisible(true);
        setTransactionId(paymentResponse.data.transactionId);
      } else {
        alert(paymentResponse.message);
        navigateToKhitmahDetails();
      }
    } catch (error) {
      console.log("Payment error", error);
      setLoading(false);
    }
  };

  const navigateToKhitmahDetails = () => {
    history.goBack();
  };

  return (
    <>
      <div className="donation-summary-screen">
        {/* <Loader showLoader={loading} /> */}
        <ConfirmDonationView details={donation_details} />
        <div className="confirm-donation-btn-box">
          {donation_details.type !== PAYMENT_TYPE.PAY_AS_FORWARD && (
            <div className="confirm-donation-details">
              <p>{t("donation.donationSummaryMessagePart1")}</p>
              <p>{`${t("donation.donationSummaryMessagePart2")} ${72} ${t(
                "donation.donationSummaryMessagePart3"
              )}`}</p>
            </div>
          )}
          <ButtonPrimary
            name={
              donation_details.type === PAYMENT_TYPE.PAY_AS_FORWARD
                ? t("payItForwardScreen.confirmDonation")
                : t("khitmah.donate")
            }
            onClick={onDonate}
            loading={loading}
            className="donate-btn"
          />
          {isThankyouModaVisible && (
            <ThankYouModal
              onClick={navigateToKhitmahDetails}
              amount={amount}
              friendName={friendName}
              transactionId={transactionId}
              type={type}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withHeader(ConfirmDonation);
