import {User} from './../../Models/user';
import {Khitmah} from './../../Models/khitmah';
import {trackPayItForward} from './../Mixpanel/trackEvents';
import {HTTP_FETCH_METHODS} from '../../Constants/urlConstants';
import {
  PAYMENT_FORM_FIELDS,
  PAYMENT_RESPONSE,
  PAYMENT_TYPE,
} from '../../Utils/types';
import {trackFundsRaised} from '../Mixpanel/trackEvents';

/**
 * This service is intended to make payments either for donation or pay it forward
 * @param url -> Dynamic url based on the environment
 * @param paylaod -> request payload required for making payment
 */
export const makePayment = async (
  url: string,
  payload: PAYMENT_FORM_FIELDS,
  otherInfo?: Khitmah | User,
) => {
  try {
    const response = await fetch(url, {
      method: HTTP_FETCH_METHODS.POST,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    const {success, data, message} = result;
    if (payload.type == PAYMENT_TYPE.DONATION)
      trackFundsRaised(payload.amount, payload.type, otherInfo as Khitmah);
    else if (payload.type == PAYMENT_TYPE.PAY_AS_FORWARD)
      trackPayItForward(payload.amount, otherInfo as User);
    return {success, data, message} as PAYMENT_RESPONSE;
  } catch (error) {
    throw new Error(error);
  }
};
