import React, { Component } from "react";
import { ErrorMessage, Field } from "formik";
import { DatePickerField } from "../DatePickerField";

function DatePicker(props) {
  const { name } = props;
  return (
    <>
      <Field name={name} component={DatePickerField} {...props} />
    </>
  );
}
export default DatePicker; 
