import React, { Component } from "react";
import { ErrorMessage, Field  } from "formik";
import { TimePickerField } from "../DatePickerField";

function TimePicker(props) {
    const { name } = props;
    return (
      <>
        <Field name={name} component={TimePickerField} {...props} />
      </>
    );
}
export default TimePicker;
