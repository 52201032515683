export const FIELD_TYPES = {
  TEXT: "text",
  TEXT_BOX: "text_box",
  DATE: "date",
  TIME: "time",
  MAP: "map",
  PICKER: "picker",
  MULTIPICKER: "multipicker",
  MONTH_YEAR: "month_year",
  YEAR: "year",
  SHEET_PICKER: "sheet_picker",
  TEXT_INPUT_MASKED: "maskedInput",
  RADIO_PICKER: "radioPicker",
  CHECKBOX: "checkbox",
  NUMBER: "number",
  BUTTON_GROUP: "buttonGroup",
  AUTO_COMPLETE_SEARCH: "autoCompleteSearch",
  PHONE_NUMBER: "phoneNumber",
};
