import * as Yup from 'yup';

const schema = Yup.object().shape({
  fullName: Yup.string()
    .required('schemaValidationErrors.fullNameRequired')
    .test('len', 'schemaValidationErrors.symbolsNotAllowed', (val: any) => {
      return !/[^a-zA-Z0-9\ \/]/.test(val);
    }),
  email: Yup.string()
    .email('schemaValidationErrors.invalidEmail')
    .required('schemaValidationErrors.emailRequired'),
  password: Yup.string()
    .required('schemaValidationErrors.passwordRequired')
    .min(6, 'schemaValidationErrors.passwordMinLength')
    .max(30, 'schemaValidationErrors.passwordMaxLength'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), ''],
      'schemaValidationErrors.passwordsBothMatch',
    )
    .required('schemaValidationErrors.passwordConfirmRequired'),
  city: Yup.string(),
  country: Yup.string(),
  age: Yup.number().min(18, 'schemaValidationErrors.ageGreaterThan17'),
  tocAccepted: Yup.bool().oneOf(
    [true],
    'schemaValidationErrors.termsAndConditionsRequired',
  ),
});
export default schema;
