/**
 *
 * @param juzzNumber juzz number to read
 * Each juzz starts at a specific page number that is defined by 'Salam Quran' Api for reading quran.
 *
 */
export const getFirstPageOfJuzz = (juzzNumber: number) => {
  switch (juzzNumber) {
    case 1:
      return 1;
    case 2:
      return 22;
    case 3:
      return 42;
    case 4:
      return 62;
    case 5:
      return 82;
    case 6:
      return 102;
    case 7:
      return 121;
    case 8:
      return 142;
    case 9:
      return 162;
    case 10:
      return 182;
    case 11:
      return 201;
    case 12:
      return 222;
    case 13:
      return 242;
    case 14:
      return 262;
    case 15:
      return 282;
    case 16:
      return 302;
    case 17:
      return 322;
    case 18:
      return 342;
    case 19:
      return 362;
    case 20:
      return 382;
    case 21:
      return 402;
    case 22:
      return 422;
    case 23:
      return 442;
    case 24:
      return 462;
    case 25:
      return 482;
    case 26:
      return 502;
    case 27:
      return 522;
    case 28:
      return 542;
    case 29:
      return 562;
    case 30:
      return 582;
    default:
      return 0;
  }
};

/**
 *
 * @param juzzNumber juzz number to read
 * Each juzz ends at a specific page number that is defined by 'Salam Quran' Api for reading quran.
 *
 */
export const getLastPageOfJuzz = (juzzNumber: number) => {
  switch (juzzNumber) {
    case 1:
      return 21;
    case 2:
      return 41;
    case 3:
      return 61;
    case 4:
      return 81;
    case 5:
      return 101;
    case 6:
      return 120;
    case 7:
      return 141;
    case 8:
      return 161;
    case 9:
      return 181;
    case 10:
      return 200;
    case 11:
      return 221;
    case 12:
      return 241;
    case 13:
      return 261;
    case 14:
      return 281;
    case 15:
      return 301;
    case 16:
      return 321;
    case 17:
      return 341;
    case 18:
      return 361;
    case 19:
      return 381;
    case 20:
      return 401;
    case 21:
      return 421;
    case 22:
      return 441;
    case 23:
      return 461;
    case 24:
      return 481;
    case 25:
      return 501;
    case 26:
      return 521;
    case 27:
      return 541;
    case 28:
      return 561;
    case 29:
      return 581;
    case 30:
      return 604;
    default:
      return 0;
  }
};
