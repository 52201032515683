import React from "react";
import { useTranslation } from "react-i18next";
import { BRAINTREE_PAYMENT_METHOD } from "../../Constants/PaymentConstants";

const DonationSummaryDetail = (props) => {
  const { t } = useTranslation();
  const {
    amount,
    transactionFee,
    serviceCharges,
    totalDonation,
    paymentMethodType,
  } = props;
  return (
    <>
      <div className="donation-summary-box donation-summary-view">
        {/* <h2>{t("donation.donationSummary")}</h2> */}

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("donation.paymentMethod")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span className="label-value">
              {paymentMethodType === BRAINTREE_PAYMENT_METHOD.PAYPAL
                ? t("donation.paypal")
                : `${t("donation.creditCard")} (${paymentMethodType})`}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("khitmah.donationAmount")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span className="label-value">${amount}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("donation.transactionFee")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span className="label-value">${transactionFee}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <label>{t("donation.serviceCharges")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span className="label-value">${serviceCharges}</span>
          </div>
        </div>
      </div>

      <div className="donation-summary-box donation-summary-view total-donation-row ">
        <div className="row mb-0">
          <div className="col-12 col-md-6">
            <label className="mb-0">{t("donation.totalDonation")}</label>
          </div>
          <div className="col-12 col-md-6 text-right">
            <span className="label-value">${totalDonation}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationSummaryDetail;
