import React from "react";
import { Field } from "formik";
import RadioButtonGroup from "../RadioButtonGroup";

const RadioPicker = (props) => {
  const { name } = props;
  return (
    <>
      <Field name={name} component={RadioButtonGroup} {...props} />
    </>
  );
};
export default RadioPicker;
