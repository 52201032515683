import { dateFormatted2 } from "@khitmah/shared/src/Utils/utils";
import storage from "@khitmah/shared/src/Firebase/storage";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AssetsConstants from "../../../Constants/AssetsConstants";
import "./style.scss";
import { AppRoutes } from "../../../Constants/RouteConstants";
import {} from "react-router-dom";
import { downloadPhoto } from "@khitmah/shared/src/Services/downloadPhoto";

function MyPledgesCard(props) {
  const { t } = useTranslation();
  const { item, onClick } = props;
  const { friendName, juzzNumber, friendImageUrl, createdAt } = item;

  const [imageString, setImageString] = useState("");

  const openConfirmationModal = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    props.openConfirmationModal(item);
  };

  useEffect(() => {
    if (!!friendImageUrl) {
      try {
        downloadPhoto(friendImageUrl).then((path) => {
          setImageString(path);
        });
      } catch (error) {
        //console.error(error);
      }
    }
    return () => {
      setImageString("");
    };
  }, [friendImageUrl]);

  return (
    <>
      <div className="carousel-card mypledge-card" onClick={onClick}>
        {/* <div className="status-box">{getStatusText(status)}</div> */}

        <div className="user-detail-box">
          <div className="user-pic">
            {imageString ? (
              <img src={imageString} alt="" className="img-thumbnail" />
            ) : (
              <img src={AssetsConstants.defaultPlaceholder} alt="" />
            )}
          </div>
          <div className="user-info-box">
            <div className="card-small-title">{t("khitmah.myPledge")}</div>
            <div className="continue-reading-title">
              {t("khitmah.continueReadingJuzz") + " " + juzzNumber}
            </div>
            <div className="friend-title">
              {`${t("khitmah.khitmahFor")} ${friendName}`}
            </div>
          </div>
        </div>

        <div className="bottom-box">
          <button
            className="mark-btn"
            onClick={(e) => openConfirmationModal(e)}
          >
            {t("pledges.markAsComplete")}
          </button>
          <div className="date">{dateFormatted2(createdAt?.toDate())}</div>
        </div>
      </div>
    </>
  );
}

export default MyPledgesCard;
