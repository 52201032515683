import storage from '../Firebase/storage';
import {getErrorMessageFromFirestoreError} from '../Utils/firestoreErrorMap';
import {IMAGES_DIRECTORY} from '../Constants/appConstants';

export type PHOTO_TYPES = 'profile' | 'khitmahFriend';

const ImageFolders = {
  USER_PHOTOS: IMAGES_DIRECTORY + '/user',
  KHITMAH_PHOTOS: IMAGES_DIRECTORY + '/khitmah',
};

const ImagePrefix = {
  USER_PROFILE_PHOTO: 'profile',
  KHITMAH_FRIEND_PHOTO: 'friend',
};

export const uploadPhoto = async (
  photoType: PHOTO_TYPES,
  path: string,
  id: string,
  file?: File,
): Promise<string | null> => {
  let folderToPutImageIn: string;
  let imageName: string;
  try {
    switch (photoType) {
      case 'profile':
        folderToPutImageIn = ImageFolders.USER_PHOTOS;
        imageName = ImagePrefix.USER_PROFILE_PHOTO;
        break;
      case 'khitmahFriend':
        folderToPutImageIn = ImageFolders.KHITMAH_PHOTOS;
        imageName = ImagePrefix.KHITMAH_FRIEND_PHOTO;
        break;
    }

    let fileName = '';
    if (!!file) {
      fileName = file.name;
    } else {
      const fileNameIndex = path.lastIndexOf('/') + 1;
      fileName = path.substr(fileNameIndex);
    }

    const fileExtension = fileName.substring(fileName.lastIndexOf('.'));

    // Folder name / id / filename
    // e.g: /images/user_profile/sdafbsdfisdf12/profile.jpeg
    const imageLocation =
      folderToPutImageIn + '/' + id + '/' + imageName + fileExtension;
    const reference = storage().ref(imageLocation);
    let task = null;
    if (!!file) {
      task = await reference.put(file);
    } else {
      task = await reference.putFile(path);
    }

    if (task.state === 'success') return imageLocation;
    else return null;
  } catch (error) {
    // console.log(error);
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
