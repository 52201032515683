import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import React from "react";
import { privateRoutes, publicRoutes } from "../Constants/RouteConstants";

export function Routes(props) {
  return (
    <Router>
      <Switch>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              exact={route.exact}
              path={route.path}
              key={index}
              component={route.component}
              name={route.name || ""}
              {...props}
            />
          );
        })}

        {privateRoutes.map((route, index) => {
          return (
            <PrivateRoute
              exact={route.exact}
              path={route.path}
              key={index}
              component={route.component}
              name={route.name || ""}
              nameTx={route.nameTx || ""}
              authenticated={props.authenticated}
              {...props}
            />
          );
        })}
        <Route component={() => <p>404 not found</p>} />
      </Switch>
    </Router>
  );
}

export function PrivateRoute({
  component: Component,
  authenticated,
  name,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} name={name} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
