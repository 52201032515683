import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/AppContext";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../Constants/RouteConstants";
import { getAllPayments } from "@khitmah/shared/src/Services/User/PayAsForward/getAllPayments";
import { documentSnapshotToPayAsForward } from "@khitmah/shared/src/Models/payItForwardDonation";
import { PAYMENT_TYPE } from "@khitmah/shared/src/Utils/types";

export const usePayItForward = () => {
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const { loggedInUser } = state;
  const [dropInInstance, setDropInInstance] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  const initialValues = {
    message: "",
    amount: "$0",
    paymentId: "",
    paymentType: "",
  };

  const goToConfirmDonation = (donation_details) => {
    const params = { ...donation_details, type: PAYMENT_TYPE.PAY_AS_FORWARD };
    history.push(AppRoutes.CONFIRM_DONATION, {
      donation_details: params,
    });
  };

  const [myDonations, setMyDonations] = useState([]);

  const onCollectionUpdate = useCallback((snapshot) => {
    let donations = [];
    donations = snapshot.docs.map((doc) => documentSnapshotToPayAsForward(doc));
    setMyDonations(donations);
    setIsLoading(false);
  });

  const onCollectionError = useCallback((error) => {
    setMyDonations([]);
    setIsLoading(false);
  });

  useEffect(() => {
    if (history.location.pathname === AppRoutes.DONATION_HISTORY) {
      const unsub = getAllPayments(loggedInUser.id).onSnapshot(
        onCollectionUpdate,
        onCollectionError
      );
      return () => unsub();
    }
  }, []);

  return {
    isLoading,
    initialValues,
    dropInInstance,
    setDropInInstance,
    showModal,
    setShowModal,
    goToConfirmDonation,
    myDonations,
  };
};
