import React from "react";
import { useTranslation } from "react-i18next";

const TextField = (props) => {
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    hideLabel,
    placeholder,
    helperText,
    disabled,
    required,
  } = props;
  let validatyClass = "";
  if (!!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-invalid";
  }
  if (!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-valid";
  }

  const { t } = useTranslation();

  return (
    <>
      {!hideLabel && (
        <label htmlFor={name}>
          {label}{" "}
          {required && label && <strong style={{ color: "red" }}>*</strong>}
        </label>
      )}
      <input
        {...field}
        {...props}
        placeholder={placeholder}
        id={name}
        type={fieldType}
        className={`form-control ${validatyClass} ${disabled && "disabled"}`}
      />
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback">{t(errors[field.name])}</div>
      )}
      {helperText && <div className="generic-note-msg">{helperText}</div>}
    </>
  );
};

export default TextField;
