import React, { Suspense, lazy, Fragment, useState } from "react";
import {
  CHeader,
  CSidebar,
  CSidebarHeader,
  CSidebarForm,
  CSidebarNav,
  CSidebarNavItem,
  CSidebarFooter,
  CCreateElement,
  CContainer,
} from "@coreui/react";
import { Switch, Route } from "react-router-dom";
import Content from "./Content";
import Sidebar from "./Sidebar";
import { withModal } from "../Components/Common/Modal";

// laxy loaded components
const AppHeader = React.lazy(() => import("./Header"));

function BaseLayout(props) {
  const [minimize, setMinimize] = useState(true);
  return (
    <div className="c-app c-default-layout">
      <Sidebar {...props} minimize={minimize} />
      <div className="c-wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <AppHeader {...props} minimize={minimize} setMinimize={setMinimize} />
        </Suspense>
        <div className="c-body">
          <Content {...props} />
        </div>
      </div>
    </div>
  );
}

export default withModal(BaseLayout);
