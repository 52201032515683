import {KHITMAH_PLEDGES} from '../../Constants/database';
import firestore from '../../Firebase/firestore';
import {Status} from '../../Models/khitmahPledge';

export const getActiveKhitmahPledges = (userId: string, limit?: number) => {
  if (limit)
    return firestore()
      .collection(KHITMAH_PLEDGES)
      .where('userId', '==', userId)
      .where('status', '==', Status.CLAIMED)
      .orderBy('createdAt', 'desc')
      .limit(limit);

  return firestore()
    .collection(KHITMAH_PLEDGES)
    .where('userId', '==', userId)
    .where('status', '==', Status.CLAIMED)
    .orderBy('createdAt', 'desc');
};

export const getActiveKhitmahPledgesOfUserForKhitmah = (
  userId: string,
  khitmahId: string,
) => {
  return firestore()
    .collection(KHITMAH_PLEDGES)
    .where('userId', '==', userId)
    .where('khitmahId', '==', khitmahId)
    .where('status', '==', Status.CLAIMED);
};

export const getPastKhitmahPledges = (userId: string) => {
  // There is support available in firestore for != but not available in react native firestore package right now
  return firestore()
    .collection(KHITMAH_PLEDGES)
    .where('userId', '==', userId)
    .where('status', 'in', [
      Status.LEFT,
      Status.COMPLETED,
      Status.REMOVED,
      Status.KHITMAH_COMPLETED,
      Status.KHITMAH_REMOVED,
    ])
    .orderBy('createdAt', 'desc');
};

export const getAllKhitmahPledgesOfUserForKhitmah = (
  userId: string,
  khitmahId: string,
) => {
  return firestore()
    .collection(KHITMAH_PLEDGES)
    .where('userId', '==', userId)
    .where('khitmahId', '==', khitmahId);
};

export const getIfKhitmahPledgeExists = (
  userId: string,
  khitmahId: string,
  juzzNumber: number,
) => {
  return firestore()
    .collection(KHITMAH_PLEDGES)
    .where('userId', '==', userId)
    .where('khitmahId', '==', khitmahId)
    .where('juzzNumber', '==', juzzNumber);
};

export const getPledgeById = (id: string) => {
  return firestore().collection(KHITMAH_PLEDGES).doc(id);
};
