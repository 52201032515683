import React, { useEffect, useState } from "react";
import {
  FundraiserProgressBarConfig,
  KhitmahProgressBarConfig,
} from "../../../Helper/KhitmahList";
import KWHProgressBar from "../../Common/KWHProgressBar";
import FundRaiserStats from "../../Common/FundRaiserStats";
import JuzzCompletionStats from "../../Common/JuzzCompletionStats";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { daysLeft } from "@khitmah/shared/src/Utils/utils";

import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import useShareKhitmah from "../../../Hooks/useShareKhitmah";

function MyKhitmahsCard(props) {
  const { t } = useTranslation();
  const [showShareIcons, setShowShareIcons] = useState(false);
  const { item, onClick } = props;
  const {
    stats = {},
    fundRaiser = {},
    friendName,
    endDate,
    joiningCode,
    hasFundRaiser,
    id,
  } = item;
  const [shareUrl, setShareUrl] = useState(process.env.REACT_APP_SHARE_URL);
  const { createUrl } = useShareKhitmah();

  const toggleShareButtons = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setShowShareIcons(!showShareIcons);
  };

  useEffect(() => {
    if (item) {
      createUrl(item).then((url) => {
        setShareUrl(url);
      });
    }

    return () => {
      setShowShareIcons(false);
    };
  }, []);

  return (
    <>
      <div className="carousel-card mykhidmah-card" onClick={onClick}>
        <div className="top-box">
          <div className="card-small-title">{t("khitmah.myKhitmah")}</div>
          <div className="card-title">
            {`${t("khitmah.khitmahFor")} ${friendName}`}
          </div>

          <button className="share-btn" onClick={(e) => toggleShareButtons(e)}>
            <i className="icon-share-2"></i>
            <div className={`share-popup-box ${showShareIcons && "active"}`}>
              <div className="icon fb">
                <FacebookShareButton
                  url={shareUrl}
                  quote={`${t(
                    "common.shareKhitmahTextFirstPart"
                  )} ${shareUrl} ${t(
                    "common.shareKhitmahTextSecondPart"
                  )} ${joiningCode}`}
                >
                  <FacebookIcon size={27} round />
                </FacebookShareButton>
                {/* <i className="icon-facebook"></i> */}
              </div>
              <div className="icon">
                <EmailShareButton
                  url={shareUrl}
                  subject={t("common.shareKhitmahEmailTitle")}
                  body={`${t(
                    "common.shareKhitmahTextFirstPart"
                  )} ${shareUrl} ${t(
                    "common.shareKhitmahTextSecondPart"
                  )} ${joiningCode}`}
                >
                  <EmailIcon size={27} round />
                </EmailShareButton>
                {/* <i className="icon-envelope"></i> */}
              </div>
              <div className="icon">
                <WhatsappShareButton
                  url={shareUrl}
                  title={`${t("common.shareKhitmahTextFirstPart")} ${t(
                    "common.shareKhitmahTextSecondPart"
                  )} ${joiningCode}`}
                >
                  <WhatsappIcon size={27} round />
                </WhatsappShareButton>
              </div>
            </div>
          </button>
        </div>

        <div className="progress-chart-parent">
          <div className="inner totalKhitmahCompleted">
            <h3 className="count">{stats?.completedQuran}</h3>
            <div className="info-box">{t("khitmah.totalKhitmahCompleted")}</div>
          </div>
          <div className="inner">
            <div className="secondary-progress chart-box">
              <KWHProgressBar config={KhitmahProgressBarConfig(stats)} />
            </div>

            <div className="info-box">
              <JuzzCompletionStats
                completedCount={stats.completedJuzz}
                pledgedCount={stats.claimedJuzz - stats.completedJuzz}
              />
            </div>
          </div>

          {hasFundRaiser && (
            <div className="inner">
              <div className="primary-progress chart-box">
                <KWHProgressBar
                  config={FundraiserProgressBarConfig(fundRaiser)}
                />
              </div>

              <div className="info-box primary-txt">
                <FundRaiserStats
                  amountRaised={fundRaiser?.receivedAmount}
                  amountGoal={fundRaiser?.requestedAmount}
                />
              </div>
            </div>
          )}
        </div>

        <div className="left-days-box">
          <div className="txt">
            <span>{daysLeft(endDate)}</span>{" "}
            {Number(daysLeft(endDate)) > 1
              ? t("khitmah.daysLeft")
              : t("khitmah.dayLeft")}
          </div>
        </div>
      </div>
    </>
  );
}

export default MyKhitmahsCard;
