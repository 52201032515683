import { createUser } from "@khitmah/shared/src/Services/User/createUser";
import { getErrorMessageFromFirestoreError } from "@khitmah/shared/src/Utils/firestoreErrorMap";
import { auth } from "firebase";
import { getBrowserDefaultLanguage } from "../../Helper/Common";
import { setMixpanelUser } from "../Mixpanel";
import { trackUserSignedup } from "../Mixpanel/trackEvents";

export const gmailLoginUser = async (response) => {
  try {
    // Create a Google credential with the token
    const googleCredential = auth.GoogleAuthProvider.credential(
      response.credential.idToken
    );
    // Sign-in the user with the credential
    const credential = await auth().signInWithCredential(googleCredential);
    const additionalUserInfo = credential.additionalUserInfo;
    //console.log("google" + JSON.stringify(credential));
    const userProvidedData = credential.user.providerData[0];
    const fullName = userProvidedData.displayName
      ? userProvidedData.displayName
      : "";
    const email = userProvidedData.email ? userProvidedData.email : "";
    const profileImageUrl = userProvidedData.photoURL
      ? userProvidedData.photoURL
      : "";

    if (additionalUserInfo?.isNewUser) {
      setMixpanelUser(credential.user.uid);
      trackUserSignedup(userProvidedData.country, userProvidedData.city);
    }

    createUser(
      fullName,
      email,
      profileImageUrl,
      credential.user.uid,
      getBrowserDefaultLanguage(),
      additionalUserInfo?.isNewUser
    );
    return credential.user;
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
