import { useContext, useEffect, useState } from "react";
import {
  getActiveKhitmahPledges,
  getPastKhitmahPledges,
} from "@khitmah/shared/src/Services/KhitmahPledge/getAllKhitmahPledge";
import { PledgesListModel } from "../Helper/PledgesList";
import { AppContext } from "../Context/AppContext";

export function useMyActivePledges() {
  const {
    state: { loggedInUser, fetchingUserDetails },
  } = useContext(AppContext);

  const [activePledgesLoading, setActivePledgesLoading] = useState(
    fetchingUserDetails
  );
  const [activePledges, setMyPledges] = useState([]);

  useEffect(() => {
    if (!!loggedInUser?.id) {
      setActivePledgesLoading(true);

      getActiveKhitmahPledges(loggedInUser.id).onSnapshot(
        (snapshot) => {
          const data = snapshot.docs.map(
            (doc) => doc.exists && { ...doc.data(), id: doc.id }
          );
          setMyPledges(PledgesListModel(data).pledges);
          setActivePledgesLoading(false);
        },
        (error) => {
          setActivePledgesLoading(false);
          console.log("Error in fetching pledges", error);
        }
      );
    }
  }, [loggedInUser]);

  const removePledgeFromList = (pledge) => {
    setMyPledges(activePledges.filter((p) => p.id !== pledge.id));
  };

  return {
    activePledges,
    activePledgesLoading,
    removePledgeFromList,
  };
}

export function useMyPastPledges() {
  const [pastPledgesLoading, setPastPledgesLoading] = useState(false);
  const [pastPledges, setMyPledges] = useState([]);

  const {
    state: { loggedInUser },
  } = useContext(AppContext);

  useEffect(() => {
    if (loggedInUser.id) {
      setPastPledgesLoading(true);
      return getPastKhitmahPledges(loggedInUser.id).onSnapshot(
        (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.exists && doc.data());
          setMyPledges(PledgesListModel(data).pledges);
          setPastPledgesLoading(false);
        },
        (error) => {
          console.log("Error in fetching pledges", error);
        }
      );
    }
  }, []);

  return {
    pastPledges,
    pastPledgesLoading,
  };
}
