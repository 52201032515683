import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { gmailAuthProvider } from "../../Services/firebase";
import { gmailLoginUser } from "../../Services/Authentication/gmailLogin";
import "./styles.scss";
import KWHModal from "../Common/Modal";
import { useTranslation } from "react-i18next";

function GmailLogin(props) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("Login Error");
  const { t } = useTranslation();

  const loginWithGmail = async () => {
    setErrorMessage("");

    try {
      let response = await firebase.auth().signInWithPopup(gmailAuthProvider);
      const user = await gmailLoginUser(response);
      if (!user.emailVerified) {
        setErrorMessage(t("loginScreen.verifyYourEmailText"));
        setErrorTitle(t("loginScreen.emailVerificationRequired"));
        setVisibleModal(true);
      } else {
        props.redirect();
      }
    } catch (error) {
      setErrorTitle("Login Error");
      setErrorMessage(error.message);
      setVisibleModal(true);
    }
  };

  useEffect(() => {
    return () => {
      setErrorMessage("");
      setVisibleModal(false);
    };
  }, []);

  const closeModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <KWHModal
        visible={visibleModal}
        modalTitle={errorTitle}
        modalBody={errorMessage}
        onClose={closeModal}
        onOkClick={closeModal}
        btnSaveText={t("common.ok")}
      />
      <button onClick={loginWithGmail} className="gmail-btn"></button>
    </>
  );
}

export default GmailLogin;
