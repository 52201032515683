import { QURAN_TRANSLATION } from "@khitmah/shared/src/Constants/appConstants";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReadJuzContext } from "../../Hooks/useReadJuz";

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const {
    openLanguage,
    onClickActionItem,
    language,
    setLanguage,
  } = ReadJuzContext();

  const getLanguageName = (name) => {
    switch (name) {
      case QURAN_TRANSLATION.NO_TRANSLATION:
        return t("common.noTranslation");
      case QURAN_TRANSLATION.ENGLISH:
        return t("common.english");
      case QURAN_TRANSLATION.ARABIC:
        return t("common.arabic");
      case QURAN_TRANSLATION.FARSI:
        return t("common.farsi");
    }
  };

  return (
    <button
      className="arabic-btn"
      onClick={() => onClickActionItem("language")}
    >
      <i className="icon"></i>
      <span>{getLanguageName(language)}</span>

      <div className={`popup-box ${openLanguage ? "active" : ""}`}>
        <h2>{t("common.translationLanguage")}</h2>
        <ul>
          <li onClick={() => setLanguage(QURAN_TRANSLATION.NO_TRANSLATION)}>
            <span>{t("common.noTranslation")}</span>{" "}
            <i
              className={
                language === QURAN_TRANSLATION.NO_TRANSLATION &&
                `icon-check check-icon`
              }
            ></i>
          </li>
          <li onClick={() => setLanguage(QURAN_TRANSLATION.ENGLISH)}>
            <span>{t("common.english")}</span>{" "}
            <i
              className={
                language === QURAN_TRANSLATION.ENGLISH &&
                `icon-check check-icon`
              }
            ></i>
          </li>
          <li onClick={() => setLanguage(QURAN_TRANSLATION.ARABIC)}>
            <span>{t("common.arabic")}</span>{" "}
            <i
              className={
                language === QURAN_TRANSLATION.ARABIC && `icon-check check-icon`
              }
            ></i>
          </li>
          <li onClick={() => setLanguage(QURAN_TRANSLATION.FARSI)}>
            <span>{t("common.farsi")}</span>{" "}
            <i
              className={
                language === QURAN_TRANSLATION.FARSI && `icon-check check-icon`
              }
            ></i>
          </li>
        </ul>
      </div>
    </button>
  );
};

export default LanguageDropdown;
