import React, { useEffect, useState } from "react";
import storage from "@khitmah/shared/src/Firebase/storage";
import AssetsConstants from "../../../Constants/AssetsConstants";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Khitmah, KhitmahType } from "@khitmah/shared/src/Models/khitmah";
import { AvatarCell } from "../../Common/Table/Avatar";
import { downloadPhoto } from "@khitmah/shared/src/Services/downloadPhoto";

function PublicKhitmahsCard(props) {
  const { t } = useTranslation();
  const { item, onClick } = props;
  const {
    stats = {},
    fundRaiser = {},
    friendName,
    hasFundRaiser,
    type,
    friendImageUrl,
  } = item;
  const { requestedAmount = 0, receivedAmount = 0 } = fundRaiser;
  let fundNeeded =
    hasFundRaiser && fundRaiser ? requestedAmount - receivedAmount : 0;
  if (receivedAmount > requestedAmount) {
    fundNeeded = 0;
  }
  const [imageString, setImageString] = useState("");

  useEffect(() => {
    if (friendImageUrl) {
      try {
        downloadPhoto(friendImageUrl).then((path) => setImageString(path));
      } catch (error) {
        //console.error(error);
      }
    }

    return () => {
      setImageString("");
    };
  }, [friendImageUrl]);

  const khitmahType = () => {
    switch (type) {
      case KhitmahType.SICK:
        return t("khitmah.forTheSick");
      case KhitmahType.DECEASED:
        return t("khitmah.forTheDeceased");
      case KhitmahType.MISSING:
        return t("khitmah.forTheMissing");
      default:
        return "";
    }
  };

  return (
    <>
      <div className="carousel-card publickhitmah-card" onClick={onClick}>
        <div className="user-detail-box">
          <div className="user-pic">
            {imageString ? (
              <img src={imageString} alt="" className="img-thumbnail" />
            ) : (
              <img src={AssetsConstants.defaultPlaceholder} alt="" />
            )}
          </div>
          {/* <AvatarCell imageUrl={item?.friendImageUrl} /> */}

          <div className="user-info-box">
            <div className="user-title">
              {`${t("khitmah.khitmahFor")} ${friendName}`}
            </div>
            <div className="for-title">{khitmahType()}</div>

            <div className="remain-fund-txt">
              <span>
                {`${stats.completedQuran} ${t("khitmah.khitmahsSmall")} `}
              </span>
              {t("khitmah.completed")} <br />
              {hasFundRaiser && fundRaiser && (
                <>
                  <span>{`$ ${fundNeeded} `}</span>
                  {t("khitmah.fundsNeeded")}
                </>
              )}
            </div>
          </div>

          <div className="right-arrow">
            <i className="icon-arrow-right2"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicKhitmahsCard;
