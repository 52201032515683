import React, { Suspense } from "react";
import ForgotPassword from "../Screens/ForgotPassword";
import Login from "../Screens/Login";
import SignUp from "../Screens/SignUp";
import BaseLayout from "../Container/BaseLayout";
import TermsAndCondition from "../Screens/TermsAndCondition";
import MyKhitmahs from "../Screens/MyKhitmahs";
import Dashboard from "../Screens/Dashboard";
import MyPledges from "../Screens/MyPledges";
import RenderText from "../Components/Common/RenderText";
import ReadJuzz from "../Screens/ReadJuzz";
import ConfirmDonation from "../Screens/ConfirmDonation";
import MobileRedirection from "../Helper/MobileSchemeRedirection";
import DonationHistory from "../Screens/DonationHistory";
import Tutorial from "../Screens/Tutorial";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import PayItForward from "../Screens/PayItForward";
import ContactUs from "../Screens/AboutUs/contactus";

// Lazy loaded components
const CreateKhitmah = React.lazy(() => import("../Screens/CreateKhitmah"));
const CreateFundraiser = React.lazy(() =>
  import("../Screens/CreateFundraiser")
);
const JoinAKhitmah = React.lazy(() => import("../Screens/JoinAKhitmah"));
const KhitmahDetails = React.lazy(() => import("../Screens/KhitmahDetails"));
const ParticipantsMapView = React.lazy(() =>
  import("../Screens/ParticipantsMapView")
);
const AboutUs = React.lazy(() => import("../Screens/AboutUs"));
const Profile = React.lazy(() => import("../Screens/Profile"));

export const AppRoutes = {
  DASHBOARD: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgotpassword",
  CREATE_KHITMAH: "/createKhitmah",
  EDIT_KHITMAH: "/editKhitmah",
  CREATE_FUNDRAISER: "/createFundraiser",
  EDIT_FUNDRAISER: "/editFundraiser",
  TERMS_AND_CONDITION: "/terms",
  MY_KHITMAHS: "/my-khitmahs",
  JOIN_A_KHITMAH: "/join-a-khitmah",
  MY_PLEDGES: "/my-pledges",
  KHITMAH_DETAILS: "/khitmah-details",
  PARTICIPANTS_MAP_VIEW: "/participants-map",
  DONORS_MAP_VIEW: "/donors-map",
  ABOUT_US: "/about-khitmah",
  EDIT_PROFILE: "/edit-profile",
  READ_JUZZ: "/read-juzz",
  CONFIRM_DONATION: "/confirm-donation",
  MOBILE_REDIRECT: "/paypal-connect",
  DONATION_HISTORY: "/donations",
  TUTORIALS: "/tutorials",
  PRIVACY_POLICY: "/policy",
  PAY_IT_FORWARD: "/pay-it-forward",
  CONTACT_US: "/contact-us",
}; // set route path in nav.js for redirection from side menu

export const publicRoutes = [
  { path: AppRoutes.LOGIN, component: Login },
  { path: AppRoutes.SIGNUP, component: SignUp },
  { path: AppRoutes.FORGOT_PASSWORD, component: ForgotPassword },
  { path: AppRoutes.TERMS_AND_CONDITION, component: TermsAndCondition },
  { path: AppRoutes.PRIVACY_POLICY, component: PrivacyPolicy },
  { path: AppRoutes.CONTACT_US, component: ContactUs },

  {
    path: AppRoutes.MOBILE_REDIRECT,
    component: () => {
      window.location.href = `paypalConnect://khitmahdev.page.link${window.location.search}`;
      return null;
    },
  },
];

export const privateRoutes = [
  {
    path: AppRoutes.DASHBOARD,
    exact: false,
    name: "Dashboard",
    component: BaseLayout,
  },
];

// Inside application routes
export const routes = [
  //todo: Change base route to Dashboard when created
  {
    path: AppRoutes.DASHBOARD,
    exact: true,
    name: () => RenderText("screenTitles.dashboard"),
    component: Dashboard,
  },
  {
    path: AppRoutes.CREATE_KHITMAH,
    name: () => RenderText("screenTitles.createNewKhitmah"),
    component: CreateKhitmah,
  },
  {
    path: AppRoutes.EDIT_KHITMAH,
    name: () => RenderText("screenTitles.editKhitmah"),
    component: CreateKhitmah,
  },
  {
    path: AppRoutes.JOIN_A_KHITMAH,
    name: () => RenderText("khitmah.joinKhitmah"),
    component: JoinAKhitmah,
  },
  {
    path: AppRoutes.CREATE_FUNDRAISER,
    name: () => RenderText("screenTitles.createFundraiser"),
    component: CreateFundraiser,
  },
  {
    path: AppRoutes.EDIT_FUNDRAISER,
    name: () => RenderText("screenTitles.editFundraiser"),
    component: CreateFundraiser,
  },
  {
    path: AppRoutes.MY_KHITMAHS,
    name: () => RenderText("screenTitles.myKhitmahs"),
    component: MyKhitmahs,
  },
  {
    path: AppRoutes.MY_PLEDGES,
    name: () => RenderText("screenTitles.myPledges"),
    component: MyPledges,
  },
  {
    path: AppRoutes.KHITMAH_DETAILS,
    component: KhitmahDetails,
  },
  {
    path: AppRoutes.PARTICIPANTS_MAP_VIEW,
    name: () => RenderText("screenTitles.khitmahParticipants"),
    component: ParticipantsMapView,
  },
  {
    path: AppRoutes.DONORS_MAP_VIEW,
    name: () => RenderText("screenTitles.donorsParticipants"),
    component: ParticipantsMapView,
  },
  {
    path: AppRoutes.ABOUT_US,
    name: () => RenderText("screenTitles.aboutUs"),
    component: AboutUs,
  },
  {
    path: AppRoutes.DONATION_HISTORY,
    name: () => RenderText("screenTitles.yourDonations"),
    component: DonationHistory,
  },
  {
    path: AppRoutes.EDIT_PROFILE,
    name: () => RenderText("screenTitles.editProfile"),
    component: Profile,
  },
  {
    path: AppRoutes.READ_JUZZ,
    name: () => RenderText("screenTitles.juzzReading"),
    component: ReadJuzz,
  },
  {
    path: AppRoutes.CONFIRM_DONATION,
    name: () => RenderText("screenTitles.confirmDonation"),
    component: ConfirmDonation,
  },
  {
    path: AppRoutes.TUTORIALS,
    name: () => "",
    component: Tutorial,
  },
  {
    path: AppRoutes.PAY_IT_FORWARD,
    name: () => RenderText("screenTitles.payItForward"),
    component: PayItForward,
  },
];
