import React, { useEffect, useState } from "react";
import { facebookAuthProvider } from "../../Services/firebase";
import firebase from "firebase";
import { facebookLoginUser } from "../../Services/Authentication/facebookLogin";

import "./styles.scss";
import KWHModal from "../Common/Modal";
import { useTranslation } from "react-i18next";

function LoginWithFacebook(props) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("Login Error");
  const { t } = useTranslation();
  const loginWithFacebook = async () => {
    setErrorMessage("");
    try {
      let response = await firebase
        .auth()
        .signInWithPopup(facebookAuthProvider);
      const user = await facebookLoginUser(response);
      if (!user.emailVerified) {
        setErrorMessage(t("loginScreen.verifyYourEmailText"));
        setErrorTitle(t("loginScreen.emailVerificationRequired"));
        setVisibleModal(true);
      } else {
        props.redirect();
      }
    } catch (error) {
      //console.log(error);
      setErrorMessage(error.message);
      setErrorTitle("Login Error");
      setVisibleModal(true);
    }
  };

  useEffect(() => {
    return () => {
      setErrorMessage("");
      setVisibleModal(false);
    };
  }, []);

  const closeModal = () => {
    setVisibleModal(false);
  };

  return (
    <>
      <KWHModal
        visible={visibleModal}
        modalTitle={errorTitle}
        modalBody={errorMessage}
        onClose={closeModal}
        onOkClick={closeModal}
        btnSaveText="Ok"
      />
      <button className="facebook-btn" onClick={loginWithFacebook}></button>
    </>
  );
}

export default LoginWithFacebook;
