//import Config from 'react-native-config';

export const KHITMAHS = 'khitmahs';
export const USERS = 'users';
export const KHITMAH_PLEDGES = 'khitmah_pledges';
export const FUND_RAISER_ACCOUNTS = 'fund_raiser_accounts';
export const DONATION_PLEDGES = 'donation_pledges';
export const INBOX_NOTIFICATIONS = 'inbox_notifications';
export const MESSAGES = 'messages';
export const PAY_AS_FORWARD = 'pay_as_forward';
