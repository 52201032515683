import React, { useState, useEffect, useContext, useRef } from "react";
import firebase from "firebase";
import { Routes } from "./Routes";
import { getUser } from "@khitmah/shared/src/Services/User/getUser";
import { updatePushTokenUser } from "@khitmah/shared/src/Services/User/updatePushTokenUser";
import "./App.scss";
import "./style/rtl.scss";
import Loader from "./Components/Common/Loader";

import { AppContext } from "./Context/AppContext";
import {
  setFetchingUser,
  setLoggedInUser,
  setUserAuthType,
} from "./Context/Action";
import { requestNotificationPermission } from "./InitializeApp";
import auth from "@khitmah/shared/src/Firebase/auth";
import mixpanel from "mixpanel-browser";
import { setMixpanelUser } from "./Services/Mixpanel";
import { AUTH_TYPE } from "@khitmah/shared/src/Utils/types";

function App(props) {
  const { dispatch } = useContext(AppContext);

  const [authenticated, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(true);
  let retry = useRef(0);

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { ignore_dnt: true });
  const getUserDetails = (userId) => {
    getUser(userId)
      .then((userDetails) => {
        requestNotificationPermission()
          .then(() => {
            setMixpanelUser(userDetails.id);
            try {
              updatePushTokenUser(userDetails.id, userDetails.deviceToken);
            } catch (e) {
              console.log(e);
            }
          })
          .catch((err) => {
            return err;
          });

        dispatch(setFetchingUser(false));
        dispatch(setLoggedInUser(userDetails));
        setLoading(false);
      })
      .catch((e) => {
        if (retry.current < 1) {
          //console.log("retry time", retry.current);
          retry.current = retry.current + 1;

          getUserDetails(userId);
        } else {
          auth().signOut();
        }
        // setRetry(retry + 1);
        dispatch(setFetchingUser(false));
        //console.log(e);
      });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      dispatch(
        setUserAuthType(
          user?.providerData.find((i) => i.providerId === "password")
            ? AUTH_TYPE.EMAIL
            : AUTH_TYPE.SOCIAL
        )
      );
      if (user?.uid) {
        setAuthentication(true);
        dispatch(setFetchingUser(true));
        getUserDetails(user.uid);
      } else {
        setAuthentication(false);
        setLoading(false);
      }
    });
  }, [dispatch]);

  return loading ? (
    <Loader showLoader={true} />
  ) : (
    <Routes authenticated={authenticated} {...props} />
  );
}

export default App;
