import React from "react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const CheckboxField = (props) => {
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    hideLabel,
    setFieldValue,
    setFieldTouched,
  } = props;
  let validatyClass = "";
  if (!!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-invalid";
  }
  if (!errors[field.name] && !!touched[field.name]) {
    validatyClass = "is-valid";
  }

  const { t } = useTranslation();

  return (
    <>
      {!hideLabel && <label htmlFor={name}>{label}</label>}
      <div className="form-control-checkbox">
        <input
          {...field}
          id={name}
          type={fieldType}
          className={`form-control ${validatyClass}`}
          onChange={(e) => {
            setFieldTouched(field.name, true);
            setFieldValue(field.name, e.target.checked);
          }}
        />
        <span className="checkmark"></span>
      </div>

      {props.children}

      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback">{t(errors[field.name])}</div>
      )}
    </>
  );
};

function FormInput(props) {
  const { name } = props;
  return (
    <>
      <Field name={name} component={CheckboxField} {...props} />
    </>
  );
}
export default FormInput;
