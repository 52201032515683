import React, { Suspense } from "react";
import { CContainer, CFade } from "@coreui/react";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes, AppRoutes } from "../Constants/RouteConstants";
import { useTranslation } from "react-i18next";

const Content = () => {
  const { t } = useTranslation();
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name && route.name()}
                  render={(props) => (
                    //todo: Create HoC for error handling
                    <CFade>
                      <route.component {...props} name={route.name} />
                    </CFade>
                  )}
                />
              ) : null;
            })}
            <Redirect from="/" to="/createKhitmah" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default Content;
