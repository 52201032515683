import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "./../ButtonPrimary";
import ButtonSecondary from "./../ButtonSecondary";
import RenderText from "./RenderText";

const ButtonGroup = (props) => {
  const { t } = useTranslation();
  const {
    field,
    errors = {},
    name,
    touched = {},
    label,
    options,
    value,
    onButtonClick,
  } = props;

  return (
    <>
      <div className="form-control-btn-group">
        {options.map((option) => {
          const txName = t(option);
          const isSelected = txName === value;
          return (
            <>
              <div className="btn-parent-box">
                <ButtonSecondary
                  {...props}
                  className={isSelected ? "btn-active" : ""}
                  id={txName}
                  name={txName}
                  onClick={({ target }) => onButtonClick(target.textContent)}
                />
              </div>
            </>
          );
        })}
      </div>
      {errors[field.name] && touched[field.name] && (
        <div class="invalid-feedback">{t(errors[field.name])}</div>
      )}
    </>
  );
};

export default ButtonGroup;
