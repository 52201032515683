import React from "react";
import FundRaiserStats from "../../Components/Common/FundRaiserStats";
import JuzzCompletionStats from "../../Components/Common/JuzzCompletionStats";
import KWHProgressBar from "../../Components/Common/KWHProgressBar";
import KWHAvatarCell from "../../Components/Common/Table/Avatar";
import TableTextCell from "../../Components/Common/Table/Text";
import "./style.scss";
import {
  FundraiserProgressBarConfig,
  getStatusOfKhitmah,
  KhitmahProgressBarConfig,
} from "../../Helper/KhitmahList";
import RenderText from "../../Components/Common/RenderText";
import { getStatusColor } from "../../Helper/PledgesList";

export const MyKhitmahsListColumns = [
  {
    name: () => RenderText("khitmah.khitmahName"),
    type: "visible", // hidden
    id: "friendName",
    columnWidth: "22%",

    component: ({ data }) => (
      <>
        <KWHAvatarCell
          name={data["friendName"]}
          imageUrl={data["friendImageUrl"]}
          text={RenderText("khitmah.khitmahFor")}
        />
      </>
    ),
  },
  {
    name: () => RenderText("khitmah.totalKhitmahsCompleted"),
    type: "visible",
    id: "completedQuran",
    columnWidth: "15%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className="khitmah-count-box">
        <TableTextCell text={data.stats?.completedQuran} />
      </div>
    ),
  },
  {
    name: () => RenderText("khitmah.khitmahProgress"),
    type: "visible",
    id: "progress",
    columnWidth: "25%",
    component: ({ data }) => (
      <>
        <div className="khitmah-progress-box">
          <div className="secondary-progress chart-box">
            <KWHProgressBar config={KhitmahProgressBarConfig(data.stats)} />
          </div>
          <div className="info-box">
            <div className="inner">
              <JuzzCompletionStats
                completedCount={data.stats?.completedJuzz}
                pledgedCount={data.stats?.claimedJuzz}
              />
            </div>
          </div>
        </div>
      </>
    ),
  },
  {
    name: () => RenderText("khitmah.fundRaiserProgress"),
    type: "visible",
    id: "fundraiser",
    columnWidth: "25%",
    component: ({ data }) => (
      <>
        {data.hasFundRaiser && (
          <div className="fundraiser-progress-box">
            <div className="primary-progress chart-box">
              <KWHProgressBar
                config={FundraiserProgressBarConfig(data.fundRaiser)}
              />
            </div>
            <div className="info-box">
              <div className="inner">
                <FundRaiserStats
                  amountRaised={data.fundRaiser?.receivedAmount}
                  amountGoal={data.fundRaiser?.requestedAmount}
                />
              </div>
            </div>
          </div>
        )}
      </>
    ),
  },
  {
    name: () => RenderText("khitmah.khitmahStatus"),
    type: "visible",
    id: "status",
    columnWidth: "13%",
    classNameTh: "text-center",
    component: ({ data }) => (
      <div className={`status-box ${getStatusColor(data["status"])}`}>
        <TableTextCell text={getStatusOfKhitmah(data["status"])} />
      </div>
    ),
  },
];

/**
 *  Extended MyKhitmahsListColumns
 *  Additional Columns will be added in later parameter
 *  */
export const JoinAKhitmahListColumns = [...MyKhitmahsListColumns, ...[]];
