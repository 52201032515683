import { TransactionMode } from "@khitmah/shared/src/Models/donationPledge";
import { KhitmahType } from "@khitmah/shared/src/Models/khitmah";
import RenderText from "../Components/Common/RenderText";
import AssetsConstants from "../Constants/AssetsConstants";
export const DonationRequestModel = (props) => ({
  token: props.token,
  type: props.type,
  payerId: props.payerId,
  amount: props.amount,
  khitmaId: props.khitmaId,
  isAnonymous: props.isAnonymous,
  sponsorMsg: props.sponsorMsg ?? "",
});

export const khitmahType = (khitmahType) => {
  switch (khitmahType) {
    case KhitmahType.SICK:
      return RenderText("khitmah.forTheSick");
    case KhitmahType.DECEASED:
      return RenderText("khitmah.forTheDeceased");
    case KhitmahType.MISSING:
      return RenderText("khitmah.forTheMissing");
    default:
      return "";
  }
};

export const cardType = (transactionMode) => {
  switch (transactionMode) {
    case TransactionMode.PAYPAL:
      return AssetsConstants.paypalCard;
    case TransactionMode.MASTERCARD:
      return AssetsConstants.masterCard;
    case TransactionMode.VISA:
      return AssetsConstants.visaCard;
    case TransactionMode.DISCOVER:
      return AssetsConstants.discoverCard;
    case TransactionMode.AMERICAN_EXPRESS:
      return AssetsConstants.americanCard;
    case TransactionMode.JCB:
      return AssetsConstants.jcbCard;
    case TransactionMode.MAESTRO:
      return AssetsConstants.cardGeneral;
    default:
      return AssetsConstants.cardGeneral;
  }
};
