import {Khitmah} from '@khitmah/shared/src/Models/khitmah';
import {downloadPhoto} from '../downloadPhoto';

export const getKhitmahShareUrl = async (
  khitmah: Khitmah,
  env: 'dev' | 'qa' | 'staging' | 'production',
  apiKey: string,
) => {
  const basePackage = 'com.khitmah.mobile';
  const androidMinVersionCode = '21';
  const iosAppStoreId = '12345'; // Need to change this when we go live

  let domainUriPrefix = 'https://khitmah.page.link';
  let link = 'https://www.khitmahwithfriends.com/';
  let androidPackage = basePackage;
  let iosBundle = basePackage;

  switch (env) {
    case 'dev':
      androidPackage += '.dev';
      link = 'https://khitma-dev.web.app/';
      domainUriPrefix = 'https://khitmahdev.page.link';
      break;
    case 'qa':
      androidPackage += '.qa';
      link = 'https://khitma-qa.web.app/';
      domainUriPrefix = 'https://khitmahqa.page.link';
      break;
    case 'staging':
      androidPackage += '.staging';
      link = 'https://khitma-staging.web.app/';
      domainUriPrefix = 'https://khitmahstaging.page.link';
      break;
    case 'production':
      androidPackage += '.app';
      link = 'https://www.khitmahwithfriends.com/';
      break;
  }
  link += '?khitmahId=' + khitmah.id;

  const dynamicLinkInfo = {
    dynamicLinkInfo: {
      domainUriPrefix: domainUriPrefix,
      link: link,
      androidInfo: {
        androidPackageName: androidPackage,
        androidMinPackageVersionCode: androidMinVersionCode,
      },
      iosInfo: {
        iosBundleId: iosBundle,
        iosAppStoreId: iosAppStoreId,
      },
      navigationInfo: {
        enableForcedRedirect: true,
      },
      socialMetaTagInfo: {
        socialTitle: khitmah.friendName,
        socialDescription: khitmah.description,
        socialImageLink: khitmah.friendImageUrl
          ? await downloadPhoto(khitmah.friendImageUrl)
          : '',
      },
    },
    suffix: {
      option: 'UNGUESSABLE',
    },
  };

  const url =
    'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + apiKey;

  let response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dynamicLinkInfo),
  });

  let responseJson = await response.json();

  return responseJson.shortLink;
};
