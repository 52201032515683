import React, { useRef, useState } from "react";
import DropIn from "braintree-web-drop-in-react";
import "./styles.scss";

import {
  Currency,
  PayPalButtonStyle,
  BraintreeTranslations,
} from "../../Constants/BraintreeConstants";
import Loader from "../Common/Loader";

/**
 * This component is intended to be used for payment or donation via Braintree Dropin.
 */
function DonateAmount(props) {
  const {
    amount,
    setDropInInstance,
    setPaymentMethod,
    clientToken,
    loading,
  } = props;
  const [retry, setRetry] = useState(1);

  return (
    <div className="donation-amount-accordian">
      <Loader showLoader={loading} />
      <DropIn
        options={{
          authorization: clientToken,
          paypal: {
            flow: "checkout",
            amount: amount,
            currency: Currency,
            buttonStyle: PayPalButtonStyle,
          },
          translations: BraintreeTranslations(),
          vaultManager: false, // Set true if you need to edit/delete selected payment method.
        }}
        onInstance={(instance) => {
          setDropInInstance(instance);
        }}
        onError={(error) => {
          if (retry < 2) {
            setRetry(retry + 1);
          }
          //console.log("Braintree initializing error ", error);
          setDropInInstance(null);
        }}
        onPaymentOptionSelected={({ paymentOption }) => {
          /**
           * This event is emitted when the customer selects a new payment option type (e.g. PayPal, PayPal Credit, credit card).
           * This event is not emitted when the user changes between existing saved payment methods.
           * Only relevant when accepting multiple payment options.
           */
          //console.log("Payment option selected:", paymentOption);
          setPaymentMethod(paymentOption);
        }}
        onPaymentMethodRequestable={(paymentRequestable) => {
          /**
           * This event is emitted when the payment method available in Drop-in changes.
           * This includes when the state of Drop-in transitions from having no payment method available
           * to having a payment method available and when the kind of payment method available changes.
           * This event is not fired if there is no payment method available on initialization.
           * To check if there is a payment method requestable on initialization, use isPaymentMethodRequestable.
           */
          //console.log("paymentRequestable", paymentRequestable);
          if (paymentRequestable?.paymentMethodIsSelected) {
            setPaymentMethod(paymentRequestable.type);
          }
        }}
        onNoPaymentMethodRequestable={(noPayment) => {
          /**
           * This event is emitted when there is no payment method available in Drop-in.
           * This event is not fired if there is no payment method available on initialization.
           * To check if there is a payment method requestable on initialization,
           * use isPaymentMethodRequestable. No payload is available in the callback for this event.
           */
          //setPaymentMethod(null);
        }}
      />
    </div>
  );
}

export default DonateAmount;
