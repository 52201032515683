import {
  KhitmahFundRaiserInfoCauseType,
  KhitmahMemorialInfoLocationType,
  KhitmahPrivacyType,
  KhitmahType,
} from '../Models/khitmah';
import {Status} from '../Models/khitmahPledge';
import {AccountType} from '../Models/fundraiserAccount';

export type KHITMAH_FORM_FIELDS = {
  friendName: string;
  description: string;
  khitmahType: KhitmahType;
  khitmahPrivacy: KhitmahPrivacyType;
  dateOfPassing: Date | null;
  ageOfDeceased: number | null;
  memorialLocation: string | null;
  memorialLocationType: KhitmahMemorialInfoLocationType | null;
  memorialDate: Date | null;
  memorialTime: Date | null;
  memorialEndTime: Date | null;
  thankYouMessage: string;
  friendImageUrl: string | null;
  amount: number;
  causeOfFundRaiser: KhitmahFundRaiserInfoCauseType[];
  paymentAccountNumber: string;
  paymentAccountType: AccountType;
  friendImage?: File;
  city?: string;
  country?: string;
  thankYouMessageFundRaiser: string;
  phoneNumber: string;
};

export type JuzzModel = {
  juzzNumberDigit: number;
  juzzNumber: string;
  juzzVerses: string;
  juzzName: string;
  juzzNameArabic: string;
  juzzClaimStatus?: Status;
  juzzClaimedBy?: string;
  canBeClaimed?: boolean;
  khitmahPledgeId?: string;
  startPageNumber?: number;
};

export type Item = {
  label: string;
  value: any;
  key?: string | number;
  color?: string;
  /**
   * Used when you want a different label displayed
   * on the input than what is displayed on the Picker
   *
   * If falsy, label is used
   */
  inputLabel?: string;
};

export interface JuzzPageDto {
  juzzFirstPage: number;
  juzzLastPage: number;
  currentPage: number;
  pageVerses: PageVerseDto[];
}

export interface PageVerseDto {
  verseText: string;
  verseNumber: number;
  verseTranslation: string;
  suraNumber: number;
  suraName: string;
  suraEngName: string;
  suraType: string;
}

export class JuzzPage {
  juzzFirstPage: number;
  juzzLastPage: number;
  currentPage: number;
  pageVerses: PageVerseDto[];

  constructor(data: JuzzPageDto) {
    this.juzzFirstPage = data.juzzFirstPage;
    this.juzzLastPage = data.juzzLastPage;
    this.currentPage = data.currentPage;
    this.pageVerses = data.pageVerses.map((verse) => new PageVerse(verse));
  }
}

export class PageVerse {
  verseText: string;
  verseNumber: number;
  suraNumber: number;
  suraName: string;
  suraEngName: string;
  suraType: string;
  verseTranslation: string;

  constructor(data?: any) {
    this.verseNumber = data.result.aya;
    this.verseText = data.result.text;
    this.verseTranslation = data.result.translate.text;
    this.suraNumber = data.result.sura;
    this.suraName = data.result.sura_detail.name;
    this.suraEngName = data.result.sura_detail.tname;
    this.suraType = data.result.sura_detail.type;
  }
}

export enum PAYMENT_TYPE {
  DONATION = 'DONATION',
  PAY_AS_FORWARD = 'PAY_AS_FORWARD',
}

export type PAYMENT_FORM_FIELDS = {
  type: PAYMENT_TYPE;
  payerId: string;
  token: string;
  amount: number;
  khitmaId?: string;
  isAnonymous?: boolean;
  sponsorMsg?: string;
};

export type PAYMENT_RESPONSE = {
  success: boolean;
  data: PaymentData;
  message: string;
};

export type PaymentData = {
  amount: number;
  entityId: string;
  fee: number;
  khitmaId: string;
  khitmaName: string;
  message: string;
  paymentMode: string;
  title: string;
  transactionId: string;
};

export type DonationInfo = {
  paymentData: any;
  donationAmount: string;
  isAnonymous: boolean;
  type: PAYMENT_TYPE;
  payerId: string;
  khitmahId: string;
  khitmahFriendName: string;
};

export type ACCOUNT_EMAIL = {
  value: string;
  primary: boolean;
  confirmed: boolean;
};

export class PayPalAccountInfo {
  payerId: string;
  name: string;
  email?: string;

  constructor(data?: any) {
    if (data.emails) {
      const primaryEmail =
        data.emails.filter((email: ACCOUNT_EMAIL) => email.primary)[0] ?? null;
      this.email = primaryEmail?.value ?? null;
    }
    this.payerId = data.payer_id;
    this.name = data.name;
  }
}

export type DELETE_USER_RESPONSE = {
  success: boolean;
  data: USER_DATA;
};

export type USER_DATA = {
  userId: string;
};

export type SponsorDonationInfo = {
  paymentData: any;
  sponsorMsg: string;
  amount: string;
  type: PAYMENT_TYPE;
  payerId: string;
};

export enum AUTH_TYPE {
  SOCIAL = 'SOCIAL',
  EMAIL = 'EMAIL',
}
