import React, { useEffect, useState } from "react";
import storage from "@khitmah/shared/src/Firebase/storage";
import AssetsConstants from "../../../../Constants/AssetsConstants";
import "./style.scss";
import { downloadPhoto } from "@khitmah/shared/src/Services/downloadPhoto";

function TableAvatarCell(props) {
  const { name, text, localImage = null, localImageSrc } = props;

  return (
    <>
      <div className="khitmah-avatar-box">
        {!!localImage ? (
          <div className="user-pic">
            <img src={localImageSrc} alt="card" className="img-thumbnail" />
          </div>
        ) : (
          <AvatarCell {...props} />
        )}
        <div className="user-desc">
          {text && <div className="txt">{text}</div>}
          <div className="name">{name}</div>
        </div>
      </div>
    </>
  );
}

export const AvatarCell = (props) => {
  const [imageString, setImageString] = useState("");
  const { imageUrl, localImageUrl, large } = props;

  useEffect(() => {
    if (localImageUrl && typeof localImageUrl == "string") {
      setImageString(localImageUrl);
    } else if (imageUrl) {
      try {
        downloadPhoto(imageUrl).then((path) => {
          setImageString(path);
        });
      } catch (error) {
        //console.trace("error", error);
      }
    }

    return () => {
      setImageString("");
    };
  }, [imageUrl, localImageUrl]);
  return (
    <div className="user-pic">
      {imageString ? (
        <img src={imageString} alt="" className="img-thumbnail" />
      ) : large ? (
        <img src={AssetsConstants.defaultProfilePlaceholder} alt="" />
      ) : (
        <img src={AssetsConstants.defaultPlaceholder} alt="" />
      )}
    </div>
  );
};

export default TableAvatarCell;
