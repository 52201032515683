import React from "react";
import "./styles.scss";

function ImageContentContainer(props) {
  return (
    <>
      <section className="authentication-sec">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 col-md-7 col-lg-8">
              <div className="left-img-box">
                <img src={props.imageSrc} className={""} alt="Not found" />
                {props.showTextBoxImage && props.textBoxContent}
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-4">{props.content}</div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ImageContentContainer;
