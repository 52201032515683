import firestore from '../../Firebase/firestore';
import {USERS} from '../../Constants/database';
import {getErrorMessageFromFirestoreError} from '../../Utils/firestoreErrorMap';
import {uploadPhoto} from '../uploadPhoto';
import {User} from '../../Models/user';

export const updateUserOnBoardingFlag = async (
  onBoardingViewed: boolean,
  id: string,
) => {
  try {
    const user = firestore().collection(USERS);
    await user.doc(id).update({
      onBoardingViewed: onBoardingViewed,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const updateUserLanguage = async (language: string, id: string) => {
  try {
    const user = firestore().collection(USERS);
    await user.doc(id).update({
      language: language,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};

export const updateUserProfileDetails = async (
  id: string,
  fullName: string,
  age: string,
  city: string,
  country: string,
  profileImageUrl?: string | File | Blob,
) => {
  try {
    const user = firestore().collection(USERS).doc(id);
    const userData: User = {
      fullName: fullName,
      city: city,
      country: country,
      age: age,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };

    // Uploading background and friend images if exist
    if (profileImageUrl) {
      let friendImageRef: any;
      if (profileImageUrl instanceof File || profileImageUrl instanceof Blob) {
        friendImageRef = await uploadPhoto(
          'profile',
          '',
          id,
          <File>profileImageUrl,
        );
      } else {
        friendImageRef = await uploadPhoto('profile', profileImageUrl, id);
      }
      if (friendImageRef !== null) {
        userData.profileImageUrl = friendImageRef;
      }
    }
    await user.update(userData);
  } catch (error) {
    throw new Error(getErrorMessageFromFirestoreError(error));
  }
};
