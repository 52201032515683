import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field } from "formik";
import SimplePicker from "./SimplePicker";
import { useTranslation } from "react-i18next";

export const DatePickerField = (props) => {
  const { t } = useTranslation();
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    options,
    value,
    onChange,
    required,
    startDate,
    placeholder,
  } = props;
  return (
    <>
      <label for={name}>
        {label}
        {required && <em>*</em>}
      </label>
      <div className="form-control-date">
        <DatePicker
          selected={value}
          placeholderText={placeholder || t("common.selectDate")}
          onChange={onChange}
          dateFormat="dd MMMM yyyy"
          {...props}
        />
      </div>
      {errors[field.name] && touched[field.name] && (
        <div className="invalid-feedback d-block">{t(errors[field.name])}</div>
      )}
    </>
  );
};

const hourConstant = Array(12)
  .fill()
  .map((_, i) => (i + 1).toString());
const minConstant = Array(60)
  .fill()
  .map((_, i) => (i < 10 ? "0" + i : i.toString()));
const meridiemConst = [
  {
    label: "AM",
    value: "am",
  },
  {
    label: "PM",
    value: "pm",
  },
];

export const TimePickerField = (props) => {
  const {
    field,
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    options,
    value = new Date(),
    onChangeTime,
    required,
    startDate,
  } = props;
  // hour, min, meridiem are values passed to component
  const [hour, min, meridiem] = getTimeComponents(value);
  const [h, setH] = useState(hour);
  const [m, setM] = useState(min);
  const [mer, setMer] = useState(meridiem);

  useEffect(() => {
    var now = value;
    let hour = h;
    if (hour == 12 && mer == "am") {
      hour = 0;
    } else if (mer == "pm" && hour < 12) {
      hour = hour + 12;
    }
    now.setHours(hour);
    now.setMinutes(m);
    onChangeTime({
      time: `${h}:${m} ${mer}`,
      date: now,
    });
  }, [h, m, mer]);

  const { t } = useTranslation();

  return (
    <>
      <label for={name}>
        {label}
        {required && <em>*</em>}
      </label>
      <div className="form-control-time">
        <SimplePicker
          value={hour}
          items={hourConstant}
          onChange={(value) => setH(parseInt(value))}
        />
        <span className="dot">:</span>
        <SimplePicker
          value={min}
          items={minConstant}
          onChange={(value) => setM(parseInt(value))}
        />
        <SimplePicker
          value={meridiem}
          items={meridiemConst}
          onChange={(value) => setMer(value)}
        />
      </div>
      {errors[field.name] && touched[field.name] && (
        <div class="invalid-feedback d-block">{t(errors[field.name])}</div>
      )}
    </>
  );
};

const getTimeComponents = (date) => {
  let hour = 12,
    min = 0,
    meridiem = "am";
  if (date) {
    const timeArr = date.toTimeString().split(" ")[0].split(":");
    hour = parseInt(timeArr[0]);
    min = parseInt(timeArr[1]);
    if (hour == 0) {
      hour = 12;
      meridiem = "am";
    } else if (hour == 12) {
      meridiem = "pm";
    } else if (hour > 12) {
      hour = hour % 12;
      meridiem = "pm";
    }
  }
  return [hour, min, meridiem];
};
