import React from "react";

export const withHeader = (child, actions) => {
  return (props) => {
    return (
      <div>
        <div className="d-flex justify-content-between">
          {typeof actions?.leftAction === "function" &&
            actions?.leftAction(props)}
          <h1>
            {typeof props.name === "function"
              ? props.name()
              : "Error: name prop is missing for header"}
          </h1>
          {typeof actions?.rightAction === "function" &&
            actions?.rightAction(props)}
        </div>

        {child(props)}
      </div>
    );
  };
};
