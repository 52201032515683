import React from "react";
import { withHeader } from "../../Components/Header";
import ReadJuzProvider from "../../Hooks/useReadJuz";
import ReadJuzzWithContext from './readJuzz';

function ReadJuzz(props) {

  return (
    <ReadJuzProvider>
      <ReadJuzzWithContext/>
    </ReadJuzProvider>
  );
}

export default withHeader(ReadJuzz);
