import React from "react";
import "./style.scss";
import AssetsConstants from "../../Constants/AssetsConstants";
import { CIcon } from "@coreui/icons-react";
import { cibFacebook, cibLinkedin } from "@coreui/icons";

function ContactUs() {
  return (
    <>
      <div className="about-khitmah-screen generic-inner-sec">
        <div className="inner-bg-box">
          <div className="head-box">
            <img src={AssetsConstants.khitmahHeading} alt="khitmah" />
          </div>
          <div className="content-box margin-xl">
            <h2>We'd love to hear from you.</h2>
            <br />
            <h3>Contact Us</h3>
            <p>
              Khitmah with Friends is an application designed to manage the
              collective grieving process of friends who cannot pay their
              respects in person. <br></br>If you're looking for more
              information, or have a question, contact us on
              <br></br>
              <a href="mailto:khitmaapp@gmail.com">khitmaapp@gmail.com</a>
              <br />
              <br />
              <div>Or reach out to us on our social</div>
              <br></br>
              <div class="social-links">
                <a href="https://www.facebook.com/khitmaapp" target="_blank">
                  <CIcon content={cibFacebook} size="xl" />
                </a>
                <a
                  href="https://www.linkedin.com/in/keyfaya-labs-4429a6208/"
                  target="_blank"
                >
                  <CIcon content={cibLinkedin} size="xl" />
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
