import mixpanel from "mixpanel-browser";

// Shared events were not being triggered
// Due to time constraint, created service with package
export const setMixpanelUser = (userId) => {
  try {
    mixpanel.identify(userId);
  } catch (e) {
    console.log("Error in seting mixpanel user", e);
  }
};

export const trackEvent = (eventName, properties) => {
  try {
    mixpanel.track(eventName, properties);
  } catch (e) {
    console.log("Error in tracking event", e);
  }
};
