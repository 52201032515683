import React, { Component, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const isNumber = (str) => {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const Picker = (props) => {
  const {
    errors = {},
    name,
    touched = {},
    fieldType,
    label,
    options,
    value,
    items,
    handlePickerChange,
    disabled,
    placeholder,
  } = props;

  const selected =
    value &&
    items?.filter((item) => {
      return typeof item === "string" ? item == value : item.value == value;
    })[0];

  const { t } = useTranslation();

  return (
    <>
      <div className="field-group">
        <label htmlFor={name}>{label}</label>
        <Field
          name={name}
          component={() => {
            return (
              <>
                <Dropdown
                  className={`form-control-select ${disabled && "disabled"}`}
                  onSelect={(eventKey) => {
                    if (typeof handlePickerChange === "function") {
                      if (isNumber(eventKey)) {
                        handlePickerChange(Number(eventKey));
                      } else handlePickerChange(eventKey);
                    }
                  }}
                >
                  <Dropdown.Toggle variant="secondary" className="text-left">
                    {selected ? (
                      <div>
                        {typeof selected === "string"
                          ? t(selected)
                          : t(selected.label)}
                      </div>
                    ) : (
                      <div>{placeholder || t("khitmah.selectAnOption")}</div>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {items?.map((item, index) => {
                      return typeof item === "string" ? (
                        <Dropdown.Item
                          active={item == value}
                          key={item}
                          eventKey={item.toString()}
                        >
                          {t(item)}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          key={item.value}
                          active={item.value == value}
                          eventKey={item.value.toString()}
                        >
                          {t(item.label)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                {errors[name] && touched[name] && (
                  <div className="invalid-feedback d-block">
                    {t(errors[name])}
                  </div>
                )}
              </>
            );
          }}
        ></Field>
      </div>
    </>
  );
};
export default Picker;
