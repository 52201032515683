import { LANGUAGE_ACTIONS, USER_ACTIONS } from "../Constants/ActionConstants";

export let initialAppState = {
  loggedInUser: {},
  fetchingUserDetails: false,
  isRtlMode: false,
  authType: "",
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case USER_ACTIONS.SET_LOGGEDIN_USER:
      return { ...state, loggedInUser: action.payload };
    case USER_ACTIONS.SET_FETCHING_USER:
      return { ...state, fetchingUserDetails: action.payload };
    case USER_ACTIONS.SET_USER_LOCATION:
      const updateUserLocation = {
        ...state.loggedInUser,
        city: action.payload.city,
        country: action.payload.country,
      };
      return { ...state, loggedInUser: updateUserLocation };
    case USER_ACTIONS.SET_USER_AUTH_TYPE:
      return { ...state, authType: action.payload };
    case LANGUAGE_ACTIONS.SET_CURRENT_LANGUAGE:
      var updatedUser = { ...state.loggedInUser, language: action.payload };
      return {
        ...state,
        loggedInUser: updatedUser,
      };
    case LANGUAGE_ACTIONS.SET_RTL_MODE:
      return { ...state, isRtlMode: action.payload };
    default:
      return state;
  }
};
