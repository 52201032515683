import { changeDigitsToArabicDigits } from "@khitmah/shared/src/Utils/utils";
import { QURAN_TRANSLATION } from "@khitmah/shared/src/Constants/appConstants";
import React, { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

function PageVerses(props) {
  const { pageVerses, language, page } = props;

  const {
    state: { isRtlMode },
  } = useContext(AppContext);

  //console.log(pageVerses);

  return (
    <>
      <div className="ayat-box">
        <p>
          {pageVerses?.map((verse, index) =>
            verse.verseNumber.toString() === "1" ? (
              <>
                <div>
                  {isRtlMode ? (
                    <span>{`\uFD3F ${verse.suraName} \uFD3E`}</span>
                  ) : (
                    <span>{`\uFD3E ${verse.suraName} \uFD3F`}</span>
                  )}
                </div>
                {verse.suraNumber.toString() !== "9" &&
                  verse.suraNumber.toString() !== "1" && (
                    <div>
                      <span>{"بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ"}</span>
                    </div>
                  )}
                {verse.verseText}
                {` ( ${changeDigitsToArabicDigits(verse.verseNumber)} ) `}
              </>
            ) : (
              <>
                {index === 0 && (
                  <div>
                    {isRtlMode ? (
                      <span>{`\uFD3F ${verse.suraName} \uFD3E`}</span>
                    ) : (
                      <span>{`\uFD3E ${verse.suraName} \uFD3F`}</span>
                    )}
                  </div>
                )}
                {verse.verseText}
                {` ( ${changeDigitsToArabicDigits(verse.verseNumber)} ) `}
              </>
            )
          )}

          <div class="page-number">{` - ${page?.toString() ?? "0"} - `}</div>
          {/* 
          Old implimentation for showing page number in arabic
          <div class="page-number">
            {` - ${changeDigitsToArabicDigits(page?.toString() ?? "0")} - `}
          </div> */}
        </p>
      </div>
      <div className="sep-line"></div>
      {language !== QURAN_TRANSLATION.NO_TRANSLATION && (
        <>
          <div
            className={`${
              language === QURAN_TRANSLATION.ENGLISH
                ? "eng-lan"
                : "translation-box"
            }`}
          >
            <p>
              {pageVerses?.map((verse) => (
                <>
                  {verse.verseTranslation + " ( " + verse.verseNumber + " ) "}
                </>
              ))}
            </p>
          </div>
          <div className="powered-by">
            <span>Powered by :</span>
            <span className="salam-quran-txt">Salam Quran</span>
          </div>
        </>
      )}
    </>
  );
}

export default PageVerses;
