import {JuzzPage, JuzzPageDto} from '../../Utils/types';
import {QURAN_TRANSLATION} from '../../Constants/appConstants';
import {getFirstPageOfJuzz, getLastPageOfJuzz} from '../../Constants/juzzPages';
import {URL_CONSTANTS} from '../../Constants/urlConstants';

export const readJuzz = async (
  juzzNumber: number,
  translation: string = QURAN_TRANSLATION.ARABIC,
  pageNumber?: number,
) => {
  let pageToRead = pageNumber;
  let juzzFirstPage = getFirstPageOfJuzz(juzzNumber);
  let juzzLastPage = getLastPageOfJuzz(juzzNumber);
  let pageVerses: any = [];

  if (!pageToRead) {
    pageToRead = juzzFirstPage;
  }
  let juzzPageUrl = loadPageUrlByLanguage(translation);
  let juzzVerseUrl = loadVerseUrlByLanguage(translation);

  try {
    // Fetching a single page of quran with page number.
    let response = await fetchUrlWithParam(juzzPageUrl, pageToRead);
    let {result} = response;
    let promises: any[] = [];
    result.forEach(async (verse: any) => {
      let promise = fetchUrlWithParam(juzzVerseUrl, verse.index);
      promises.push(promise);
    });

    pageVerses = await Promise.all(promises);

    let juzzPageData: JuzzPageDto = {
      juzzFirstPage,
      juzzLastPage,
      currentPage: pageToRead,
      pageVerses,
    };
    return new JuzzPage(juzzPageData);
  } catch (err) {
    throw new Error(err);
  }
};

const fetchUrlWithParam = async (url: string, param: number) => {
  try {
    let response = await fetch(`${url}?index=${param}`, {
      method: 'GET',
      mode: 'cors', // Need for development purpose
    });
    let json = await response.json();
    return json;
  } catch (e) {
    throw new Error(e);
  }
};

const loadPageUrlByLanguage = (language: string) => {
  let url = URL_CONSTANTS.GET_PAGE_OF_JUZZ;
  url = url.replace(/{language}/, language);
  return url;
};

const loadVerseUrlByLanguage = (language: string) => {
  let url = URL_CONSTANTS.GET_AYAT_OF_JUZZ;
  url = url.replace(/{language}/, language);
  return url;
};
