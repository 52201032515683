import {
  ARABIC_NUMBERS,
  KHITMAH_INVITE_CODE_LENGTH,
  LANGUAGES,
} from '../Constants/appConstants';
import moment from 'moment';

export function getInviteCode() {
  return getRandomString(KHITMAH_INVITE_CODE_LENGTH);
}

function getRandomString(length: number) {
  const randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length),
    );
  }
  return result;
}

export function isStringEmpty(value?: string) {
  return value == null || !/\S/.test(value);
}

export function dateFormatted(date: Date | null) {
  if (date === null) return '';
  return moment(date).format('MMM Do YYYY');
}

export function dateFormatted2(date: Date | null) {
  if (date === null) return '';
  return moment(date).format('DD MMM, YYYY');
}

export function dateFormatted3(date: Date | null) {
  if (date === null) return '';
  return moment(date).format('DD MMMM, YYYY');
}

export function timeFormatted(date: Date) {
  if (date === null) return '';
  return moment(date).format('h:mm A');
}

export function timeDifference(date: Date) {
  if (date === null) return '';
  return moment(date).fromNow(true);
}

export function calculatePercentage(completed: number, total: number): number {
  if (total === 0) return 0;
  return Math.round((completed / total) * 100);
}

export function daysLeft(dateString: Date) {
  if (dateString === null) return '';
  let endDate = moment(dateString);
  var todaysdate = moment();
  return endDate.diff(todaysdate, 'days');
}

export function getKhitmahIdFromUrl(url: string): string | null {
  const khitmahIdIndex = url.lastIndexOf('khitmahId=');

  if (khitmahIdIndex !== -1) {
    const khitmahId = url.substr(khitmahIdIndex + 10); // 10 is the length of khitmahId=
    return khitmahId;
  }
  return null;
}

export function isLanguageSupported(lang: string): boolean {
  const langKeys = Object.values(LANGUAGES);
  return langKeys.indexOf(lang) > -1;
}

export function changeDigitsToArabicDigits(number: string): string {
  return number.replace(/[0-9]/g, function (num) {
    return ARABIC_NUMBERS[+num];
  });
}

const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const btoa = (input: string = '') => {
  let str = input;
  let output = '';

  for (
    let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || ((map = '='), i % 1);
    output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
  ) {
    charCode = str.charCodeAt((i += 3 / 4));

    if (charCode > 0xff) {
      throw new Error(
        "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.",
      );
    }

    block = (block << 8) | charCode;
  }

  return output;
};
