import { useCallback, useContext, useEffect, useState } from "react";

import { getUserDonationPledges } from "@khitmah/shared/src/Services/DonationPledge/getAllDonationPledge";
import {
  DonationPledge,
  documentSnapshotToDonationPledge,
} from "@khitmah/shared/src/Models/donationPledge";

import { AppContext } from "../Context/AppContext";

export const useDonationHistory = () => {
  const { state } = useContext(AppContext);
  const { loggedInUser } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [myDonations, setMyDonations] = useState([]);

  const onCollectionUpdate = useCallback((snapshot) => {
    let donations = [];
    donations = snapshot.docs.map(
      (doc) => doc.exists && { ...doc.data(), id: doc.id }
    );
    setMyDonations(donations);
    setIsLoading(false);
  });

  const onCollectionError = useCallback((error) => {
    setMyDonations([]);
    setIsLoading(false);
  });

  useEffect(() => {
    getUserDonationPledges(loggedInUser.id).onSnapshot(
      onCollectionUpdate,
      onCollectionError
    );
  }, []);

  return {
    isLoading,
    myDonations,
  };
};
