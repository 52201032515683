import React from "react";
import OnBoardingTutorial from "../../Components/OnBoardingTutorial";

function Tutorial(props) {
  const { history } = props;

  return <OnBoardingTutorial closeOnBoarding={() => history.goBack()} />;
}

export default Tutorial;
