export interface Message {
  en: MessageData;
  ar: MessageData;
  de: MessageData;
  es: MessageData;
  fa: MessageData;
  fr: MessageData;
  id: MessageData;
  it: MessageData;
  ru: MessageData;
  sv: MessageData;
  tr: MessageData;
  ur: MessageData;
  zh: MessageData;
  ms: MessageData;
  pt: MessageData;
}

export interface MessageData {
  title: string;
  msg: string;
}

export const documentSnapshotToMessage = (snap: any) => {
  const message = snap.data() as Message;
  if (snap.id === null) {
    throw new Error('message snapshot document id not found');
  }
  message.id = snap.id;

  return message;
};
