import React from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {string} key => Key to get the text from multilingual translation file.
 */
function RenderText(key) {
  const { t } = useTranslation();
  return <>{t(key)}</>;
}

export default RenderText;
