import { Entity } from './entity';

export interface User extends Entity {
  fullName: string;
  email: string;
  language: string;
  country?: string;
  city?: string;
  age?: string;
  profileImageUrl?: string;
  deviceToken?: string;
  onBoardingViewed?: boolean;
  authType?: boolean;
}

export const documentSnapshotToUser = (snap: any) => {
  const user = snap.data() as User;
  if (snap.id === null) {
    throw new Error('user snapshot document id not found');
  }
  user.id = snap.id;

  return user;
};
