export default {
  loginImage: require("../Assets/Images/group-3.png"),
  forgotImage: require("../Assets/Images/forgot-img.jpg"),
  logo: require("../Assets/Images/logo.png"),
  dashboardLogo: require("../Assets/Images/dashboard-logo.png"),
  lockImg: require("../Assets/Images/lock-img.svg"),
  signupImg: require("../Assets/Images/signup-img.jpg"),
  termImg: require("../Assets/Images/term-img.jpg"),
  docImg: require("../Assets/Images/term-img.svg"),
  defaultPlaceholder: require("../Assets/Images/default-placeholder.jpg"),
  defaultProfilePlaceholder: require("../Assets/Images/profile-placeholder.svg"),

  countryEnglish: require("../Assets/Images/language/uk.svg"),
  countryArabic: require("../Assets/Images/language/saudi-arabia.svg"),
  countryFrench: require("../Assets/Images/language/france.svg"),
  countrySpanish: require("../Assets/Images/language/spain.svg"),
  countryUrdu: require("../Assets/Images/language/pakistan.svg"),
  countryTurkish: require("../Assets/Images/language/turkey.svg"),
  countryChinese: require("../Assets/Images/language/china.svg"),
  countryRussian: require("../Assets/Images/language/russia.svg"),
  countryItalian: require("../Assets/Images/language/italy.svg"),
  countryFarsi: require("../Assets/Images/language/iran.svg"),
  countryMalay: require("../Assets/Images/language/malaysia.svg"),
  countryIndonesian: require("../Assets/Images/language/indonesia.svg"),
  countryGerman: require("../Assets/Images/language/germany.svg"),
  countrySwedish: require("../Assets/Images/language/sweden.svg"),

  profileOne: require("../Assets/Images/profile-1.jpg"),
  profileTwo: require("../Assets/Images/profile-2.jpg"),
  crossIcon: require("../Assets/Images/cross-icon.svg"),

  khitmahHeading: require("../Assets/Images/khitmah-heading.png"),

  onboardStep1: require("../Assets/Images/onboard-step-1.png"),
  onboardStep2: require("../Assets/Images/onboard-step-2.png"),
  onboardStep3: require("../Assets/Images/onboard-step-3.png"),
  onboardStep4: require("../Assets/Images/onboard-step-4.png"),
  onboardStep5: require("../Assets/Images/onboard-step-5.png"),

  checkIcon: require("../Assets/Images/check.svg"),
  cardGeneral: require("../Assets/Images/cardGeneral.png"),
  masterCard: require("../Assets/Images/cards/master.png"),
  paypalCard: require("../Assets/Images/paypal-light-large.png"),
  visaCard: require("../Assets/Images/cards/visa.png"),
  jcbCard: require("../Assets/Images/cards/jcb.png"),
  discoverCard: require("../Assets/Images/cards/discover.png"),
  americanCard: require("../Assets/Images/cards/american.png"),
};
