import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import FormInput from "../../Components/Common/FormInput";
import Loader from "../../Components/Common/Loader";
import { withHeader } from "../../Components/Header";
import { FIELD_TYPES } from "../../Constants/FormConstants";
import { useClientToken } from "../../Hooks/useClientToken";
import { usePayItForward } from "../../Hooks/usePayItForward";
import DonateAmount from "../../Components/DonateAmount";
import { BraintreeTranslations } from "../../Constants/BraintreeConstants";
import ButtonPrimary from "../../Components/ButtonPrimary";
import { prop } from "ramda";
import KWHModal from "../../Components/Common/Modal";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react";
import { PAYMENT_TYPE } from "@khitmah/shared/src/Utils/types";
import { AppContext } from "../../Context/AppContext";

const donationAmounts = [
  "common.twenty",
  "common.forty",
  "common.hundred",
  "common.fivehundred",
  "common.thoundand",
];

const PayItForward = () => {
  const { t } = useTranslation();

  const {
    state: { loggedInUser },
  } = useContext(AppContext);
  const { id: payerId } = loggedInUser;
  const [presetAmountValue, setPresetAmountValue] = useState("");

  const {
    isLoading,
    initialValues,
    setDropInInstance,
    dropInInstance,
    showModal,
    setShowModal,
    goToConfirmDonation,
  } = usePayItForward();
  const { clientToken } = useClientToken();

  let braintreeTranslations = BraintreeTranslations();

  const onSubmitForm = async (values) => {
    const response = await dropInInstance.requestPaymentMethod();
    const { details, nonce, type } = response;
    const donationDetails = {
      token: nonce,
      type: PAYMENT_TYPE.PAY_AS_FORWARD,
      payerId: payerId,
      amount: values.amount,
      cardDetails: details,
      paymentMethodType: type,
      sponsorMsg: values.message,
    };
    goToConfirmDonation(donationDetails);
  };
  return (
    <>
      <Loader showLoader={isLoading} />
      <CModal
        className="modal-sponsor"
        show={showModal}
        closeOnBackdrop={false}
        centered={true}
      >
        <CModalHeader>{t("payItForwardScreen.sponsorAKhitmah")}</CModalHeader>
        <CModalBody>
          {t("payItForwardScreen.sponsorAKhitmahMessage")}
          <div className="ok-button">
            <ButtonPrimary
              name={t("common.ok")}
              onClick={() => setShowModal(false)}
            />
          </div>
        </CModalBody>
      </CModal>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          const amountToNum =
            typeof values.amount === "number"
              ? values.amount
              : Number(values.amount.substr(1));
          if (amountToNum < 20) {
            errors.amount = t("schemaValidationErrors.amountNotLessThan20");
          } else if (values.message.trim() === "") {
            errors.message = t("payItForwardScreen.writeAMessage");
          }
          return errors;
        }}
        validateOnMount={true}
      >
        {(props) => {
          let amountNum =
            typeof props.values?.amount === "number"
              ? props.values?.amount
              : Number(props.values?.amount?.substr(1));
          return (
            <>
              <Form>
                <div className="generic-inner-sec">
                  <div className="container-flude">
                    <div className="sub-heading-style2 mt-0">
                      <h3>{t("payItForwardScreen.donationDesc")}</h3>
                    </div>
                    <div className="content-box">
                      <p>
                        {t(
                          "payItForwardScreen.payItForwardDetailAccordianContent"
                        )}
                      </p>
                    </div>
                    <FormInput
                      name="message"
                      label={t("common.message")}
                      fieldType="text"
                      maxLength={300}
                      required={true}
                      placeholder=""
                      {...props}
                    />
                    <div className="donation-amount-box">
                      <div className="sub-heading-style2 mt-0">
                        <h3>{t("payItForwardScreen.enterAmountToDonate")}</h3>
                      </div>
                      <div className="content-box">
                        <p>{t("payItForwardScreen.minimumDonateAmount")}</p>
                      </div>
                      <FormInput
                        name="amount"
                        required={true}
                        onChange={(e) => {
                          const val = e.target.value.substr(1);
                          const numberRgx = new RegExp("^[0-9]*$");

                          if (numberRgx.test(val)) {
                            props.setFieldValue(
                              "amount",
                              `$${Number(val)}`,
                              true
                            );
                          }
                          setPresetAmountValue("");
                        }}
                        {...props}
                      />
                      <div className="amount-box">
                        <div className="select-amount">
                          <FormInput
                            type={FIELD_TYPES.BUTTON_GROUP}
                            options={donationAmounts}
                            onButtonClick={(value) => {
                              if (!!value) {
                                props.setFieldValue("amount", value);
                                setPresetAmountValue(value);
                              }
                            }}
                            value={presetAmountValue}
                            {...props}
                          />
                        </div>

                        <div className="payment-box">
                          <h3 className="sub-heading">
                            {t("khitmah.enterAccountInformation")}
                          </h3>

                          {/** Render donation module only if valid amount is provided */}
                          {!!amountNum && amountNum >= 20 ? (
                            <>
                              {!clientToken ? (
                                <Loader />
                              ) : (
                                <DonateAmount
                                  amount={amountNum}
                                  setDropInInstance={(instance) => {
                                    // Scroll down the view when dropin is loaded
                                    window.scrollBy({
                                      top: 350,
                                      behavior: "smooth",
                                    });
                                    setDropInInstance(instance);
                                  }}
                                  setPaymentMethod={(paymentMethod) => {
                                    props.setFieldValue(
                                      "paymentType",
                                      paymentMethod
                                    );
                                  }}
                                  clientToken={clientToken}
                                  braintreeTranslations={braintreeTranslations}
                                  loading={!dropInInstance ? true : false}
                                />
                              )}
                            </>
                          ) : (
                            <p>{t("donation.pleaseSelectAnAmount")}</p>
                          )}
                          <div className="btn-box">
                            <ButtonPrimary
                              className="create-fundraiser-btn"
                              name={t("khitmah.donate")}
                              loading={isLoading}
                              onClick={() => {
                                if (props.isValid) {
                                  onSubmitForm(
                                    Object.assign(props.values, {
                                      amount: amountNum,
                                    })
                                  );
                                } else {
                                  props.setFieldTouched("message", true);
                                  props.setFieldTouched("amount", true);
                                }
                              }}
                              disabled={!props.values.paymentType}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default withHeader(PayItForward);
