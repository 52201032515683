import React from "react";
import RenderText from "../../Components/Common/RenderText";

import KWHAvatarCell from "../../Components/Common/Table/Avatar";
import TableTextCell from "../../Components/Common/Table/Text";
import { khitmahType, cardType } from "../../Helper/Donation";
import { dateFormatted3 } from "@khitmah/shared/src/Utils/utils";

export const MyDonationsListColumns = [
  {
    name: () => RenderText("donation.deceasedName"),
    type: "visible", // hidden
    id: "friendName",
    columnWidth: "22%",

    component: ({ data }) => (
      <>
        <KWHAvatarCell
          name={data["friendName"]}
          text={RenderText("khitmah.khitmahFor")}
          localImageSrc={cardType(data["transactionMode"])}
          localImage={true}
        />
      </>
    ),
  },
  {
    name: () => RenderText("donation.khitmahType"),
    type: "visible", // hidden
    id: "khitmahType",
    columnWidth: "22%",

    component: ({ data }) => (
      <>
        <div className="">
          <TableTextCell text={khitmahType(data["khitmahType"])} />
        </div>
      </>
    ),
  },
  {
    name: () => RenderText("donation.transaction"),
    type: "visible", // hidden
    id: "transaction",
    columnWidth: "15%",

    component: ({ data }) => (
      <>
        <div className="status-box">
          <TableTextCell text={RenderText("donation.successful")} />
        </div>
      </>
    ),
  },
  {
    name: () => RenderText("common.date"),
    type: "visible", // hidden
    id: "date",
    columnWidth: "15%",

    component: ({ data }) => <>{dateFormatted3(data["createdAt"]?.toDate())}</>,
  },
  {
    name: () => RenderText("common.amount"),
    type: "visible", // hidden
    id: "amount",
    columnWidth: "15%",

    component: ({ data }) => <>{`$ ${data["amount"]}`}</>,
  },
];

export const PayItForwardListColumns = [
  {
    name: () => RenderText("donation.transactionType"),
    type: "visible", // hidden
    id: "friendName",
    columnWidth: "22%",

    component: ({ data }) => (
      <>
        <KWHAvatarCell
          name={RenderText("donation.sponsoredDonations")}
          localImageSrc={cardType(data["transactionMode"])}
          localImage={true}
        />
      </>
    ),
  },
  {
    name: () => RenderText("donation.transaction"),
    type: "visible", // hidden
    id: "transaction",
    columnWidth: "15%",

    component: ({ data }) => (
      <>
        <div className="status-box">
          <TableTextCell text={RenderText("donation.successful")} />
        </div>
      </>
    ),
  },
  {
    name: () => RenderText("common.date"),
    type: "visible", // hidden
    id: "date",
    columnWidth: "15%",

    component: ({ data }) => <>{dateFormatted3(data["createdAt"]?.toDate())}</>,
  },
  {
    name: () => RenderText("common.amount"),
    type: "visible", // hidden
    id: "amount",
    columnWidth: "15%",

    component: ({ data }) => <>{`$ ${data["amount"]}`}</>,
  },
];
