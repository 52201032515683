import React from 'react'
import { CIcon } from '@coreui/icons-react';
import { useStyles } from 'react-styles-hook';

const styles = useStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    buttonText: {
        margin: 10
    }
})

const IconButton = ({ name, icon, iconProps, src }) => {
    return (<div style={styles.container}>
        {
            icon &&
            <CIcon content={icon} {...iconProps} />
        }
        {
            src &&
            <img src={src} {...iconProps} />
        }
        <div style={styles.buttonText}>{name}</div>
    </div>);
}

export default IconButton;