import { LANGUAGE_ACTIONS, USER_ACTIONS } from "../Constants/ActionConstants";

export const setLoggedInUser = (details) => {
  return {
    type: USER_ACTIONS.SET_LOGGEDIN_USER,
    payload: details,
  };
};

export const setFetchingUser = (fetching) => {
  return {
    type: USER_ACTIONS.SET_FETCHING_USER,
    payload: fetching,
  };
};

export const setCurrentLanguage = (language) => {
  return {
    type: LANGUAGE_ACTIONS.SET_CURRENT_LANGUAGE,
    payload: language,
  };
};

export const setRtlMode = (isRtl) => {
  return {
    type: LANGUAGE_ACTIONS.SET_RTL_MODE,
    payload: isRtl,
  };
};

export const setUserLocation = (location) => {
  return {
    type: USER_ACTIONS.SET_USER_LOCATION,
    payload: location,
  };
};

export const setUserAuthType = (autType) => {
  return {
    type: USER_ACTIONS.SET_USER_AUTH_TYPE,
    payload: autType,
  };
};
