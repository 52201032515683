import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Loader from "../../Components/Common/Loader";
import KWHModal from "../../Components/Common/Modal";
import KWHTable from "../../Components/Common/Table";
import DonationSummaryDetail from "../../Components/DonationSummaryDetail";
import { usePayItForward } from "../../Hooks/usePayItForward";
import { PayItForwardListColumns } from "./tableConfig";

const PayItForwardHistory = (props) => {
  const { t } = useTranslation();

  const { isLoading, myDonations } = usePayItForward();
  const [selectedItem, setSelectedItem] = useState({});
  const [isSummaryModalVisible, setSummaryModalVisibility] = useState(false);

  const openSummaryModal = (item) => {
    setSelectedItem(item);
    setSummaryModalVisibility(true);
  };

  const closeSummaryModal = () => {
    setSelectedItem({});
    setSummaryModalVisibility(false);
  };

  return isLoading ? (
    <Loader showLoader={true} />
  ) : (
    <>
      <div className="my-donation-screen generic-inner-sec">
        <KWHTable
          columns={PayItForwardListColumns}
          tableData={myDonations}
          handleRowClick={openSummaryModal}
        />
        <>
          <KWHModal
            visible={isSummaryModalVisible}
            modalTitle={t("donation.donationSummary")}
            modalBody={
              <DonationSummaryDetail
                amount={selectedItem.amount}
                transactionFee={selectedItem.fee || 0}
                serviceCharges={selectedItem.serviceFee || 0}
                totalDonation={
                  selectedItem.amount -
                  (selectedItem.fee || 0) -
                  (selectedItem.serviceFee || 0)
                }
                paymentMethodType={selectedItem.transactionMode}
              />
            }
            // onClose={closeModal}
            onOkClick={() => closeSummaryModal()}
            btnSaveText={t("common.close")}
          />
        </>
      </div>
    </>
  );
};

export default PayItForwardHistory;
