import { useState, useEffect } from "react";
import { getClientToken } from "@khitmah/shared/src/Services/Payment/getClientToken";

export const useClientToken = () => {
  const [clientToken, setClientToken] = useState(null);

  const fetchClientToken = async () => {
    try {
      const url = process.env.REACT_APP_CLIENT_TOKEN_FOR_PAYMENT_URL;
      const token = await getClientToken(url);
      setClientToken(token);
    } catch (error) {
      console.log("Error in getting client token", error);
    }
  };

  useEffect(() => {
    fetchClientToken();
  }, []);

  return {
    clientToken,
  };
};
