import {Entity} from './entity';
import {Notification} from './notification';
export interface Inbox extends Entity {
  messages: Notification[];
  unread: number;
  unseen: number;
}

export const documentSnapshotToInbox = (snap: any) => {
  const notification = snap.data() as Inbox;
  if (snap.id === null) {
    throw new Error('inbox snapshot document id not found');
  }
  notification.id = snap.id;

  return notification;
};
